const Menu = ({ menuList, state, setState, t, lang }) => {

    return (
        <div className="bg-surface-card h-full rounded-md overflow-auto xl:min-w-[280px] md:overflow-hidden md:sticky top-[100px]">
            <ul className="flex flex-row md:flex-col label1 text-600 w-fit">
                {menuList.map((item, index) => {
                    return (
                        <li className="py-x-big px-big profile-menu" key={item.id}>
                            <button onClick={() => setState(item.id)} className={`w-max md:w-full flex gap-sm items-center label1 text-start ${Number(item.id) === Number(state) ? 'text-surface-primary active' : ''} `}>
                                <span><img src={item.iconPath} /></span>
                                <span>{item.label ? t(item.label) : item?.[`name${lang}`] ? item?.[`name${lang}`] : ''}</span>
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Menu;