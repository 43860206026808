import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { inputLabelClasses } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import ErrorMessage from "../../components/error/ErrorMessage";
import { selectClasses } from "@mui/material";

const MySelect = ({
  name,
  inputLabel,
  options,
  default_value,
  action,
  disabled,
  updateFields,
  data,
  error,
  optional,
  help_text,
  setError,
  lng,
  readonly,
  customDisplay,
  customRender,
  t,
  removeOption,
}) => {
  const [value, setValue] = useState(default_value || data || "");
  const [option, setOption] = useState(options || []);
  const lang = lng ? lng[0].toUpperCase() + lng.slice(1) : "";
  const { fetchData } =
    name === "country_code"
      ? SubmitAndFetch({ lng, t })
      : () => {
          return false;
        };

  useEffect(() => {
    const gatData = async () => {
      await fetchData("country", null, setOption, null, lng);
    };
    if (name === "country_code") {
      gatData();
    }
  }, []);

  const handleChange = (e) => {
    if (action) {
      action(e.target.value);
    }
    setValue(e.target.value);
    if (updateFields) {
      updateFields({ [name]: e.target.value });
    }
    if (setError) setError((preError) => ({ ...preError, [name]: "" }));
  };

  useEffect(() => {
      setValue(data);
  }, [data]);

  const renderValue = (selected) => {
    return customRender ? customRender(selected, option) : selected;
  };

  return (
    <>
      <FormControl variant="filled" className="w-full">
        <InputLabel
          error={error}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "var(--text-caption)",
            },
            color: "var(--text-body)",
            [`&.Mui-error.${inputLabelClasses.focused}`]: {
              [`&.${inputLabelClasses.shrink}`]: {
                color: "var(--text-warning)",
              },
            },
            [`&.${inputLabelClasses.focused}`]: {
              [`&.${inputLabelClasses.shrink}`]: {
                color: "var(--label-icon-on-light)",
              },
            },
            [`&.${inputLabelClasses.focused}`]: {
              [`&.${inputLabelClasses.shrink}`]: {
                color: "var(--label-icon-on-light)",
              },
            },
            [`&.${inputLabelClasses.shrink}`]: {
              color: "var(--text-caption)",
            },
            [`&.Mui-error.${inputLabelClasses.shrink}`]: {
              color: "var(--text-warning)",
            },
          }}
        >
          {t(inputLabel)}
        </InputLabel>
        <Select
          IconComponent={KeyboardArrowDownIcon}
          disabled={disabled}
          labelId={inputLabel}
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          className={`${readonly ? "readonly" : ""} body1 text-body w-full`}
          error={!!error}
          inputProps={{ readOnly: readonly }}
          renderValue={renderValue}
          MenuProps={{
            PaperProps: {
              className: "custom-scrollbar p-x-sm",
              style: {
                maxHeight: "322px",
                marginTop: "var(--spacing-sm)",
                overflowY: "auto",
                background: "var(--surface-card)",
                borderRadius: "var(--radius-sm)",
                boxShadow: "var(--box-shadow-lg)",
              },
            },
            MenuListProps: { style: { padding: "0" } },
          }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "var(--text-body)",
              background: "var(--surface-card)",
              borderRadius: "var(--radius-x-sm)",
            },
            [`&.${selectClasses.disabled}`]: {
              ":before": {
                content: "none",
              },
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              borderRadius: "var(--radius-x-sm)",
            },
            "& fieldset": { border: "none" },
            background: "var(--surface-base)",
            ":before": { borderBottomColor: "var(--Neutral-300)" },
            ":after": { borderBottomColor: "var(--surface-primary)" },
          }}
        >
          {removeOption && (
            <MenuItem
              value=""
              sx={{
                "&:hover": {
                  "&.Mui-selected": {
                    background: "var(--surface-secondary)",
                  },
                  background: "var(--surface-secondary)",
                },
                "&.MuiList-root": { padding: "0" },
                "&.Mui-selected": {
                  color: "var(--text-body) !important",
                  background: "none",
                },
                maxWidth: "100%",
                whiteSpace: "normal",
                display: "flex",
                gap: "var(--spacing-md)",
                padding: "var(--spacing-x-sm)",
                borderRadius: "var(--radius-x-sm)",
              }}
            >
              <em>{t('None')}</em>
            </MenuItem>
          )}
          {option &&
            option.map((item, index) => {
              return (
                <MenuItem
                  value={item.code || item.value || item.id || item.userEmail}
                  key={index}
                  sx={{
                    "&:hover": {
                      "&.Mui-selected": {
                        background: "var(--surface-secondary)",
                      },
                      background: "var(--surface-secondary)",
                    },
                    "&.MuiList-root": { padding: "0" },
                    "&.Mui-selected": {
                      color: "var(--text-body) !important",
                      background: "none",
                    },
                    maxWidth: "100%",
                    whiteSpace: "normal",
                    display: "flex",
                    gap: "var(--spacing-md)",
                    padding: "var(--spacing-x-sm)",
                    borderRadius: "var(--radius-x-sm)",
                  }}
                >
                  {customDisplay
                    ? customDisplay(item)
                    : lng
                    ? item?.[`name${lang}`]
                    : t(item.label) || t(item.name)}
                </MenuItem>
              );
            })}
          {option?.length === 0 && (
            <MenuItem value="" disabled>
              <em>{t("No Data")}</em>
            </MenuItem>
          )}
        </Select>
        <ErrorMessage
          error={error}
          optional={optional}
          help_text={help_text}
          t={t}
        />
      </FormControl>
    </>
  );
};

export default MySelect;
