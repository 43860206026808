import Breadcrumb from "../../components/header/Breadcrumb";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { BlueForwardLink } from "../../components/button/SingleButton";
import ArticlesTemplate from "../../components/help-centre/ArticlesTemplate";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import ArticleTemplates from "../../components/articles/ArticlesTemplate";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import {useParams} from "react-router-dom";
import SmallFunction from "../../components/function/SmallFunction";


const ArticleDetails = ({ lng }) => {
    const lang = lng ? lng[0].toUpperCase() + lng.slice(1) : '';
    const { t } = useTranslation();
    const { id } = useParams();
    const [articleDetails, setArticleDetails] = useState([]);
    const [articlesRelated, setArticlesRelated] = useState([]);
    const { userLoading, profileData } = useUserLoginContext();
    const { fetchData } = SubmitAndFetch({ lng, t});
    const { changeDate } = SmallFunction();

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchArticles = async () => {
            await fetchData(`article/${id}`, null, setArticleDetails, null, lng)
        }
        if (profileData) {
            fetchArticles();
        }
    }, [id, profileData]);

   useEffect(() => {
       if (articleDetails) {
           setArticlesRelated(articleDetails?.relatedList?.slice(0, 3))
           document.title = `${t("web_portal_title_text")} - ${t('Article')} | ${articleDetails?.[`title${lang}`]}`;
       }
   }, [articleDetails])

    if (userLoading) return <LoadingUI/>;

    const subtitle = () => (
        <div className='flex gap-x-sm items-start md:items-center mt-x-sm flex-col md:flex-row'>
            <span className='label1 text-caption'>{changeDate(articleDetails?.postDate)}</span>
            <span className='w-xx-sm h-xx-sm bg-surface-disabled rounded-full hidden md:block'></span>
            <span className='label1 text-caption flex flex-wrap gap-xx-sm md:gap-x-sm items-center text-sm md:text-base'>{
                articleDetails?.topicList ? articleDetails?.topicList?.map((topic, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <span className='w-xx-sm h-xx-sm bg-surface-disabled rounded-full'></span>}
                        {topic?.[`name${lang}`]}
                    </React.Fragment>
                )) : ''
            }</span>
        </div>
    )

    return (
        <>
            <Breadcrumb
                lng={lng}
                pervElement={{
                    skip: true,
                    position: 2,
                }}
                currentElement={articleDetails?.[`title${lang}`]}
            />
            <ArticleTemplates subtitle={subtitle()} title={articleDetails?.[`title${lang}`]}>
                <div className='bg-surface-card p-md md:p-lg rounded-big mt-x-big w-full'>
                    <div className='mb-xx-big'>
                        <img src={articleDetails?.[`thumbnail${lang}`]} alt='thumbnail' className='w-full rounded-sm'/>
                    </div>
                    <p className='body2 text-title' dangerouslySetInnerHTML={{ __html: articleDetails?.[`content${lang}`] }}></p>
                </div>
                <div className='mt-xx-lg flex gap-x-big flex-col'>
                    <div className='flex justify-between items-center'>
                        <h2 className='h2 text-headline'>{t('Related Articles')}</h2>
                        <BlueForwardLink t={t} href={`/${lng}/help-centre/articles?related_id=${id}`} label='All' />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid md:grid-cols-3 gap-x-big'>
                        <ArticlesTemplate lang={lang} lng={lng} data={articlesRelated} />
                    </div>
                </div>
            </ArticleTemplates>
        </>
    )
}

export default ArticleDetails;