import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeepCompareEffect } from "react-use";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import USER_TYPE from "../../data/variable/user_type.json";
import TabTemplate from "../../components/tab/TabTemplate";
import Input from "../../components/input/Input";
import Select from "../../components/input/Select";
import Radio from "../../components/input/Radio";
import Password from "../../components/input/Password";
import AreaAndDistrict from "../../components/input/AreaAndDistrict";
import VerifyField from "../../components/input/VerifyField";
import ProfileSection from "../../components/my-profile/ProfileSection";
import Line from "../../components/line/Line";
import VerifyDisableChangeFunction from "../../components/function/VerifyDisableChangeFunction";
import PasswordTemplate from "../../components/form/password/template";
import UploadFilePreview from "../../components/upload/UploadFilePreview";
import UserIconData from "../../components/account_management/UserIconData";
import AccountManagementTemplate from "../../components/account_management/Template";
import LoadingUI from "../../components/loading/LoadingUI";
import { DiscardButton } from "../../components/button/SingleButton";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import VerifiedButton from "../../components/button/VerifiedButton";
import CircularProgress from "@mui/material/CircularProgress";
import smsOption from "../../data/sms_option.json";
import particulars_options from "../../data/particulars_options.json";
import { DeepEqual } from "../../assets/js/global";
import Breadcrumb from "../../components/header/Breadcrumb";
import useDocument from "../../hooks/useDocument";

const MyProfile = ({ lng }) => {
  const { t } = useTranslation();
  const {
    profileData,
    userLoading,
    token,
    icon,
    account_type,
    setProfileData,
  } = useUserLoginContext();
  const {
    handleEmailVerifyActive,
    handleMobileVerifyActive,
    handleAltEmailVerifyActive,
    emailVerifyActive,
    mobileVerifyActive,
    altEmailVerifyActive,
  } = VerifyDisableChangeFunction();
  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const [error, setError] = useState([]);
  const [data, setData] = useState({});
  const [userData, setUserData] = useState({});
  const [file, setFile] = useState({});
  const [getFile, setGetFile] = useState({});
  const [getFileLoading, setGetFileLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [showSMS, setShowSMS] = useState(null);
  const [mobileVerified, setMobileVerified] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const [altEmailVerified, setAltEmailVerified] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const individual = account_type === USER_TYPE.INDIVIDUAL;
  const api_name = "profile";
  const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
  useDocument("My Profile")

  useEffect(() => {
    postForm(
      api_name,
      null,
      null,
      null,
      null,
      null,
      saveData,
      token,
      null,
      null,
      false
    );
  }, []);

  const saveData = (newData) => {
    if (newData?.profile) newData = newData?.profile;
    if (newData) {
      const userAccount = Number(newData?.accountType?.value);
      localStorage.setItem("userData", JSON.stringify(newData));
      setProfileData(newData);
      const profileObject = {
        username: newData?.username,
        name_en: newData?.nameEn,
        name_zh: newData?.nameZh,
        email: newData?.email,
        email_alternative: newData?.emailAlternative,
        is_receive_sms: newData?.isReceiveSms,
        country_code: newData?.countryCode?.code,
        mobile: newData?.mobile,
        fax: newData?.fax,
        title: newData?.title,
        corporate_contact_name_en: newData?.corporateDetail?.contactNameEn,
        corporate_contact_name_zh: newData?.corporateDetail?.contactNameZh,
        corporate_contact_post_title: newData?.corporateDetail?.contactPostTitle,
        billing_account_no:
          userAccount === USER_TYPE.INDIVIDUAL
            ? newData?.billingAccount?.accountNo
            : newData?.corporateDetail?.billingAccountNo,
        billing_payer_name:
          userAccount === USER_TYPE.INDIVIDUAL
            ? newData?.billingAccount?.payerName
            : newData?.corporateDetail?.billingName,
        address_area: newData?.address?.addressArea?.id,
        address_district: newData?.address?.addressDistrict?.id,
        address_sub_district: newData?.address?.addressDistrict?.subDistricts,
        address_street: newData?.address?.addressStreet,
        address_building: newData?.address?.addressBuilding,
        address_block: newData?.address?.addressBlock,
        address_floor: newData?.address?.addressFloor,
        address_room: newData?.address?.addressRoom,
        corporate_brn: newData?.corporateDetail?.corporateBRN,
        corporate_name_en: newData?.corporateDetail?.corporateNameEn,
        corporate_name_zh: newData?.corporateDetail?.corporateNameZh,
        password: "",
        password2: "",
        current_password: "",
        alternative_verification_code: "",
        mobile_verification_code: "",
        email_verification_code: "",
        is_verified_email: newData?.isVerifiedEmail,
        is_verified_mobile: newData?.isVerifiedMobile,
        is_verified_alternative_email: newData?.isVerifiedEmailAlternative,
      };
      setUserData(profileObject);
      setData(profileObject);
      setMobileVerified(profileObject?.is_verified_mobile === "1");
      setShowSMS(profileObject?.is_receive_sms === "1");
      setEmailVerified(profileObject?.is_verified_email === "1");
      setAltEmailVerified(profileObject?.is_verified_alternative_email === "1");
    }
  };

  const saveFileData = (newData) => {
    setFile({
      name: newData?.corporateDetail?.proofFileName,
      type: newData?.corporateDetail?.proofFileType,
      size: newData?.corporateDetail?.proofFileSize,
      api: api_name + "/corporate/proof",
      updated_at: newData?.corporateDetail?.proofFileUploadDate,
    });
  };

  useEffect(() => {
    if (file) setGetFileLoading(false);
  }, [file]);

  useEffect(() => {
    saveFileData(profileData);
  }, [getFile, profileData]);

  useDeepCompareEffect(() => {
    if (!DeepEqual(data, userData)) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [data]);

  const inputProps = (
    name,
    label,
    optional = false,
    disabled = false,
    action = null,
    type = "text"
  ) => ({
    name,
    inputLabel: label,
    data: data ? data[name] : "",
    error: error ? error[name] : "",
    optional,
    action,
    type,
    disabled,
    lng,
    setError,
    updateFields: updateFormData,
    t,
  });

  const selectProps = (
    name,
    value,
    label,
    optional = false,
    disabled = false
  ) => ({
    name,
    inputLabel: label,
    data: value,
    error: error ? error[name] : "",
    optional,
    disabled,
    lng,
    setError,
    updateFields: updateFormData,
    t,
  });

  const radioProps = (name, options, disabled = false, action = null, col) => ({
    name,
    data: data ? data[name] : "",
    col,
    action,
    error: error ? error[name] : "",
    disabled,
    options,
    setError,
    updateFields: updateFormData,
    t,
  });

  const passwordProps = () => ({
    passwordData: data ? data.password : "",
    confirmData: data ? data.password2 : "",
    passwordError: error ? error.password : "",
    confirmError: error ? error.password2 : "",
    setError,
    updateFields: updateFormData,
    t,
  });

  const updateFields = (fields, setState) =>
    setState((prev) => ({ ...prev, ...fields }));
  const updateFormData = (fields) => updateFields(fields, setData);

  const handleChangeVerified = (
    value,
    field,
    verifyActive,
    setVerified,
    verifyHandler,
    verified
  ) => {
    const isNotSameValue = value?.trim() !== userData?.[field];
    if (value && isNotSameValue) {
      setVerified(0);
      updateFormData({ [field]: value });
      verifyHandler(value);
    } else if (value && !isNotSameValue) {
      setVerified(verified);
    } else {
      verifyHandler("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = new FormData(event.target);

    let slug = '';

    if (account_type === USER_TYPE.INDIVIDUAL) {
      switch (currentTab) {
        case 0:
          slug = "update"
          break;
        case 1:
          slug = "password/change"
          break;
      }
    } else if (account_type === USER_TYPE.ADMIN || account_type === USER_TYPE.OFFICER) {
      switch (currentTab) {
        case 0:
          slug = "update"
          break;
        case 1:
          slug = "corporate/update"
          break;
        case 2:
          slug = "password/change"
          break;
      }
    }

    postForm(api_name, slug, updatedFormData, setError, null, true, saveData, token);
  };

  const setToDefaultValue = () => {
    setData(userData);
    setChanged(false);
    setMobileVerified(userData.is_verified_mobile === "1");
    setShowSMS(userData.is_receive_sms === "1");
    setEmailVerified(userData.is_verified_email === "1");
    setAltEmailVerified(userData.is_verified_alternative_email === "1");
  };

  const headData = () => (
    <UserIconData userData={profileData} lang={lang} icon={icon} t={t} />
  );

  const billingDetails = (disabled = false) => {
    return (
      <>
        <Input
          {...inputProps(
            "billing_account_no",
            "Billing Account No.",
            0,
            1,
            disabled
          )}
        />
        <div className="gap-xxx-big flex-col md:flex-row flex md:gap-x-sm">
          <Input
            {...inputProps(
              "billing_payer_name",
              "Name of Payer",
              0,
              1,
              disabled
            )}
          />
        </div>
      </>
    );
  };

  const correspondenceAddress = (disabled = false) => {
    return (
      <>
        <Line />
        <ProfileSection title="Correspondence Address" t={t}>
          <div className="flex gap-x-big flex-col">
            <AreaAndDistrict
              updateFields={updateFormData}
              areaData={data?.address_area}
              districtData={data?.address_district}
              subDistrictData={data?.address_sub_district}
              AreaError={error && error["address_area"]}
              districtError={error && error["address_district"]}
              disabled={account_type !== USER_TYPE.ADMIN}
              lng={lng}
              t={t}
            />
            <Input
              {...inputProps(
                "address_street",
                "No. and Name of Street or Village",
                0,
                account_type !== USER_TYPE.ADMIN,
              )}
            />
            <Input
              {...inputProps(
                "address_building",
                "Name of Building/Estate",
                1,
                  account_type !== USER_TYPE.ADMIN,
              )}
            />
            <Input
              {...inputProps(
                "address_block",
                "Block/Tower/House/Others",
                1,
                  account_type !== USER_TYPE.ADMIN,
              )}
            />
            <div className="flex-col md:flex-row flex gap-sm">
              <Input {...inputProps("address_floor", "Floor", 0, account_type !== USER_TYPE.ADMIN)} />
              <Input
                {...inputProps(
                  "address_room",
                  "Flat/Shop/Unit/Suite/Room/Others",
                  1,
                    account_type !== USER_TYPE.ADMIN,
                )}
              />
            </div>
          </div>
        </ProfileSection>
      </>
    );
  };

  const accountDetail = () => {
    return (
      <>
        <ProfileSection title="Account Details" t={t}>
          <Input {...inputProps("username", "Username", 0, 1)} />
          {!individual && (
            <div className="gap-xxx-big flex-col md:flex-row flex md:gap-x-sm">
              <Input {...inputProps("name_en", "English Name", 0, 1)} />
              <Input {...inputProps("name_zh", "Chinese Name", 0, 1)} />
            </div>
          )}
        </ProfileSection>
        <Line />

        {individual && (
          <>
            <ProfileSection title="Your information" t={t}>
              <Radio {...radioProps("title", particulars_options, 1)} />
              <div className="gap-xxx-big flex-col md:flex-row flex md:gap-x-sm">
                <Input
                  {...inputProps("name_en", "English Name", 0, 1)}
                  maxLength="100"
                />
                <Input
                  {...inputProps("name_zh", "Chinese Name", 0, 1)}
                  maxLength="28"
                />
              </div>
              {/*{billingDetails()}*/}
            </ProfileSection>
            <Line />
          </>
        )}

        <ProfileSection title="Contact Information" t={t}>
          <div className="flex gap-sm flex-col lg:flex-row">
            <Input
                {...inputProps("email", "Email Address", 0)}
                action={(value) =>
                    handleChangeVerified(
                        value,
                        "email",
                        emailVerifyActive,
                        setEmailVerified,
                        handleEmailVerifyActive,
                        userData.is_verified_email === "1"
                    )
                }
            />
            {emailVerified ? (
                <>
                  <input
                      type="hidden"
                      name={`uuid_email`}
                      value={data["uuid_email"] || ""}
                  />
                  <VerifiedButton data={emailVerified}/>
                </>
            ) : (
                <VerifiedButton data={emailVerified}/>
            )}
          </div>
          {!emailVerified ? (
              <>
                <VerifyField
                    {...inputProps(
                        "email_verification_code",
                        "Email Verification Code",
                        0,
                        !emailVerifyActive
                    )}
                    parentData={{ email: data?.email || "", email_alternative: data?.email_alternative || "" }}
                    uuid={data["uuid_email"]}
                    show={emailVerifyActive}
                    codeError={error["uuid_email"]}
                    api_name={api_name}
                    updateFields={updateFormData}
                    setFieldVerified={setEmailVerified}
                    isVerified={emailVerified}
                    authorization={token}
                />
              </>
          ) : null}
          <div className="flex gap-sm flex-col lg:flex-row">
            <Input
                {...inputProps("email_alternative", "Alternative E-mail Address")}
                action={(value) =>
                    handleChangeVerified(
                        value,
                        "email_alternative",
                        altEmailVerifyActive,
                        setAltEmailVerified,
                        handleAltEmailVerifyActive,
                        userData.is_verified_alternative_email === "1"
                    )
                }
            />
            {altEmailVerified ? (
                <>
                  <input
                      type="hidden"
                      name={`uuid_email_alternative`}
                      value={data["uuid_email_alternative"] || ""}
                  />
                  <VerifiedButton data={altEmailVerified}/>
                </>
            ) : (
                <VerifiedButton data={altEmailVerified}/>
            )}
          </div>
          {!altEmailVerified && altEmailVerifyActive ? (
              <>
                <VerifyField
                    {...inputProps(
                        "alternative_verification_code",
                        "Alternative Email Verification Code",
                        0,
                        !altEmailVerifyActive
                    )}
                    parentData={{ email_alternative: data?.email_alternative || "", email: data?.email || "" }}
                    uuid={data["uuid_email_alternative"]}
                    show={altEmailVerifyActive}
                    codeError={error["uuid_email_alternative"]}
                    updateFields={updateFormData}
                    setFieldVerified={setAltEmailVerified}
                    isVerified={altEmailVerified}
                    api_name={api_name}
                    authorization={token}
                />
              </>
          ) : null}
          <div className="flex gap-sm flex-col lg:flex-row">
            <div className="min-w-200px">
              <Select
                  {...selectProps(
                      "country_code",
                      data?.country_code,
                      "Country/Area Code"
                  )}
              />
            </div>
            <Input
                {...inputProps("mobile", "Contact No.", 0, 0, null, "tel")}
                action={(value) =>
                    handleChangeVerified(
                        value,
                        "mobile",
                        mobileVerifyActive,
                        setMobileVerified,
                        handleMobileVerifyActive,
                        userData.is_verified_mobile === "1"
                    )
                }
            />

            {mobileVerified ? (
                <>
                  <input
                      type="hidden"
                      name={`uuid_mobile`}
                      value={data["uuid_mobile"] || ""}
                  />
                  <VerifiedButton data={mobileVerified}/>
                </>
            ) : (
                <VerifiedButton data={mobileVerified}/>
            )}
          </div>
        </ProfileSection>
        <Line/>
        <ProfileSection title="SMS Notification Services" t={t}>
          <Radio
              {...radioProps("is_receive_sms", smsOption, 0, setShowSMS, 1)}
          />
          {showSMS && !mobileVerified ? (
              <>
                <VerifyField
                    {...inputProps(
                        "mobile_verification_code",
                        "Mobile Verification Code"
                    )}
                    parentData={{
                      mobile: data?.mobile,
                      country_code: data?.country_code,
                    }}
                    uuid={data["uuid_mobile"]}
                    show={mobileVerifyActive || data?.mobile}
                    codeError={error["uuid_mobile"]}
                    updateFields={updateFormData}
                    setFieldVerified={setMobileVerified}
                    isVerified={mobileVerified}
                    api_name={api_name}
                    authorization={token}
                />
              </>
          ) : null}
          <Input {...inputProps("fax", "Fax No.", 0, 0, null, "tel")} />
        </ProfileSection>
        {individual && correspondenceAddress()}
      </>
    );
  };

  const corporateDetails = () => {
    const disabled = true;
    return (
      <>
        {/*<ProfileSection title="Your information" t={t}>*/}
        {/*  <Radio {...radioProps("title", particulars_options, 1)} />*/}
        {/*  <div className="gap-xxx-big flex-col md:flex-row flex md:gap-x-sm">*/}
        {/*    <Input*/}
        {/*      {...inputProps(*/}
        {/*        "corporate_contact_name_en",*/}
        {/*        "English Name",*/}
        {/*        0,*/}
        {/*        disabled*/}
        {/*      )}*/}
        {/*    />*/}
        {/*    <Input*/}
        {/*      {...inputProps(*/}
        {/*        "corporate_contact_name_zh",*/}
        {/*        "Chinese Name",*/}
        {/*        0,*/}
        {/*        disabled*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*  <Input*/}
        {/*    {...inputProps(*/}
        {/*      "corporate_contact_post_title",*/}
        {/*      "Capacity/Post title",*/}
        {/*      0,*/}
        {/*      disabled*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</ProfileSection>*/}
        {/*<Line />*/}
        <ProfileSection title="Corporate Details" t={t}>
          <Input
            {...inputProps(
              "corporate_brn",
              "Business Registration Certificate",
              0,
              disabled
            )}
          />
          <div className="gap-xxx-big flex-col md:flex-row flex md:gap-x-sm">
            <Input
              {...inputProps(
                "corporate_name_en",
                "English Name of Corporate/Organisation",
                0,
                disabled
              )}
            />
            <Input
              {...inputProps(
                "corporate_name_zh",
                "Chinese Name of Corporate/Organisation",
                0,
                disabled
              )}
            />
          </div>
        </ProfileSection>

        {correspondenceAddress(disabled)}
        {/*<Line />*/}

        {/*<ProfileSection title="Billing Account Details" t={t}>*/}
        {/*  {billingDetails(disabled)}*/}
        {/*</ProfileSection>*/}
        {/*<Line />*/}
        {/*<div className="relative">*/}
        {/*  <ProfileSection title="Document" t={t}>*/}
        {/*    {getFileLoading ? (*/}
        {/*      <CircularProgress color="inherit" />*/}
        {/*    ) : (*/}
        {/*      <UploadFilePreview t={t} data={file} disabled={disabled} />*/}
        {/*    )}*/}
        {/*  </ProfileSection>*/}
        {/*</div>*/}
      </>
    );
  };

  const passwordDetail = () => {
    return (
      <ProfileSection
        t={t}
        title="Change Password"
        subtitle="Enter a new password to reset the password on your account. We'll ask for this password whenever you log in."
      >
        <Password
          {...inputProps("current_password", "Current Password")}
          showEyeIcon={true}
        />
        <PasswordTemplate {...passwordProps()} />
      </ProfileSection>
    );
  };

  let tab = [];
  if (individual) {
    tab = [
      {
        label: "Account Details",
        field: accountDetail(),
      },
      {
        label: "Change Password",
        field: passwordDetail(),
      },
    ];
  } else {
    tab = [
      {
        label: "Account Details",
        field: accountDetail(),
      },
      {
        label: "Corporate Details",
        field: corporateDetails(),
      },
      {
        label: "Change Password",
        field: passwordDetail(),
      },
    ];
  }

  if (userLoading) return <LoadingUI />;

  return (
    <>
      <Breadcrumb lng={lng} />
      <form onSubmit={handleSubmit} className="w-full">
        <AccountManagementTemplate
          lng={lng}
          accountType={account_type}
          title="My Profile"
          setError={setError}
        >
          <TabTemplate
            lng={lng}
            title="My Profile"
            headData={headData()}
            tab={tab}
            changed={changed}
            backButton={
              currentTab !== 1 || (currentTab === 1 && (account_type === USER_TYPE.INDIVIDUAL ||  account_type === USER_TYPE.ADMIN)) ?  <DiscardButton t={t} action={setToDefaultValue} /> : null

            }
            saveButton={currentTab !== 1 || (currentTab === 1 && (account_type === USER_TYPE.INDIVIDUAL ||  account_type === USER_TYPE.ADMIN)) ? "Save Changes" : null}
            loading={loading}
            setToDefaultValue={setToDefaultValue}
            setCurrentTab={setCurrentTab}
            setError={setError}
          />
        </AccountManagementTemplate>
      </form>
    </>
  );
};

export default MyProfile;
