import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { ReactComponent as TickSVG } from "../../assets/images/others/tick.svg";

const Checkbox = ({
  readOnly,
  name,
  inputLabel,
  check,
  setDisableBtn,
  disabledBtn,
  updateFields,
  data,
  t,
  action,
  itemStart,
  flex = true,
  disabled = false,
  labelClass,
}) => {
  const is_checked = data === true || data === "1" || data === 1 || check;
  const [value, setValue] = useState(is_checked);
  const handleChange = (e) => {
    const newValue = e.target.checked ? "1" : "0";
    if (setDisableBtn) {
      setDisableBtn(!disabledBtn);
    }
    if (action) action(e, setValue);
    if (updateFields) {
      updateFields({ [name]: newValue });
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (is_checked) {
      setValue("1");
    } else if (!is_checked) {
      setValue("0");
    }
    if (!setDisableBtn && !is_checked) {
      return;
    }
    if (setDisableBtn && is_checked) {
      setDisableBtn(false);
    }
  }, [data]);

  return (
    <>
      <label
        className={`checkbox-container label1 text-800 ${
          disabled || readOnly ? "pointer-events-none" : "cursor-pointer"
        } flex w-fit`}
        style={{ alignItems: itemStart ? "normal" : "center" }}
        htmlFor={name}
      >
        <input
          name={name}
          id={name}
          type="checkbox"
          readOnly={readOnly}
          className="opacity-0 w-0 h-0 align-top me-sm border-default"
          onChange={handleChange}
          value={value}
          disabled={disabled}
          checked={value === "1"}
        />
        {value === "0" && (
          <input name={name} type="hidden" value={data || value} />
        )}
        <span
          className={`checkmark ${
            inputLabel ? "mr-md" : ""
          } bg-white  rounded-x-sm flex justify-center items-center`}
        >
          <div className="flex w-full h-full justify-center items-center">
            <TickSVG width={12} height={12} />
          </div>
        </span>
        <span className={`leading-normal ${labelClass ? labelClass : ""}`}>
          {t ? t(inputLabel) : inputLabel}
        </span>
      </label>
    </>
  );
};

export default Checkbox;
