import React, {useState, useEffect} from "react";
import Input from "../../components/input/Input";
import FieldCard from "../../components/form/FieldCard";
import Switcher from "../../components/button/Switcher";
import ErrorMessage from "../../components/error/ErrorMessage";
import MtCaptcha from "../../components/MtCaptcha/MtCaptcha";
import Select from "../../components/input/Select";

const StepOne = ({ props, api_name, slug, activeIndex, setActiveIndex, data, error, setError, updateFields, loadingCapthcha, t }) => {
    const [stepOneData, setStepOneData] = useState({
        username: data ? data['username'] : '',
        email: data ? data['email'] : '',
        email_address_alternative: data ? data['email_address_alternative'] : '',
        mobile: data ? data['mobile'] : '',
        country_code: data ? data['country_code'] : '',
    });

    useEffect(() => {
        setStepOneData({
            username: data ? data['username'] : '',
            email: data ? data['email'] : '',
            email_address_alternative: data ? data['email_address_alternative']: '',
            mobile: data ? data['mobile'] : '',
            country_code: data ? data['country_code'] : '',
        });
    }, [data]);

    const clearNumberValue = () => {
        updateFields({number: ''});
    }

    const hasCountryOrMobile = data['country_code'] || data['mobile'];
    const filledInputsCount = Object.values(stepOneData).filter(value => value).length;
    const disableInputs = filledInputsCount >= 2 || (hasCountryOrMobile && filledInputsCount > 1);
    return (
        <>
            {api_name === 'forgot' ?
                <div className="gap-md flex flex-col">
                    <FieldCard t={t} title="Account Information" subtitle={slug === "password" ? 'Please enter any two of the following information you previously registered in the Web portal to receive the verification code' : 'Please enter the following information in the Web Portal to receive the verification code'}>
                        {(error && error.message) && <ErrorMessage message={error?.message} t={t}/>}
                        {slug && slug === 'password' ?
                            <>
                                <Input {...props("username", "Username")} disabled={disableInputs && !data['username']}/>
                                <Input {...props("email", "Email Address")} disabled={disableInputs && !data['email']}/>
                                <Input {...props("email_address_alternative", "Alternative E-mail Address")} disabled={disableInputs && !data['email_address_alternative']}/>
                                <div className="flex gap-md flex-col md:flex-row">
                                    <Select {...props("country_code", "Country/Area Code")} disabled={disableInputs && !data['mobile'] && !data['country_code']} removeOption/>
                                    <Input {...props("mobile", "Contact No.")} disabled={disableInputs && !data['mobile'] && !data['country_code']}/>
                                </div>
                            </>
                            :
                            <>
                                <Input {...props("email", "Email Address")}/>
                                <Switcher {...props("type", "Identification")} options={[
                                    {value:1, label: 'HKIC'},
                                    {value:2, label: 'Passport'},
                                    {value:3, label: 'Other Document'},
                                ]}
                                    action={clearNumberValue}
                                    setActiveIndex={setActiveIndex}
                                    activeIndex={activeIndex}
                                    errorOption={['number']}
                                />
                                {Number(activeIndex) === 1 ?
                                    <Input {...props("number", "Hong Kong Identity Card No.")}/>
                                    : Number(activeIndex) === 2 ?
                                    <Input {...props("number", "Passport No.")}/>
                                    :
                                    <Input {...props("number", "Other Travel Document No./Business Registration Certificate")}/>
                                }
                            </>
                        }
                        <MtCaptcha loadingCapthcha={loadingCapthcha} error={error['mtcaptcha-verifiedtoken']}/>
                    </FieldCard>
                </div>
                :
                <div className="gap-md flex flex-col">
                    <FieldCard t={t} title="Enter Email Address" subtitle="Please enter your email address to receive the verification code">
                        <Input {...props("email", "Email Address")}/>
                        <MtCaptcha loadingCapthcha={loadingCapthcha} error={error['mtcaptcha-verifiedtoken']} />
                    </FieldCard>
                </div>
            }

        </>
    )
}

export default StepOne;