import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Select, MenuItem, Divider } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import RoundedCheckbox from "../RoundedCheckbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useTranslation} from "react-i18next";

const WhiteStatueSelect = ({ props, upAndLowMenu, name }) => {
  const { t } = useTranslation();
  const [selectedUpper, setSelectedUpper] = useState(null);
  const [selectedLower, setSelectedLower] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const occupation_option =
    props && props?.filterOption?.statusOccupationList
      ? props?.filterOption?.statusOccupationList
      : [];
  const physical_option =
    props && props?.filterOption?.statusPhysicalList
      ? props?.filterOption?.statusPhysicalList
      : [];
  const physical_filteredOptions = physical_option.filter(
    (item) => item.id !== ""
  );
  const occupation_filteredOptions = occupation_option.filter(
    (item) => item.id !== ""
  );
  const lang = props?.lang;

  const getOptions = (name) => {
    switch (name) {
      case "status_occupation":
        return occupation_option;
      case "status_physical":
        return physical_option;
      default:
        return [];
    }
  };

  useEffect(() => {
    if (!props?.searchData.status_occupation) setSelectedUpper("");
    if (!props.searchData.status_physical) setSelectedLower("");
    if (!props.searchData.name) setSelectedValue("");
    if (props?.data) setSelectedValue(props?.data);
  }, [props?.searchData, props?.data]);

  const handleUpperChange = (event) => {
    const value = event.target.value;
    const newValue = selectedUpper === value ? "" : value;
    setSelectedUpper(newValue);
    updateprops(newValue, selectedLower);
    if (props?.setResetPagination) props?.setResetPagination(true);
    if (props?.action) props?.action(event);
  };

  const handleLowerChange = (event) => {
    const value = event.target.value;
    const newValue = selectedLower === value ? "" : value;
    setSelectedLower(newValue);
    updateprops(selectedUpper, newValue);
    if (props?.setResetPagination) props?.setResetPagination(true);
    if (props?.action) props?.action(event);
  };

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    if (props?.setResetPagination) props?.setResetPagination(true);
    setSelectedValue(newValue);
  };
  const updateprops = (upper, lower) => {
    const updatedStatus = { status_occupation: upper, status_physical: lower };
    if (props?.updateFields) props?.updateFields(updatedStatus);
  };

  const renderValue = (selected) => {
    if (upAndLowMenu) {
      let upper = "";
      let lower = "";

      if (!selected || (!selected[0] && !selected[1])) {
        return <span className="label1-400 text-body">{t('Status')}</span>;
      } else {
        if (selected[0]) {
          upper = occupation_filteredOptions.find(
            (option) => option.id === selected[0]
          )?.[`name${lang}`];
        }
        if (selected[1]) {
          lower = physical_filteredOptions.find(
            (option) => option.id === selected[1]
          )?.[`name${lang}`];
        }
      }

      return `${upper}${upper && lower ? ", " : ""}${lower}`;
    } else {
      if (!selected) {
        const newName = name.replace(/^status_/, "");
          return (
              <div className="text-placeholder">
                  {t(newName.charAt(0).toUpperCase() + newName.slice(1) + ' Status')}
              </div>
          );
      } else {
        return getOptions(name).find((option) => option.id === selected)?.[
          `name${lang}`
        ];
      }
    }
  };

  const upperMenuItems = () => {
    return occupation_filteredOptions.map((item, index) => {
      return (
        <MenuItem
          key={item.value}
          value={item.value}
          name="status_occupation"
          onClick={() => handleUpperChange({ target: { value: item.id } })}
          sx={{
            "&:hover": {
              "&.Mui-selected": {
                background: "var(--surface-secondary)",
              },
              background: "var(--surface-secondary)",
            },
            "&.MuiList-root": { padding: "0" },
            "&.Mui-selected": {
              color: "var(--text-body) !important",
              background: "none",
            },
            maxWidth: "100%",
            whiteSpace: "normal",
            display: "flex",
            gap: "var(--spacing-md)",
            padding: "0",
            borderRadius: "var(--radius-x-sm)",
          }}
        >
          <RoundedCheckbox
            style={{ padding: "0 var(--spacing-x-sm)", pointerEvents: "none" }}
            data={selectedUpper === item.id}
          />
          <ListItemText
            primary={item?.[`name${lang}`]}
            sx={{ padding: "var(--spacing-sm) var(--spacing-md)" }}
          />
        </MenuItem>
      );
    });
  };

  const lowerMenuItems = () => {
    return physical_filteredOptions.map((item, index) => {
      return (
        <MenuItem
          key={item.id}
          value={item.id}
          name="status_physical"
          onClick={() => handleLowerChange({ target: { value: item.id } })}
          sx={{
            "&:hover": {
              "&.Mui-selected": {
                background: "var(--surface-secondary)",
              },
              background: "var(--surface-secondary)",
            },
            "&.MuiList-root": { padding: "0" },
            "&.Mui-selected": {
              color: "var(--text-body) !important",
              background: "none",
            },
            maxWidth: "100%",
            whiteSpace: "normal",
            display: "flex",
            gap: "var(--spacing-md)",
            padding: "0",
            borderRadius: "var(--radius-x-sm)",
          }}
        >
          <RoundedCheckbox
            style={{ padding: "0 var(--spacing-x-sm)", pointerEvents: "none" }}
            data={selectedLower === item.id}
          />
          <ListItemText
            primary={item?.[`name${lang}`]}
            sx={{ padding: "var(--spacing-sm) var(--spacing-md)" }}
          />
        </MenuItem>
      );
    });
  };

  const singleMenuItems = () => {
    return getOptions(name).map((item) => (
      <MenuItem
        key={item.id}
        value={item.id}
        name={name}
        onClick={() => handleValueChange({ target: { value: item.id } })}
        sx={{
          "&.MuiList-root": { padding: "0" },
          "&.Mui-selected": {
            color: "var(--text-body) !important",
            background: "none",
          },
          maxWidth: "100%",
          whiteSpace: "normal",
          display: "flex",
          gap: "var(--spacing-md)",
          padding: "0",
          borderRadius: "var(--radius-x-sm)",
        }}
      >
        <RoundedCheckbox
          style={{ padding: "0 var(--spacing-x-sm)", pointerEvents: "none" }}
          data={!selectedValue ? false : selectedValue === item.id}
        />
        <ListItemText
          primary={item?.[`name${lang}`]}
          sx={{ padding: "var(--spacing-sm) var(--spacing-md)" }}
        />
      </MenuItem>
    ));
  };

  return (
    <Select
      IconComponent={KeyboardArrowDownIcon}
      name={name ?? "status"}
      multiple={upAndLowMenu}
      className={`body1 text-body w-full`}
      value={upAndLowMenu ? [selectedUpper, selectedLower] : selectedValue}
      displayEmpty
      renderValue={renderValue}
      MenuProps={{
        PaperProps: {
          className: "custom-scrollbar",
          style: {
            padding: "var(--spacing-x-sm)",
            maxHeight: "322px",
            marginTop: "var(--spacing-sm)",
            overflowY: "auto",
            background: "var(--surface-card)",
            borderRadius: "var(--radius-sm)",
            boxShadow: "var(--box-shadow-lg)",
          },
        },
        MenuListProps: { style: { padding: "0" } },
      }}
      sx={{
        "&.MuiInputBase-root": { border: "0" },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid var(--surface-primary)",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--border-default)",
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "1px solid var(--border-default)",
        },
        background: "var(--surface-card)",
        borderRadius: "var(--radius-sm)",
        height: "48px",
      }}
    >
      {upAndLowMenu ? upperMenuItems() : singleMenuItems()}
      {upAndLowMenu && <Divider />}
      {upAndLowMenu && lowerMenuItems()}
    </Select>
  );
};

export default WhiteStatueSelect;
