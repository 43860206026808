import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  inputLabelClasses,
  filledInputClasses,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ErrorMessage from "../../components/error/ErrorMessage";

const Input = ({
  name,
  inputLabel,
  help_text,
  type,
  optional,
  action,
  updateFields,
  data,
  disabled,
  error,
  maxLength,
  minLength,
  min,
  max,
  t,
  setError,
  readonly,
  numberWithArrow,
}) => {
  const [showClearBtn, setShowClearBtn] = useState(data || false);
  const [value, setValue] = useState(data || "");
  const input_field = useRef(null);
  const verifiInput =
    name === "email_verification_code" ||
    name === "mobile_verification_code" ||
    name === "alternative_verification_code" ||
    name === "verification_code";
  const verifiParentInput =
    name === "email" || name === "mobile" || name === "email_alternative";
  const codeError = {
    email_verification_code: "uuid_email",
    mobile_verification_code: "uuid_mobile",
    alternative_verification_code: "uuid_email_alternative",
  };

  useEffect(() => {
    setValue(data || "");
    if (action && verifiParentInput) {
      action(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setShowClearBtn(newValue.length || data);
    setValue(newValue);
    if (verifiInput && setError)
      setError((preError) => ({ ...preError, [codeError[name]]: "" }));
    if (setError) setError((preError) => ({ ...preError, [name]: "" }));
    if (action) {
      action(newValue, setValue);
    }
    if (updateFields && !verifiInput) {
      updateFields({ [name]: newValue });
    }
  };

  const handleClearValue = () => {
    input_field.current.value = "";
    setShowClearBtn(false);
    setValue("");
    if (updateFields) updateFields({ [name]: "" });
    if (action) action("");
  };

  return (
    <FormControl variant="filled" className="w-full">
      <InputLabel
        error={!!error}
        htmlFor={name}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "var(--text-caption)",
          },
          color: "var(--text-body)",
          [`&.Mui-error.${inputLabelClasses.focused}`]: {
            [`&.${inputLabelClasses.shrink}`]: {
              color: "var(--text-warning)",
            },
          },
          [`&.${inputLabelClasses.focused}`]: {
            [`&.${inputLabelClasses.shrink}`]: {
              color: "var(--label-icon-on-light)",
            },
          },
          [`&.${inputLabelClasses.shrink}`]: {
            color: "var(--text-caption)",
          },
          [`&.Mui-error.${inputLabelClasses.shrink}`]: {
            color: "var(--text-warning)",
          },
        }}
        className={`${readonly ? "readonly" : ""} body2 text-body`}
      >
        {t ? t(inputLabel) : inputLabel}
      </InputLabel>
      <FilledInput
        className={`${readonly ? "readonly" : ""} input bg-surface-base`}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "var(--text-body)",
            background: "var(--surface-card)",
            borderRadius: "var(--radius-x-sm)",
          },
          "& fieldset": { border: "none" },
          background: "var(--surface-base)",
          [`&.${filledInputClasses.disabled}`]: {
            ":before": {
              content: "none",
            },
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "var(--radius-x-sm)",
          },
          ":before": {
            borderBottomColor: "var(--Neutral-300)",
          },
          ":after": {
            borderBottomColor: "var(--surface-primary)",
          },
            ...(numberWithArrow === false && {
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none'
                },
                '& input[type=number]': {
                    MozAppearance: 'textfield'
                },
            }),
        }}
        ref={input_field}
        id={name}
        name={name}
        type={type || "text"}
        error={!!error}
        onChange={handleChange}
        disabled={disabled}
        value={value}
        inputProps={{
          maxLength: maxLength || "255",
          minLength: minLength,
          min: min,
          max: max,
          readOnly: readonly,
        }}
        endAdornment={
          (error || showClearBtn) && !disabled && !readonly ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear input text"
                edge="end"
                onClick={handleClearValue}
                className="text-800"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {type !== "number" &&
                  type !== "date" &&
                  showClearBtn &&
                  value && <HighlightOffOutlinedIcon />}
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
      <ErrorMessage
        error={error}
        help_text={help_text}
        optional={optional}
        t={t}
      />
    </FormControl>
  );
};

export default Input;
