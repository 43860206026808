import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AddDataToHTML } from '../assets/js/global';
import {useUserLoginContext} from "../context/UserLoginProvider";

const useResetDataThemeOnPageChange = (profileData) => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('/login')) {
            AddDataToHTML("data-theme", 'blue');
        }else if (profileData && Object.entries(profileData).length) {
            AddDataToHTML("data-theme", profileData?.themeColour?.description);
        } else {
            AddDataToHTML("data-theme", 'blue');
        }
    }, [location, profileData]);
};

export default useResetDataThemeOnPageChange;