import PropertyTemplate from "../../components/property/Template";
import DataTable from "../../components/table/DataTable";
import React, {useEffect, useMemo, useState} from "react";
import UnreadICON from '../../assets/images/header/notification_unread.svg?url';
import ReadICON from '../../assets/images/header/notification_read.svg?url';
import SmallFunction from "../../components/function/SmallFunction";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/header/Breadcrumb";
import { RemoveButton } from "../../components/button/SingleButton";
import { useTranslation } from "react-i18next";
import {useUserLoginContext} from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import Popup from "../../components/popup/Popup";
import useDocument from "../../hooks/useDocument";
import EmptyDataText from "../../components/empty/EmptyDataText";

const NotificationCentre = ({ lng }) => {
    const { t } = useTranslation()
    const { changeDate } = SmallFunction();
    const { userLoading, token} = useUserLoginContext()
    const [notification, setNotification] = useState({})
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [selectedId, setSelectedId] = useState(null);
    const { postForm, loading } = SubmitAndFetch({ lng, t });
    const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
    useDocument("Notification Centre");

    const handleDeleteNotification = async (id) => {
        await postForm(`notification/delete/${id}`, null, null, null, null, true, null, token, null, null, true, true)
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: "isRead",
                header: "",
                size: 91,
                grow: false,
                enableSorting: false,
                Cell: ({ cell }) => {
                    return cell.getValue() ?
                        <img src={ReadICON} width={32} height={32} alt={t('read')} />
                        :
                        <img src={UnreadICON} width={32} height={32} alt={t('unread')} />;
                },
            },
            {
                accessorKey:`subject`,
                header: t("Subject"),
                minSize: 713,
                grow: false,
                enableSorting: false,
                Cell: ({ cell, row }) => {
                    return (
                        <Link className={`${row.original.isRead ? 'body2' : 'font-medium'} text-title hover:body2_fw600 hover:underline leading-normal`} to={`/${lng}/notification-centre/details/${row.original.id}`}>
                            {row.original[`subject${lang}`]}
                        </Link>
                    );
                },
            },
            {
                accessorKey: "date",
                header: t("Date"),
                objKey: 'date',
                minSize: 300,
                grow: true,
                Cell: ({ cell, row }) => {
                    return (
                        <div className={`${row.original.isRead ? 'body2' : 'font-medium'} text-body flex justify-between w-full mr-lg`}>
                            {changeDate(cell.getValue())}
                            <RemoveButton t={t} handleRemove={() => {
                                setShowDeletePopup(true);
                                setSelectedId(row.original.id);
                            }} />
                        </div>
                    );
                },
            }
        ], [lng, lang, t, changeDate, notification]
    );

    if (userLoading) return <LoadingUI/>;

    const tableDataProps = {
        columns,
        apiProps: {
            isFetching,
            setIsFetching,
            setState: setNotification,
            data: notification,
            api: 'notification',
        },
        pageSize: 20,
        pageSizeOptions: [20, 50],
    }

    const apiProps = {
        loading: deleteLoading,
        customSubmit: () => handleDeleteNotification(selectedId),
    }

    return (
        <>
            {showDeletePopup && <Popup t={t} lng={lng} setState={setShowDeletePopup} confirm_popup={true} apiProps={apiProps} title="Confirm to delete notification?" text='Are you sure you want to delete this notification? Once deleted, it cannot be undone.'/>}
            <Breadcrumb
                lng={lng}
            />
            <PropertyTemplate
                t={t}
                lng={lng}
                title="Notification Centre"
            >
                <DataTable {...tableDataProps} emptyMessage={
                    <EmptyDataText text={t('No Notification')} />
                }/>
            </PropertyTemplate>
        </>
    );
};

export default NotificationCentre;
