import { Navigate } from "react-router-dom";
import { useUserLoginContext } from "./UserLoginProvider";
import {useTranslation} from "react-i18next";
import LoadingUI from "../components/loading/LoadingUI";

const PrivateRoute = ({ element }) => {
    const {
        i18n: { language },
    } = useTranslation();

    const { profileData } = useUserLoginContext();
    // return element;
    return profileData ?
        element :
        <>
            <LoadingUI />
            <Navigate to={`/${language}/login`} />;
        </>
};

export default PrivateRoute;