import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import PopupButton from "../../components/button/PopupButton";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import ProfileSection from "../../components/my-profile/ProfileSection";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import { PrimaryButton, RedButton } from "../../components/button/SingleButton";
import ErrorMessage from "../error/ErrorMessage";

const Popup = ({
  id,
  apiProps,
  buttonProps,
  children,
  setState,
  name,
  t,
  sx,
  confirm_popup = false,
  import_Popup = false,
  title,
  text,
  bottomSticky,
  classes,
}) => {
  const { popup_button, import_button } = PopupButton({ t });

  const { postForm, loading } = SubmitAndFetch({ lng: apiProps?.lng, t });

  const closePopup = () => {
    if (apiProps?.setError) apiProps?.setError({});
    setState(false);
    if (apiProps?.setErrorState) apiProps?.setErrorState({});
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    if (apiProps?.setError) apiProps?.setError({});
    const form_data = new FormData(event.target);
    postForm(
      apiProps?.api_name,
      null,
      form_data,
      apiProps?.setError,
      null,
      null,
      false,
      apiProps?.token,
      null,
      null,
      true
    );
  };

  let idArr = [];
  if (id) {
    switch (typeof id) {
      case "object":
        idArr = Object.keys(id).map((key) => [key]);
        break;
    }
  }

  const confirmPopup = () => {
    return (
      <div
        className={`form-pop-up bg-white rounded-sm md:rounded-big py-x-big px-xx-big md:py-x-big md:px-xxx-lg max-w-[95%] md:max-w-[650px] min-h-[325px] ${classes}`}
      >
        <div className="flex justify-center items-center h-full w-full">
          <form
            onSubmit={
              apiProps?.customSubmit
                ? (event) => {
                    event.preventDefault();
                    apiProps?.customSubmit(event);
                  }
                : (event) => handleSubmitData(event)
            }
            className="h-full w-full"
          >
            {(idArr.length || id) && (
              <input
                type="hidden"
                value={idArr.length ? idArr.join(",") : id}
                name={name}
              />
            )}
            <div className="flex flex-col items-center justify-center gap-md md:gap-x-big text-center h-full">
              <h1 className="h1 text-neutral-600">{title}</h1>
              <div className="body1 text-black w-full">{text}</div>
              <div className="flex gap-x-big" style={{ width: "100%" }}>
                <PrimaryButton
                  t={t}
                  action={closePopup}
                  label={buttonProps?.cancelLabel ? t(buttonProps?.cancelLabel) : "Cancel"}
                  className="w-full"
                />
                <RedButton
                  t={t}
                  type={buttonProps?.buttonType ? buttonProps?.buttonType : "submit"}
                  loading={loading || apiProps?.loading || null}
                  label={buttonProps?.confirmLabel ? t(buttonProps?.confirmLabel) : "Confirm"}
                  action={buttonProps?.action ? buttonProps?.action : null}
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const normalPopup = () => {
    return (
      <div
        className={`normal-pop-up form-pop-up bg-white rounded-sm md:rounded-big p-md md:p-lg relative flex flex-col max-w-[95%] ${classes}`}
        style={sx}
      >
        <form
          className="flex flex-col h-full"
          onSubmit={
            apiProps?.customSubmit
              ? (event) => {
                  event.preventDefault();
                  apiProps?.customSubmit(event);
                }
              : (event) => handleSubmitData(event)
          }
        >
          {(idArr.length || id) && (
            <input
              type="hidden"
              value={idArr.length ? idArr.join(",") : id}
              name={name}
            />
          )}
          {title && (
            <div className="sticky top-0 bg-white z-10 mb-xxx-big">
              <ProfileSection title={title} t={t} />
            </div>
          )}
          {children}
          <div className="sticky bottom-0 bg-white z-10">
            {bottomSticky}
            {popup_button(
              buttonProps?.backLabel || null,
              closePopup,
              buttonProps?.forwardLabel || null,
              loading || apiProps.loading,
              buttonProps?.disabled,
              buttonProps?.reupload,
              apiProps?.onClickInnerFormSubmit
            )}
          </div>
        </form>
      </div>
    );
  };

  const importPopup = () => {
    return (
      <div
        className={`${classes} import-pop-up form-pop-up bg-white rounded-sm md:rounded-big p-md md:p-xx-lg relative flex flex-col max-w-[95%]`}
        style={sx}
      >
        <div className="flex flex-col h-full">
          {(idArr.length || id) && (
            <input
              type="hidden"
              value={idArr.length ? idArr.join(",") : id}
              name={name}
            />
          )}
          {title && (
            <div className="sticky top-0 bg-white z-10 mb-xxx-big">
              <ProfileSection title={title} t={t} />
            </div>
          )}
          {children}
          <div className="sticky bottom-0 bg-white z-10">
            {import_button(
              buttonProps?.backLabel || null,
              closePopup,
              apiProps?.submit_action,
              "Confirm",
              apiProps?.loading,
              buttonProps?.disabled,
              buttonProps?.reupload
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="form-pop-up-container flex justify-center items-center w-screen h-screen fixed flex-col gap-md">
        {confirm_popup
          ? confirmPopup()
          : import_Popup
          ? importPopup()
          : normalPopup()}
        {/*<div*/}
        {/*  className="close-pop-up-btn bg-white z-40 rounded-full p-x-sm cursor-pointer hover:bg-surface-hover"*/}
        {/*  onClick={() => closePopup()}*/}
        {/*>*/}
        {/*  <CloseIcon className="text-body" />*/}
        {/*</div>*/}
      </div>
      <div className="pop-up-layout fixed w-full h-full"></div>
    </>
  );
};

export default Popup;
