import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeLayout from "./layout/HomeLayout";
import LayoutBeforeLogin from "./layout/LayoutBeforeLogin";
import LayoutAfterLogin from "./layout/LayoutAfterLogin";
import PropertyLayout from "./layout/PropertyLayout";
import LoginLayout from "./layout/LoginLayout";

import AdminRoute from "./context/AdminRoute";
import PrivateRoute from "./context/PrivateRoute";

import HomePage from "./pages/home/HomePage";
import QuickLinkPage from "./pages/home/QuickLinkPage";
import LoginPage from "./pages/login/LoginPage";
import RegistrationPage from "./pages/registration/RegistrationPage";
import ForgotPasswordPage from "./pages/forgot-password/ForgotPasswordPage";
import ForgotUsernamePage from "./pages/forgot-username/ForgotUsernamePage";
import ActivateAccountPage from "./pages/activate-account/ActivateAccountPage";
import SuccessfullyPage from "./pages/successfully/SuccessfullyPage";
import MyProfilePage from "./pages/my-profile/MyProfilePage";
import CorporateAccountPage from "./pages/user-management/CorporateAccountPage";
import CorporateAccountCreationPage from "./pages/user-management/CorporateAccountCreationPage";
import CorporateAccountDetailsPage from "./pages/user-management/CorporateAccountDetailsPage";
import AppointmentManagementPage from "./pages/agent-appointment/AppointmemtManagementPage";
import AppointmentCreationPage from "./pages/agent-appointment/AppointmentCreationPage";
import AppointmentDetailsPage from "./pages/agent-appointment/AppointmentDetailsPage";
import PropertyListPage from "./pages/property-list/PropertyListPage";
import AllPropertyPage from "./pages/property-list/AllPropertyPage";
import ViewPropertyPage from "./pages/property-list/ViewPropertyPage";
import PersonalisationPage from "./pages/personalisation/PersonalisationPage";
import AddPropertyPage from "./pages/property-list/AddPropertyPage";
import PropertyDetailsPage from "./pages/property-list/PropertyDetailsPage";
import MyDocumentPage from "./pages/my-documents/MyDocumentPage";
import ProposalSubmissionPage from "./pages/proposal-submission/ProposalSubmissionPage";
import TransactionHistoryPage from "./pages/transaction-history/TransactionHistoryPage";
import TransactionHistoryDetailsPage from "./pages/transaction-history/TransactionHistoryDetailsPage";
import SubmittedDocumentsPage from "./pages/submitted-documents/SubmittedDocumentsPage";
import SubmitDocumentPage from "./pages/submitted-documents/SubmitDocumentPage";
import NotificationCentrePage from "./pages/notification-centre/NotificationCentrePage";
import NotificationCentreDetailsPage from "./pages/notification-centre/NotificationCentreDetailsPage";
import HelpCentrePage from "./pages/help-centre/HelpCentrePage";
import GettingStartedPage from "./pages/help-centre/GettingStartedPage";
import FAQPage from "./pages/help-centre/FAQPage";
import SearchResult from "./pages/help-centre/SearchResult";
import ArticlePage from "./pages/help-centre/ArticlePage";
import ArticleDetailsPage from "./pages/help-centre/ArticleDetailsPage";

import NotFoundPage from "./pages/error/NotFoundPage";
import NoPermissionPage from "./pages/error/NoPermissionPage";
import ImportantNoticePage from "./pages/important-notice/ImportantNoticePage";
import SiteMapPage from "./pages/site-map/SiteMapPage";
import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicyPage";

import ErrorPage from "./pages/error/ErrorPage";
import IAmSmartLogin from "./iamsmart/login";
import CorporateRoute from "./context/CorporateRoute";

import ProposalSubmissionPageTwo from "./pages/proposal-submission-v2/ProposalSubmissionPage"

function App() {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Router basename="/portal">
      <Routes>
        <Route path="/" element={<LoginLayout />}>
          <Route
            path={`/:language/login`}
            element={<LoginPage lng={language} />}
          />
        </Route>

        <Route path="/" element={<HomeLayout />}>
          <Route
            path="/:language"
            element={<PrivateRoute element={<HomePage lng={language} />} />}
          />
          <Route
            index
            element={<PrivateRoute element={<HomePage lng={language} />} />}
          />
          <Route
            path="/:language/quick-links"
            element={
              <PrivateRoute element={<QuickLinkPage lng={language} />} />
            }
          />
        </Route>

        <Route path="/" element={<LayoutBeforeLogin />}>
          <Route
            path="/:language/registration"
            element={<RegistrationPage lng={language} />}
          />
          <Route
            path="/:language/forgot-password"
            element={<ForgotPasswordPage lng={language} />}
          />
          <Route
            path="/:language/forgot-username"
            element={<ForgotUsernamePage lng={language} />}
          />
          <Route
            path="/:language/activate-account"
            element={<ActivateAccountPage lng={language} />}
          />
          <Route
            path="/:language/successfully"
            element={<SuccessfullyPage lng={language} />}
          />
        </Route>

        <Route path="/" element={<LayoutAfterLogin />}>
          <Route
            path="/:language/403"
            element={
              <PrivateRoute element={<NoPermissionPage lng={language} />} />
            }
          />
          <Route
            path="/:language/404"
            element={<PrivateRoute element={<NotFoundPage lng={language} />} />}
          />

          <Route
            path="/:language/my-profile"
            element={
              <PrivateRoute element={<MyProfilePage lng={language} />} />
            }
          />
          <Route
            path="/:language/user-management"
            element={<AdminRoute />}
          >
            <Route
              index
              element={
                <PrivateRoute
                  element={<CorporateAccountPage lng={language} />}
                />
              }
            />

            <Route
              path="account-creation"
              element={
                <PrivateRoute
                  element={<CorporateAccountCreationPage lng={language} />}
                />
              }
            />
            <Route path="account-details">
              <Route
                index
                element={
                  <Navigate to={`/${language}/corporate-accounts-management`} />
                }
              />
              <Route
                path=":accountNo"
                element={
                  <PrivateRoute
                    element={<CorporateAccountDetailsPage lng={language} />}
                  />
                }
              />
            </Route>
          </Route>

          <Route path="/:language/property-list" element={<PropertyLayout />}>
            <Route
              index
              element={
                <PrivateRoute element={<PropertyListPage lng={language} />} />
              }
            />
            <Route
              path="all-properties"
              element={
                <PrivateRoute element={<AllPropertyPage lng={language} />} />
              }
            />
            <Route path="view-property">
              <Route
                index
                element={<Navigate to={`/${language}/property-list`} />}
              />
              <Route
                path=":id"
                element={
                  <PrivateRoute element={<ViewPropertyPage lng={language} />} />
                }
              />
              <Route path="add">
                <Route
                  index
                  element={<Navigate to={`/${language}/property-list`} />}
                />
                <Route
                  path=":id"
                  element={
                    <PrivateRoute
                      element={<AddPropertyPage lng={language} />}
                    />
                  }
                />
              </Route>
              <Route path="edit">
                <Route
                  index
                  element={<Navigate to={`/${language}/property-list`} />}
                />
                <Route
                  path=":pid"
                  element={
                    <PrivateRoute
                      element={<PropertyDetailsPage lng={language} />}
                    />
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route
            path="/:language/personalisation"
            element={
              <PrivateRoute element={<PersonalisationPage lng={language} />} />
            }
          />

          <Route path="/:language/agent-appointment" element={<CorporateRoute />} >
            <Route
              index
              element={
                <PrivateRoute
                  element={<AppointmentManagementPage lng={language} />}
                />
              }
            />
            <Route
              path="appointment-creation"
              element={
                <PrivateRoute
                  element={<AppointmentCreationPage lng={language} />}
                />
              }
            />
            <Route path="appointment-details">
              <Route
                index
                element={
                  <Navigate to={`/${language}/agent-appointment`} />
                }
              />
              <Route
                path=":id"
                element={
                  <PrivateRoute
                    element={<AppointmentDetailsPage lng={language} />}
                  />
                }
              />
            </Route>
          </Route>

          <Route path="/:language/help-centre">
            <Route
              index
              element={
                <PrivateRoute element={<HelpCentrePage lng={language} />} />
              }
            />
            <Route
              path="getting-started"
              element={
                <PrivateRoute element={<GettingStartedPage lng={language} />} />
              }
            />
            <Route
              path="faqs"
              element={<PrivateRoute element={<FAQPage lng={language} />} />}
            />
            <Route
              path="search-result"
              element={
                <PrivateRoute element={<SearchResult lng={language} />} />
              }
            />
            <Route path="articles">
              <Route
                index
                element={
                  <PrivateRoute element={<ArticlePage lng={language} />} />
                }
              />
              <Route path="details">
                <Route
                  index
                  element={<Navigate to={`/${language}/help-centre`} />}
                />
                <Route
                  path=":id"
                  element={
                    <PrivateRoute
                      element={<ArticleDetailsPage lng={language} />}
                    />
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route path="/:language/proposal-submission">
            <Route
              index
              element={
                <PrivateRoute
                  element={<ProposalSubmissionPage lng={language} />}
                />
              }
            />
          </Route>

          <Route path="/:language/proposal-submission-v2">
            <Route
              index
              element={
                <PrivateRoute
                  element={<ProposalSubmissionPageTwo lng={language} />}
                />
              }
            />
          </Route>

          <Route path="/:language/notification-centre">
            <Route
              index
              element={
                <PrivateRoute
                  element={<NotificationCentrePage lng={language} />}
                />
              }
            />
            <Route path="details">
              <Route
                index
                element={<Navigate to={`/${language}/notification-centre`} />}
              />
              <Route
                path=":id"
                element={
                  <PrivateRoute
                    element={<NotificationCentreDetailsPage lng={language} />}
                  />
                }
              />
            </Route>
          </Route>

          <Route path="/:language/transaction-history">
            <Route
              index
              element={
                <PrivateRoute
                  element={<TransactionHistoryPage lng={language} />}
                />
              }
            />
            <Route path="details">
              <Route
                index
                element={<Navigate to={`/${language}/transaction-history`} />}
              />
              <Route
                path=":id"
                element={
                  <PrivateRoute
                    element={<TransactionHistoryDetailsPage lng={language} />}
                  />
                }
              />
            </Route>
          </Route>

          <Route path="/:language/my-documents">
            <Route
              index
              element={
                <PrivateRoute element={<MyDocumentPage lng={language} />} />
              }
            />
          </Route>

          <Route path="/:language/submitted-documents">
            <Route
              index
              element={
                <PrivateRoute
                  element={<SubmittedDocumentsPage lng={language} />}
                />
              }
            />
            <Route
              path="submit-document"
              element={
                <PrivateRoute element={<SubmitDocumentPage lng={language} />} />
              }
            />
          </Route>
            <Route
                path="/:language/important-notice"
                element={<ImportantNoticePage lng={language} />}
            />
            <Route
                path="/:language/site-map"
                element={<SiteMapPage lng={language} />}
            />
            <Route
                path="/:language/privacy-policy"
                element={<PrivacyPolicyPage lng={language} />}
            />
        </Route>
        <Route path="/:language/error" element={<ErrorPage lng={language} />} />
        <Route
          path="*"
          element={<Navigate to={`/${language}`} replace={true} />}
        />
        <Route path={"iamsmart/testing"} element={<IAmSmartLogin />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
