export const tableStyles = {
  muiTableHeadRowProps: {
    sx: {
      background: "var(--surface-secondary)",
      paddingRight: "var(--spacing-big)",
      alignItems: "center",
      minHeight: "58px",
      marginBottom: (props) => (props.needMargin ? "var(--spacing-big)" : "0"),
      "& .MuiBadge-root": {
        width: "fit-content",
        height: "100%",
        display: "contents",
      },
      "& .MuiBadge-overlapCircular": {
        display: "none",
      },
      "& .MuiTableSortLabel-icon": {
        width: "12px",
        height: "12px",
      },
    },
  },
  muiTableHeadCellProps: {
    sx: {
      border: "0",
      padding: "var(--spacing-big) 0 var(--spacing-big) var(--spacing-big)",
    },
  },
  muiTableBodyRowProps: {
    sx: {
      "&.Mui-selected .MuiTableCell-root:after": {
        background: "var(--surface-card)",
      },
      minHeight: "80px",
      background: "var(--surface-card)",
      borderTop: "1px solid var(--border-light)",
      borderBottom: "0",
    },
  },
  muiTableContainerProps: {
    className: `custom-scrollbar-table`,
    sx: {
      borderRadius: "var(--radius-md)",
      border: (props) =>
        props.border ? "1px solid var(--border-light)" : null,
    },
  },
  muiTableBodyCellProps: {
    className: "body2",
    sx: {
      borderBottom: "0",
      padding: "var(--spacing-big) 0 var(--spacing-big) var(--spacing-big)",
    },
  },
  muiTablePaperProps: {
    elevation: 0,
    sx: {
      background: "transparent",
      border: "0",
    },
  },
  muiBottomToolbarProps: {
    sx: {
      display: (props) => (props.enablePagination ? "flex" : "none"),
      background: "transparent",
      border: "0",
      marginTop: "var(--spacing-xx-big)",
    },
  },
  muiTableBodyProps: {
    sx: {
      "& .MuiTableCell-root": {
        color: "var(--text-secondary)",
      },
      minHeight: "auto",
      background: "var(--surface-card)",
    },
  },
  muiSelectCheckboxProps: {
    sx: {
      color: "var(--border-default)",
      "&.Mui-checked": {
        color: "var(--surface-primary)",
      },
      "&.Mui-disabled": {
        display: "none",
      },
      padding: "0",
    },
  },
  muiSelectAllCheckboxProps: {
    sx: {
      color: "var(--border-default)",
      "&.Mui-checked, &.MuiCheckbox-indeterminate": {
        color: "var(--surface-primary)",
      },
      padding: "0",
      paddingBottom: "0.6rem",
    },
  },
};
