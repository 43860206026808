import FieldCard from "../../components/form/FieldCard";
import VerifyField from "../../components/input/VerifyField";

const StepTwo = ({ props, data, setError, api_name, slug, t }) => {
    const is_activate = slug && slug === 'activate';
    const is_password = slug && slug === 'password';
    const is_username = slug && slug === 'username';

    const isAccountVerified = is_activate || is_password ? data?.['verification_code'] : is_username ? data?.['username'] : false;
    const additionalData = is_username ? {'number': data?.['number']} : is_password ? data : false;
    const action = is_password ? 'reset' : is_username ? 'view' : 'activate';
    const subtitle =
        isAccountVerified ? is_activate ? 'Email has been verified, please click "Continue."' : `Request has been verified, please click "Continue" to ${action} your ${is_activate ? 'account' : slug}.` :

            `The verification code is sent to your email, please enter the code to continue.`;
    return (
        <div className="gap-md flex flex-col">
            <FieldCard title="Enter Verification Code" subtitle={subtitle} t={t}>
                <VerifyField
                    {...props("verification_code", "Verification Code")}
                    show={true}
                    parentData={data?.["email"] ? {
                        'email': data?.["email"]
                    } : {
                        'mobile': data?.["mobile"] || '',
                        'country_code': data?.["country_code"] || ''
                    }}
                    uuid={data?.["uuid"]}
                    start_counting={data?.["uuid"] !== undefined}
                    additionalData={additionalData}
                    setError={setError }
                    api_name={api_name}
                    slug={slug}
                    t={t}
                />
            </FieldCard>
        </div>
    )
}

export default StepTwo;