import { OutlineButton, PrimaryButton } from "./SingleButton";
const PopupButton = ({ t }) => {
  const popup_button = (
    backLabel = "Cancel",
    back_action,
    forwardLabel = "Save",
    loading,
    disabled,
    reupload,
    onClickInnerFormSubmit
  ) => {
    return (
      <div className="flex justify-between items-center mt-xxx-big">
        <div className="w-fit">
            <OutlineButton
              t={t}
              label={backLabel ? backLabel : "Cancel"}
              action={reupload ? (event) => reupload(event) : back_action}
            />
        </div>
        <div className="w-fit">
            <PrimaryButton
                t={t}
                label={loading ? "Loading" : forwardLabel ? forwardLabel : "Save"}
                loading={loading}
                disabled={disabled}
                type={onClickInnerFormSubmit ? "button" : "submit"}
                action={onClickInnerFormSubmit}
            />
        </div>
      </div>
    );
  };
  const import_button = (
    backLabel = "Cancel",
    back_action,
    submit_action,
    forwardLabel = "Confirm",
    loading,
    disabled
  ) => {
    return (
      <div className="flex justify-between items-center mt-xxx-big">
        <div className="w-fit">
            <OutlineButton
                t={t}
                label={backLabel ? backLabel : "Cancel"}
                action={back_action}
            />
        </div>
        <div className="w-fit">
            <PrimaryButton
                t={t}
                label={loading ? "Loading" : forwardLabel ? forwardLabel : "Save"}
                loading={loading}
                disabled={disabled}
                action={submit_action}
            />
        </div>
      </div>
    );
  };

  return {
    popup_button,
    import_button,
  };
};

export default PopupButton;
