import React, { useEffect, useState } from "react";
import FieldCard from "../../components/form/FieldCard";
import Switcher from "../../components/button/Switcher";
import Radio from "../../components/input/Radio";
import Input from "../../components/input/Input";
import VerifyField from "../../components/input/VerifyField";
import VerifyDisableChangeFunction from "../../components/function/VerifyDisableChangeFunction";
import AreaAndDistrict from "../../components/input/AreaAndDistrict";
import smsOption from "../../data/sms_option.json";
import USER_TYPE from "../../data/variable/user_type.json";
import particulars_options from "../../data/particulars_options.json";
import stepFiveFields from "../../data/step_five_fieds.json";
import Select from "../../components/input/Select";
import VerifiedButton from "../../components/button/VerifiedButton";
import { IAmSmartButton } from "../button/SingleButton";
import ErrorMessage from "../../components/error/ErrorMessage";
import Checkbox from "../../components/input/Checkbox";

const StepThree = ({
  t,
  setActiveIndex,
  active,
  updateFields,
  data,
  disabled = false,
  step,
  error,
  setError,
  clearAlldata,
  lng,
  api_name,
  setProofIndex,
  proofIndex,
}) => {
  const {
    handleEmailVerifyActive,
    handleMobileVerifyActive,
    handleAltEmailVerifyActive,
    emailVerifyActive,
    mobileVerifyActive,
    altEmailVerifyActive,
  } = VerifyDisableChangeFunction();
  const [showSMS, setShowSMS] = useState(false);

  const notFinal = step !== "final";
  const isFinal = step === "final";
  const identity_field = [
    "identity_number",
    "passport_issue_place",
    "passport_issuance_authority",
    "other_issue_place",
    "confirmation_letter_file",
    "identity_proof_file",
  ];

  useEffect(() => {
    if (Number(data["is_receive_sms"]) === 1) {
      setShowSMS(true);
    } else {
      setShowSMS(false);
    }
  }, [data]);

  const inputProps = (
    name,
    label,
    required = false,
    optional = false,
    action = null,
    type = "text"
  ) => ({
    name,
    inputLabel: label,
    updateFields: notFinal ? updateFields : null,
    data: data ? data[name] : "",
    disabled: isFinal ? true : disabled,
    error: error ? error[name] : "",
    required,
    optional,
    action,
    type,
    setError,
    lng,
    t,
  });

  const accountType = [
    // { value: 1, label: "Individual" },
    { value: 2, label: "Company/Organisation" },
  ];

  const renderAccountTypeSwitcher = () => (
    <FieldCard title="Account Type" t={t}>
      <Switcher
        name="account_type"
        options={accountType}
        activeIndex={active}
        setActiveIndex={setActiveIndex}
        updateFields={updateFields}
        data={data["account_type"]}
        setShowSMS={setShowSMS}
        setError={error && setError}
        error={error && error["account_type"]}
        clearAlldata={clearAlldata}
        t={t}
      />
    </FieldCard>
  );

  const renderCorporateInfo = () =>
    active === 2 && (
      <FieldCard title="Corporate Details" t={t}>
        <Input
          {...inputProps("corporate_brn", "Business Registration Certificate")}
        />
        <Input
          {...inputProps(
            "corporate_name_en",
            "English Name of Corporate/Organisation"
          )}
        />
        {((isFinal && data["corporate_name_zh"]) || notFinal) && (
          <Input
            {...inputProps(
              "corporate_name_zh",
              "Chinese Name of Corporate/Organisation"
            )}
            optional={true}
          />
        )}
      </FieldCard>
    );

  const renderPersonalInfo = () => (
    <FieldCard title="Your information" t={t}>
      {notFinal ? (
        <Radio
          name={active === 1 ? "title" : "corporate_contact_title"}
          disabled={disabled}
          options={particulars_options}
          updateFields={updateFields}
          data={
            data &&
            (active === 1 ? data["title"] : data["corporate_contact_title"])
          }
          error={
            error &&
            (active === 1 ? error["title"] : error["corporate_contact_title"])
          }
          setError={setError}
          t={t}
        />
      ) : (
        (data["title"] || data["corporate_contact_title"]) && (
          <Radio
            name={active === 1 ? "title" : "corporate_contact_title"}
            options={[
              {
                value: Number(data["title"] || data["corporate_contact_title"]),
                label: particulars_options.find(
                  (option) =>
                    option.value ===
                    Number(data["title"] || data["corporate_contact_title"])
                )?.label,
              },
            ]}
            disabled={true}
            data={
              data &&
              (active === 1 ? data["title"] : data["corporate_contact_title"])
            }
            error={
              error &&
              (active === 1 ? error["title"] : error["corporate_contact_title"])
            }
            setError={setError}
            t={t}
          />
        )
      )}

      <div className="gap-xxx-big flex-col md:flex-row flex md:gap-x-sm">
        {notFinal ||
        (isFinal &&
          data?.[
            `${active === 1 ? "name_en" : "corporate_contact_name_en"}`
          ]) ? (
          <Input
            maxLength="100"
            {...inputProps(
              active === 1 ? "name_en" : "corporate_contact_name_en",
              "English Name"
            )}
            help_text={active === 1 ? "Please fill in the name according to the identity document." : ''}
          />
        ) : null}
        {notFinal ||
        (isFinal &&
          data[`${active === 1 ? "name_zh" : "corporate_contact_name_zh"}`]) ? (
          <Input
            maxLength="28"
            {...inputProps(
              active === 1 ? "name_zh" : "corporate_contact_name_zh",
              "Chinese Name",
              false,
              1
            )}
            help_text={active === 1 ? "Please fill in the name according to the identity document." : ''}
          />
        ) : null}
      </div>
      {active === 2 &&
        (notFinal || (isFinal && data["corporate_contact_post_title"])) && (
          <Input
            {...inputProps(
              "corporate_contact_post_title",
              "Capacity/Post title"
            )}
          />
        )}
    </FieldCard>
  );

  const renderContactInfo = () => (
    <FieldCard title="Contact Information" t={t}>
      <div className="flex gap-x-big flex-col mb-xxx-big">
        <div className="flex gap-sm flex-col md:flex-row">
          <Input
            {...inputProps(
              "email",
              "Email Address",
              false,
              false,
              handleEmailVerifyActive
            )}
            readonly={isFinal || data["email_verification_code"] !== ""}
          />
          {notFinal && data["email_verification_code"] && (
            <VerifiedButton data={data["email_verification_code"]} />
          )}
          {/*--------------start for step 6--------------*/}
          {isFinal && data["email"] && (
            <VerifiedButton data={data["email_verification_code"]} />
          )}
          <input
            type="hidden"
            name="uuid_email"
            value={data["uuid_email"] || ""}
          />
        </div>

        {isFinal && data["fax"] && (
          <Input {...inputProps("fax", "Fax No.", false, false, null, "tel")} />
        )}
        {isFinal && data["email_alternative"] && (
          <div className="flex gap-sm flex-col md:flex-row">
            <Input
              {...inputProps(
                "email_alternative",
                "Alternative E-mail Address",
                false,
                false,
                null
              )}
            />
            <VerifiedButton data={data["alternative_verification_code"]} />
          </div>
        )}
        {/*--------------end for step 6--------------*/}

        {notFinal ? (
          <VerifyField
            {...inputProps(
              "email_verification_code",
              "Email Verification Code"
            )}
            parentData={{ email: data?.["email"] || "", email_alternative: data?.["email_alternative"] || "" }}
            uuid={data["uuid_email"] || ""}
            show={emailVerifyActive}
            codeError={(error && error["uuid_email"]) || ""}
            api_name={api_name}
            t={t}
          />
        ) : null}
      </div>

      <div className="flex flex-col gap-xxx-big">
        {(isFinal && data['mobile']) || !isFinal ? (
            <div className="flex gap-sm flex-col md:flex-row">
              <div className="min-w-xx-huge lg:min-w-200px">
                <Select
                    {...inputProps("country_code", "Country/Area Code")}
                    readonly={isFinal || data["mobile_verification_code"] !== ""}
                />
              </div>
              <Input
                  {...inputProps(
                      "mobile",
                      "Contact No.",
                      false,
                      false,
                      handleMobileVerifyActive,
                      "tel"
                  )}
                  readonly={isFinal || data["mobile_verification_code"] !== ""}
              />
            <input
                type="hidden"
                name="uuid_mobile"
                value={data["uuid_mobile"] || ""}
            />
            {notFinal && data["mobile_verification_code"] && (
                <VerifiedButton data={data["mobile_verification_code"]}/>
            )}

            {/*--------------start for step 6--------------*/}
            {isFinal && data["mobile"] && (
                <VerifiedButton data={data["mobile_verification_code"]}/>
            )}
            {/*--------------end for step 6--------------*/}
          </div>
          ) : null}

        <div className="label1 field-card-title mt-x-sm">
          {t("SMS Notification Services")}
          {notFinal && (
            <p className="body2 mt-sm">
              {t(
                "Please tick below if you would like to receive SMS notifications and verify your mobile no."
              )}
            </p>
          )}
        </div>
        {notFinal ? (
          <div className="flex gap-x-big flex-col pl-sm">
            <Checkbox
              name="is_receive_sms"
              labelClass="label1"
              itemStart={true}
              inputLabel="I would like to receive SMS notification."
              updateFields={updateFields}
              data={data["is_receive_sms"] || ""}
              disabled={disabled}
              error={(error && error["is_receive_sms"]) || ""}
              setError={setError}
              t={t}
            />
          </div>
        ) : (
          data["is_receive_sms"] && (
            <div className="flex gap-x-big flex-col">
              <Checkbox
                name="is_receive_sms"
                labelClass="label1"
                itemStart={true}
                check={data["is_receive_sms"] === "1"}
                inputLabel="I would like to receive SMS notification."
                data={data["is_receive_sms"] || ""}
                error={(error && error["is_receive_sms"]) || ""}
                disabled={true}
                setError={setError}
                t={t}
              />
            </div>
          )
        )}

        {showSMS && notFinal ? (
          <>
            <VerifyField
              {...inputProps(
                "mobile_verification_code",
                "Mobile Verification Code"
              )}
              parentData={{
                mobile: data["mobile"] || "",
                country_code: data["country_code"] || "",
              }}
              uuid={data["uuid_mobile"] || ""}
              show={mobileVerifyActive}
              codeError={(error && error["uuid_mobile"]) || ""}
              api_name={api_name}
              t={t}
            />
          </>
        ) : null}
      </div>

      {notFinal && (
        <div className="mt-x-sm pt-xxx-big flex gap-sm flex-col md:flex-row">
          <Input
            {...inputProps(
              "email_alternative",
              "Alternative E-mail Address",
              false,
              true,
              handleAltEmailVerifyActive
            )}
            readonly={isFinal || data["alternative_verification_code"] !== ""}
          />
          {notFinal && data["alternative_verification_code"] && (
            <VerifiedButton data={data["alternative_verification_code"]} />
          )}
          <input
            type="hidden"
            name="uuid_email_alternative"
            value={data["uuid_email_alternative"] || ""}
          />
        </div>
      )}

      {notFinal ? (
        <>
          <VerifyField
            {...inputProps(
              "alternative_verification_code",
              "Alternative E-mail Verification Code"
            )}
            parentData={{ email_alternative: data?.["email_alternative"] || "", email: data?.["email"] || "" }}
            uuid={data["uuid_email_alternative"] || ""}
            show={altEmailVerifyActive}
            codeError={(error && error["uuid_email_alternative"]) || ""}
            api_name={api_name}
            t={t}
          />
        </>
      ) : null}
      {notFinal && (
        <Input {...inputProps("fax", "Fax No.", false, true, null, "tel")} />
      )}
    </FieldCard>
  );
  const renderIdentityVerification = () => (
    <FieldCard title="Identification" t={t}>
      {notFinal && (
        <Switcher
          options={[
            { value: 1, label: "HKIC" },
            { value: 2, label: "Passport" },
            { value: 3, label: "Other Travel Document" },
          ]}
          name="identity_type"
          errorOption={identity_field}
          activeIndex={proofIndex}
          setActiveIndex={setProofIndex}
          updateFields={updateFields}
          data={data["identity_type"]}
          disabled={disabled}
          setError={error && setError}
          t={t}
          clearAlldata={() => {
            const fieldsToClean = [
              ...identity_field,
            ];
            clearAlldata(fieldsToClean);
          }}
        />
      )}


      {isFinal && (
        <Switcher
          options={[
            data["identity_type"] === 1
              ? { value: 1, label: "HKIC" }
              : data["identity_type"] === 2
              ? { value: 2, label: "Passport" }
              : { value: 3, label: "Other Travel Document" },
          ]}
          name="identity_type"
          activeIndex={proofIndex}
          disabled={disabled}
          data={data["identity_type"]}
          t={t}
        />
      )}
      {(proofIndex === 1 || (isFinal && data?.identity_type === 1)) && (
        <Input
          {...inputProps("identity_number", "Hong Kong Identity Card No.")}
        />
      )}

      {(proofIndex === 2 || (isFinal && data?.identity_type === 2)) && (
        <>
          <Input {...inputProps("identity_number", "Passport No.")} />
          <Input
            {...inputProps(
              "passport_issue_place",
              "Place of Issue for Passport"
            )}
          />
          <Input
            {...inputProps(
              "passport_issuance_authority",
              "Issuance authority of passport"
            )}
          />
        </>
      )}

      {(proofIndex === 3 ||  (isFinal && data?.identity_type === 3)) && (
        <>
          <Input
            {...inputProps("identity_number", "Other Travel Document No.")}
          />
          <Input
            {...inputProps(
              "other_issue_place",
              "Place of issue for Other Travel Document"
            )}
          />
        </>
      )}
    </FieldCard>
  );

  const renderCorrespondenceAddress = () => (
    <FieldCard title="Correspondence Address" t={t}>
      <div className="flex gap-x-big flex-col mb-x-big">
        <AreaAndDistrict
          isFinal={isFinal}
          updateFields={updateFields}
          areaData={data["address_area"] || ""}
          districtData={data["address_district"] || ""}
          subDistrictData={data["address_sub_district"] || ""}
          disabled={disabled}
          AreaError={error && error["address_area"]}
          districtError={error && error["address_district"]}
          subDistrictError={error && error["address_sub_district"]}
          setError={setError}
          lng={lng}
          t={t}
        />
        <Input
          {...inputProps("address_street", "No. and Name of Street or Village")}
        />
        {(isFinal && data["address_building"]) || !isFinal ? <Input {...inputProps("address_building", "Name of Building/Estate", 0, 1)} /> : null}
        {(isFinal && data["address_block"]) || !isFinal ? <Input {...inputProps("address_block", "Block/Tower/House/Others", 0, 1)}/> : null}
        {(isFinal && (data["address_room"] || data["address_room"])) || !isFinal ?<div className="flex-col gap-x-big md:flex-row flex md:gap-sm">
          {(isFinal && data["address_floor"]) || !isFinal ?
              <Input {...inputProps("address_floor", "Floor", 0, 1)} /> : null}
          {(isFinal && data["address_room"]) || !isFinal ? <Input
              {...inputProps(
                  "address_room",
                  "Flat/Shop/Unit/Suite/Room/Others",
                  0,
                  1
              )}
          /> : null}
        </div> : null}
      </div>
    </FieldCard>
  );

  const renderBillingAccountDetails = () => (
    <FieldCard
      title="Billing Account Details"
      t={t}
      tooltip="The registered payer’s name must be the same as the name shown on the first line of the Registered Payer’s Name on the latest quarterly demand note. Payer name of “The Owner/Occupier” is not acceptable for registration of Web Portal account."
    >
      <div className="flex gap-x-big flex-col mb-x-big">
        <div>
          <Input
            {...inputProps("billing_account_no", "15-digit Account No.")}
            help_text="Accept format: XXX-XXXXX-XXXX-X-XX or XXXXXXXXXXXXXXX"
          />
          {error && <ErrorMessage error={error["billing_account"]} t={t} />}
        </div>
        <div className="flex-col md:flex-row flex gap-x-big md:gap-sm">
          <Input
            {...inputProps("billing_payer_name", "First line of the Registered Payer's Name")}
            help_text="Must be the same as the name shown on the 1st line of the Registered Payer's Name on the latest demand note"
          />
        </div>
      </div>
    </FieldCard>
  );

  return (
    <>
      <div className="gap-md flex flex-col">
        {notFinal && <>{renderAccountTypeSwitcher()}</>}

        {isFinal && (
          <FieldCard title="Account Type" t={t}>
            <Switcher
              name="account_type"
              options={[
                data["account_type"] === USER_TYPE.INDIVIDUAL
                  ? { value: USER_TYPE.INDIVIDUAL, label: "Individual" }
                  : { value: 2, label: "Company/Organisation" },
              ]}
              activeIndex={active}
              error={error && error["account_type"]}
              data={data["account_type"] || ""}
              disabled={true}
              t={t}
            />
          </FieldCard>
        )}

        {active === 1 && notFinal && (
          <FieldCard>
            <div className="body1">
              {t(
                'You may click the "Continue with iAM Smart" button to fill the personal information automatically and conduct authentication.'
              )}
            </div>
            <IAmSmartButton t={t} href="" label="Continue with iAM Smart" />
          </FieldCard>
        )}

        {/*need update*/}
        {renderCorporateInfo()}
        {renderPersonalInfo()}
        {/*need update*/}
        {active === 1 && renderIdentityVerification()}
        {renderContactInfo()}
        {renderCorrespondenceAddress()}
        {renderBillingAccountDetails()}
      </div>
    </>
  );
};

export default StepThree;
