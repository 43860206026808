import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const ModelMenu = ({ buttons, buttonsChild, selected, options, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl({ el: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {buttonsChild ? (
        <button
          className={`flex items-center ${
            disabled ? "pointer-events-none" : "pointer-events-auto"
          }`}
          aria-haspopup="true"
          onClick={(event) => handleClick(event)}
        >
          {buttonsChild}
        </button>
      ) : (
        <div
          className={`flex items-center ${
            disabled ? "pointer-events-none" : "pointer-events-auto"
          }`}
          aria-haspopup="true"
          onClick={(event) => handleClick(event)}
        >
          {buttons}
        </div>
      )}
      <Menu
        anchorEl={anchorEl?.el}
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiMenu-paper": {
            boxShadow: "0px 15px 40px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "var(--radius-sm)",
            color: "var(--text-body)",
            textAlign: "start",
            padding: "var(--spacing-x-sm)",
            maxWidth: "356px",
            width: "fit-content",
          },
        }}
      >
        {options &&
          options.map((option, index) => {
            if (
              option.link &&
              ((option.show !== undefined && option.show) ||
                option.show === undefined)
            ) {
              return (
                <MenuItem
                  key={index}
                  onClick={handleClose}
                  sx={{
                    color: "var(--Neutral-800)",
                    padding: "0",
                    borderRadius: "var(--radius-sm)",
                    "&:hover": {
                      backgroundColor: "var(--surface-secondary)",
                    },
                  }}
                >
                  <Link
                    to={option.link}
                    className="w-full py-sm px-md label1 text-left"
                    disabled={option.disabled}
                  >
                    {t(option.label)}
                  </Link>
                </MenuItem>
              );
            }
            if (
              option.action &&
              ((option.show !== undefined && option.show) ||
                option.show === undefined)
            ) {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{
                    color: "var(--Neutral-800)",
                    padding: "0",
                    borderRadius: "var(--radius-sm)",
                    "&:hover": {
                      backgroundColor: "var(--surface-secondary)",
                    },
                  }}
                >
                  <button
                    className="w-full py-sm px-md label1 text-left"
                    disabled={option.disabled}
                    onClick={(e) => option.action(option.value)}
                  >
                    {t(option.label)}
                  </button>
                </MenuItem>
              );
            }
            return null;
          })}
      </Menu>
    </>
  );
};

export default ModelMenu;
