import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const TableModelMenu = ({ buttons, options, row, getDatas, is_parent }) => {
  const {t} = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, rowId) => {
    setAnchorEl({ el: event.currentTarget, rowId });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        className="flex items-center"
        title={t("Menu")}
        aria-haspopup="true"
        aria-expanded={open && anchorEl?.rowId === row.id ? "true" : undefined}
        onClick={(event) => handleClick(event, row.id)}
      >
        {buttons}
      </button>
      <Menu
        anchorEl={anchorEl?.el}
        open={open && anchorEl?.rowId === row.id}
        onClose={handleClose}
        sx={{
          ".MuiMenu-paper": {
            boxShadow: "0px 15px 40px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "var(--radius-sm)",
            color: "var(--text-body)",
            textAlign: "start",
            padding: "var(--spacing-x-sm)",
            width: "280px",
          },
        }}
      >
        {options &&
          options.map((option, index) => {
            if (
              option.link &&
              option.permission.includes(Number(row.original.permission))
            ) {
              return (
                <MenuItem
                  key={`${row.id}-${index}`}
                  onClick={handleClose}
                  sx={{
                    color: "var(--Neutral-800)",
                    padding: "0",
                    borderRadius: "var(--radius-sm)",
                    "&:hover": {
                      backgroundColor: "var(--surface-secondary)",
                    },
                  }}
                >
                  <Link
                    to={option.link + row.original.id}
                    className="w-full py-sm px-md label1 text-left"
                  >
                    {t(option.label)}
                  </Link>
                </MenuItem>
              );
            }
            if (
              option.action &&
              option.permission.includes(Number(row.original.permission))
            ) {
              switch (option.label) {
                case "Delete":
                  if (is_parent ||
                      row.original.id === "0" ||
                      row.original.rowType === "shared_child" ||
                      row.original.rowType === "shared_parent" ) {
                    return null;
                  }
                  break;
                case "Alias":
                  if (
                    (row.original.rowType !== "shared_child" &&
                    row.original.rowType !== "shared_parent")
                  ) {
                    return null;
                  }
                  break;
                case "Rename":
                  if (
                    row.original.id === "0" ||
                    row.original.rowType === "shared_child" ||
                    row.original.rowType === "parent" ||
                    row.original.rowType === "shared_parent"
                  ) {
                    return null;
                  }
                  break;
                case "Add Sub-category":
                  if (
                    row.original.id === "0" ||
                    row.original.rowType === "shared_child" ||
                    row.original.rowType === "shared_parent"
                  ) {
                    return null;
                  }
                  break;
              }

              return (
                <MenuItem
                  key={`${row.id}-${index}`}
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{
                    color: "var(--Neutral-800)",
                    padding: "0",
                    borderRadius: "var(--radius-sm)",
                    "&:hover": {
                      backgroundColor: "var(--surface-secondary)",
                    },
                  }}
                >
                  <button
                    className="w-full py-sm px-md label1 text-left"
                    data-id={row.original.id}
                    data-get={getDatas}
                    onClick={(e) => option.action(e)}
                  >
                    {t(option.label)}
                  </button>
                </MenuItem>
              );
            }
            return null;
          })}
      </Menu>
    </>
  );
};

export default TableModelMenu;
