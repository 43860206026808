import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BlueForwardLink } from "../../components/button/SingleButton";
import { ReactComponent as CelebrationSVG } from "../../assets/images/others/celebration.svg";
const SuccessfullyPage = ({ lng }) => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const type = searchParams.get('type')

    let title = '';
    let content = '';
    let buttonLable = '';
    let path = '';

    switch (type) {
        case 'registration':
            title='Submitted Successfully';
            content='Thank you for your registration. Your application has been received and is under review. We will notify you by e-mail once it is completed.';
            buttonLable='Login Now';
            path=`/${lng}/login`;
            break;
        case 'iam-smart':
            title='Submitted Successfully';
            content='Congratulations! Your account has been successfully created. You can now login to the Web Portal and have access to the features and services our platform offered.';
            buttonLable='Login Now';
            path=`/${lng}/`;
            break;
        case 'forgot':
            title='Password Reset Successfully';
            content='Congratulations! Your password has been successfully reset. Now you can login to the Web Portal.';
            buttonLable='Login Now';
            path=`/${lng}/login`;
            break;
        case 'activate_account':
            title='Activate Account Successfully';
            content='Congratulations! Your account has been successfully activated. Now you can login to the Web Portal.';
            buttonLable='Login Now';
            path=`/${lng}/login`;
            break;
    }

    return (
        <div className="bg-50">
            <div className="registration-container py-xxx-lg layout-container flex flex-col justify-center items-center text-center" style={{minHeight: "calc(100vh - 90px - 96px)"}}>
                <div className="max-w-900px  px-sm py-lg md:p-xx-lg lg:py-xx-lg lg:px-huge bg-white rounded-big flex flex-col justify-center items-center mb-lg">
                    <div className="bg-label-icon-positive w-xxx-lg h-xxx-lg rounded-full flex justify-center items-center mb-sm">
                        <div className="w-lg h-lg">
                            <CelebrationSVG />
                        </div>
                    </div>
                    <h3 className="text-label-icon-positive h3 mb-x-big">
                        {t(title)}
                    </h3>
                    <p className="body1 text-body">{t(content)}</p>
                </div>
                <BlueForwardLink t={t} href={path} label={buttonLable} />
            </div>
        </div>

    )
}

export default SuccessfullyPage;