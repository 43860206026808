import PropertyTemplate from "../../components/property/Template";
import { useTranslation } from "react-i18next";
import WhiteSelect from "../../components/input/portal/WhiteSelect";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import {useUserLoginContext} from "../../context/UserLoginProvider";
import AssessmentAndTRNSearch from "../../components/search/AssessmentAndTRNSearch";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "../../components/table/DataTable";
import { TableDownloadButton, PrimaryButton } from "../../components/button/SingleButton";
import JSZip from 'jszip';
import LoadingUI from "../../components/loading/LoadingUI";
import Breadcrumb from "../../components/header/Breadcrumb";
import useDocumentTitle from "../../hooks/useDocument";
import smallFunction from "../../components/function/SmallFunction";

const MyDocumentPage = ({ lng }) => {
    const [searchLoading, setSearchLoadingLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(0)
    const [rows, setRows]  = useState(false);
    const [searchBy, setSearchBy] = useState(1);
    const [error, setError] = useState({});
    const [isFetching, setIsFetching] = useState(true);
    const [resetPagination, setResetPagination] = useState(false);
    const [data,setData] = useState({});
    const [searchInputData,setSearchInputData] = useState({});
    const [filterData,setFilterData] = useState({
        type:99
    });
    const [tableLoading, setTableLoading] = useState(true);
    const { t } = useTranslation();
    const { userLoading } = useUserLoginContext();
    const { postForm, loading, previewFile } = SubmitAndFetch({lng, t});
    const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
    const [rowSelection, setRowSelection] = useState([]);
    const { changeDate } = smallFunction();
    const selectedLimited = 50;
    useDocumentTitle("My Documents");

    const updateFields = (fields) => setSearchInputData(prev => ({ ...prev, ...fields }));
    const updateFilterFields = (fields) => setFilterData(prev => ({ ...prev, ...fields }));
    const handleSubmit = (event) => {
        event.preventDefault();
        setResetPagination(true)
        updateFilterFields({assessment_no: searchInputData?.assessment_no ?? '', trn: searchInputData?.trn ?? '' });
        setIsFetching(true);
    }

    useEffect(() => {
        // setIsFetching(true);
    }, [lng]);

    useEffect(() => {
        setPageIndex(0)
        updateFilterFields({...searchInputData});
    }, [searchBy])

    const handleSearchDocumentType = (value) => {
        if (value !== filterData.type) {
            setResetPagination(true)
            updateFilterFields({'type': value})
            setIsFetching(true)
        }
    }

    const cancelAction = () => {
        setSearchBy(1);
        setResetPagination(true)
        setSearchInputData({});
        setFilterData({type: 99});
        updateFilterFields({'type': 99})
        setIsFetching(true)
    }

    const documentType = [
        {
            value: 99,
            label: 'All Documents',
        },
        {
            value: 1,
            label: 'Acknowledgement of Receipt',
        },
        {
            value: 2,
            label: 'Letter',
        },
        {
            value: 3,
            label: 'Notice of Decision',
        },
    ]

    const documentMenu = () => {
        // const downloadAllLabel = () => {
        //     if (Object.entries(rowSelection).length === 0) {
        //         return t('Download All');
        //     }
        //     return `${t('Download')} : ${Object.keys(rowSelection).filter(key => rowSelection[key]).length} / 50}`;
        // }
        const haveSelectData = Object.entries(rowSelection).length > 0;
        return (
            <div className='w-fit flex gap-sm'>
                <div className="w-[312px]">
                    <WhiteSelect name='type' action={handleSearchDocumentType} options={documentType} defaultValue={filterData.type} disabled={haveSelectData} />
                </div>
                {haveSelectData &&
                    <div className='w-fit'>
                        <PrimaryButton t={t} label='Download All' action={downloadAll} />
                    </div>
                }
            </div>
        )
    }

    const downloadDocument = async (id) => {
        await previewFile(`my-document/download/${id}`, null,'GET')
    }

    const downloadAll = async () => {
        const selectedIds = Object.keys(rowSelection).filter(key => rowSelection[key]);
        await previewFile(`my-document/download`, null, 'GET', { ids: selectedIds });
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'createdAt',
                header: t('Date/Time'),
                objKey: "created_at",
                size: 300,
                grow: false,
                Cell: ({ cell, row }) => (
                    changeDate(null, null, cell.getValue())
                ),

            },
            {
                accessorKey: 'subject',
                header: t('Subject'),
                objKey: "subject",
                minSize: 704,
                grow: true,
                Cell: ({ cell, row }) => (
                    row.original[`subject${lang}`] ? row.original[`subject${lang}`] : '-'
                ),
            },
            {
                accessorKey: 'button',
                header: '',
                size: 160,
                enableSorting: false,
                grow: false,
                Cell: ({ cell, row }) => (
                    <div className="flex justify-center items-center w-full pe-sm">
                        {rowSelection?.length <= 0 || Object.entries(rowSelection)?.length <= 0 ? <TableDownloadButton t={t} action={() => downloadDocument(row.original.id)}/> : null}
                    </div>
                ),
            },
        ], [rows, lng, rowSelection]
    );

    const tableDataProps = {
        columns,
        border: false,
        customLoading: tableLoading,
        setCustomLoading: setTableLoading,
        enableSelection: true,
        disableSelection: Object.entries(rowSelection).length >= selectedLimited,
        setRowSelection,
        rowSelection,
        selectAll: true,
        pageIndex,
        apiProps: {
            setError,
            isFetching,
            setIsFetching,
            setState: setData,
            data: data,
            api: "my-document",
            appandData: {
                assessment_no: filterData?.assessment_no ?? "",
                trn:filterData?.trn ?? "",
                type: filterData?.type === 99 ? "" : filterData?.type,
            },
        },
        pageSize: 20,
        selectionLimited: selectedLimited,
        pageSizeOptions: [20, 50],
        setResetPagination,
        resetPagination,
        multiSort: false,
    };

    if (userLoading) return <LoadingUI/>;

    return (
        <>
            <Breadcrumb lng={lng}/>
            <PropertyTemplate
                t={t}
                lng={lng}
                title="My Documents"
                button_bar={documentMenu}
            >
                <form className="flex-col flex gap-x-big" onSubmit={handleSubmit}>
                    <AssessmentAndTRNSearch searchBy={searchBy} t={t} loading={loading} setSearchBy={setSearchBy} data={searchInputData} updateFields={updateFields} cancelAction={cancelAction} />
                </form>
                <DataTable {...tableDataProps} />
            </PropertyTemplate>
        </>
    )
}

export default MyDocumentPage;