import React, {useEffect, useState, useMemo, useRef} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import {useDeepCompareEffect} from "react-use";
import { DeepEqual } from "../../assets/js/global";
import LoadingUI from "../../components/loading/LoadingUI";
import UserIconData from "../../components/account_management/UserIconData";
import TabTemplate from "../../components/tab/TabTemplate";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import ProfileSection from "../../components/my-profile/ProfileSection";
import { ArrowBackButton, DiscardButton, DeleteWithTextButton } from "../../components/button/SingleButton";
import Radio from "../../components/input/Radio";
import Switcher from "../../components/button/Switcher";
import Line from "../../components/line/Line";
import Input from "../../components/input/Input";
import CategoryTable from "../../components/table/CategoryTable";
import AccountManagementTemplate from "../../components/account_management/Template";
import Breadcrumb from "../../components/header/Breadcrumb";
import user_account_type from "../../data/user_account_type.json"
import NotFoundPage from '../../pages/error/NotFoundPage';
import Popup from "../../components/popup/Popup";
import SmallFunction from "../../components/function/SmallFunction";
import ErrorMessage from "../../components/error/ErrorMessage";
import USER_TYPE from "../../data/variable/user_type.json";

const accountStatus = [
    {
        value: 1,
        label: "Active"
    },
    {
        value: 3,
        label: "Suspend"
    }
]

const CorporateAccountDetailsPage = ({ lng }) => {
    const { t } = useTranslation();
    const { userLoading, token, account_type } = useUserLoginContext();
    const { accountNo } = useParams();
    const { postForm, loading} = SubmitAndFetch({ lng, t });
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [icon, setIcon] = useState('');
    const [currentTab, setCurrentTab] = useState(0);
    const [showDeleteACPopup, setShowDeleteACPopup] = useState(false);
    const [showSuspensionPopup, setShowSuspensionPopup] = useState(false);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState([]);
    const [changed, setChanged] = useState(false);
    const myForm = useRef(null);
    const [checkboxState, setCheckboxState] = useState({});
    const [originalCheckboxState, setOriginalCheckboxState] = useState({});
    const { getIconValue } = SmallFunction();
    const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
    const api_name = 'corporate/account'
    const is_admin = Number(userData?.account_type) === USER_TYPE.ADMIN;

    useEffect(() => {
        const getData = async() => {
            await postForm(api_name, accountNo, null, setError, null, false, saveData, token, setOriginalData, null, false)
        }
        getData()
    }, [token])

    useEffect(() => {
        if (originalData?.username) {
            document.title = `${t("web_portal_title_text")} - ${t("Account details")} | ${originalData?.username}`;
        }
    }, [originalData?.username]);

    const saveData = (newData) => {
        const userObject = {
            account_type: newData.accountType.value,
            status: newData.status.value,
            suspend_remark: newData.suspendRemark,
        }
        setRows(newData.propertyCategoryList);
        setUserData({...userObject});
        setData({...userObject});
        getIconValue(newData.avatarColour?.value, setIcon);
    }

    const updateFields = (fields) => {
        setData(prev => ({ ...prev, ...fields }));
    };

    const setToDefaultValue = () => {
        setData(userData);
        setCheckboxState(originalCheckboxState);
        setChanged(false)
    }


    const details = [
        {
            label: 'English Name',
            data: originalData?.nameEn,
        },
        {
            label: 'Chinese Name',
            data: originalData?.nameZh,
        },
        {
            label: 'Email',
            data: originalData?.email,
        },
        {
            label: 'Last Login Date/Time',
            data: originalData?.lastLoginTime,
            borderTop: true
        },
        {
            label: 'Created Time',
            data: originalData?.createTime,
            borderTop: true
        }
    ]

    useDeepCompareEffect(() => {
        if (!DeepEqual(data, userData) || !DeepEqual(checkboxState, originalCheckboxState) ) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }, [data, checkboxState]);


    const handleRemoveAccount = () => {
        setShowDeleteACPopup(true)
    }

    const submitDelete = async (event) => {
        event.preventDefault();
        await postForm('corporate/account/delete', accountNo, null, setError, `/${lng}/user-management`, false, () => setShowDeleteACPopup(false), token, null, setDeleteLoading, true)
    }

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();
        const slug = currentTab === 0 ? 'edit' : 'category/edit';
        const formData = new FormData(myForm.current);
        formData.append('account_no', accountNo);
        if (currentTab !== 0) {
            const category_ids = Object.keys(checkboxState).filter(key => checkboxState[key]);
            formData.append('category_ids', category_ids);
        }
        await postForm(api_name, slug, formData, setError, null, true, null, token)
        setShowSuspensionPopup(false)
    }

    const deleteBtn = () => {
        return (
            <div className="me-auto md:me-0">
                <DeleteWithTextButton t={t} label='Delete Account' action={handleRemoveAccount} />
            </div>
        )
    }


    const inputProps = (name, inputLabel = null) => ({
        name,
        inputLabel,
        data: data ? data[name] : '',
        error: error ? error[name] : '',
        updateFields,
        setError,
        t,
    });

    const accountTypeDetails = () => {
        const active = Number(data['status']) === 1;
        const inactive = Number(data['status']) === 3;
        return (
            <>
                <ProfileSection title="User Role" t={t}>
                    <Radio {...inputProps('account_type')} options={user_account_type} className={'w-fit flex flex-row gap-lg'}/>
                </ProfileSection>
                <Line/>
                <ProfileSection title="Account Status" t={t}>
                    <Switcher {...inputProps('status')}
                              options={accountStatus}
                              activeColor={active ? "bg-label-icon-positive" : "bg-red-600" }
                              hoverColor={active ? "bg-green-800" : "bg-red-800" }
                    />
                    {inactive && <Input {...inputProps("suspend_remark", "Remarks")} />}
                    {error?.message ? <ErrorMessage message={error?.message} t={t}/> : null}
                </ProfileSection>
            </>
        )
    }

    useEffect(() => {
        if (rows && Object.entries(rows).length) {
            const initialCheckboxState = {};
            const setInitialCheckboxState = (row) => {
                initialCheckboxState[row.id] = row.permission === '4' ||  row.permission === '1';
                if (row.children) {
                    row.children.forEach(subRow => setInitialCheckboxState(subRow));
                }
            };
            rows.forEach(row => setInitialCheckboxState(row));
            setCheckboxState(initialCheckboxState);
            setOriginalCheckboxState(initialCheckboxState);
        }
    }, [rows]);


    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: t('Category'),
                minSize: 560,
                grow: false,
                enableSorting: false,
                Cell: ({ cell, row }) => {
                    return (
                        CategoryColumn(row, cell)
                    );
                }
            },
            {
                accessorKey: 'propertyTotal',
                header: t('No. of Property'),
                maxSize: 180,
                grow: false,
                enableSorting: false,
                Cell: ({ cell, row }) => {
                    return (
                        <div className="label1 text-body">{cell.getValue()}</div>
                    );
                }

            },
            {
                accessorKey: 'permission',
                header: t('Editable'),
                maxSize: 140,
                enableSorting: false,
                Cell: ({ cell, row }) => {
                    return (
                        <div className="pr-big">
                            {TableTreeCheckbox(row, cell, setCheckboxState, checkboxState, null, is_admin)}
                        </div>
                    );
                },
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
        ], [checkboxState, userData, lng]);

    const { CatTableTemplate, CategoryColumn, TableTreeCheckbox } = CategoryTable({ rows, columns, t});

    const categoryaccessDetails = () => {
        return (
            <>
                {CatTableTemplate("Property Categories", true)}
                {error?.message ? <ErrorMessage message={error?.message} t={t}/> : null}
            </>
        );
    };


    const tab = [
        {
            label: "Account Details",
            field: accountTypeDetails()
        },
        {
            label: "Category Access",
            field: categoryaccessDetails()
        },
    ]

    if (userLoading) return <LoadingUI/>;
    if (error.message === 'Account does not exist') {
        return <NotFoundPage />;
    }

    const apiProps = {
        loading: deleteLoading,
        customSubmit: submitDelete,
    }


    return (
        <>
            {showDeleteACPopup && <Popup t={t} lng={lng}
                                         setState={setShowDeleteACPopup}
                                         confirm_popup={true}
                                         apiProps={apiProps} title={t("Confirm to delete account?")}
                                         text={t('Are you sure you want to delete this account? Once deleted, it cannot be undone.')}/>}
            {showSuspensionPopup && <Popup t={t} lng={lng}
                                           setState={setShowSuspensionPopup}
                                           confirm_popup={true}
                                           buttonProps={{confirmLabel: 'Yes', cancelLabel: 'No', buttonType: 'button', action: handleSubmit}}
                                           apiProps={apiProps} title={t("Confirm to suspend account?")}
                                           text={t('Once an account is suspended, it cannot be reactivated. Are you sure to suspend this account?')}/>}
            <form onSubmit={Number(data['status'] === 3)
                ? (event) => {
                event.preventDefault();
                setShowSuspensionPopup(true)
            } :  handleSubmit
            } className="w-full" ref={myForm}>
                <Breadcrumb lng={lng} noCurrentElement={true}/>
                <AccountManagementTemplate
                    lng={lng}
                    title={originalData?.username}
                    button_bar={deleteBtn()}
                    noMenu={true}
                    setError={setError}
                >
                    <UserIconData userData={originalData} showStatus={true} lang={lang} t={t} icon={icon}>
                        {details && details.map((item, index) => {
                            return (
                                <div
                                    className={`flex items-center gap-sm md:gap-big py-md justify-between md:justify-start max-w-full flex-col md:flex-row ${item.borderTop ? 'border-t border-neutral-100 border-dashed' : ''}`}
                                    key={index}>
                                    <div className="w-full md:w-240px body2 text-title">{t(item.label)}</div>
                                    <div
                                        className="text-body label1 break-words whitespace-normal text-start w-full md:text-end md:w-fit">{item.data ? item.data : '--'}</div>
                                </div>
                            )
                        })}
                    </UserIconData>
                    <TabTemplate
                        setToDefaultValue={setToDefaultValue}
                        lng={lng}
                        tab={tab}
                        changed={changed}
                        backButton={<div className="mt-md md:mt-0 md:">
                            <ArrowBackButton t={t} href={`/${lng}/user-management`}/>
                        </div>}
                        discardButton={is_admin && currentTab === 1 ? null : <DiscardButton t={t} action={setToDefaultValue}/>}
                        saveButton={is_admin && currentTab === 1 ? null : "Save Changes"}
                        setCurrentTab={setCurrentTab}
                        loading={loading}
                        setError={setError}
                    />
                </AccountManagementTemplate>

            </form>
        </>
    )
}

export default CorporateAccountDetailsPage;