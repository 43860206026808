import { ReactComponent as NotFoundSVG } from '../../assets/images/error/404.svg';
import { BlueForwardLink } from "../../components/button/SingleButton";
import ErrorPageTemplate from "./ErrorPageTemplate";
import {useTranslation} from "react-i18next";
const NotFoundPage = ({ lng }) => {
    const { t } = useTranslation();
    return (
        <ErrorPageTemplate svg={<NotFoundSVG />} breadcrumbText="Error 404" title="We have lost this page" text="The required page is missing, please check the URL or return home." button={<BlueForwardLink href={`/${lng}`} label="Home" t={t} />} />
    );
}

export default NotFoundPage;