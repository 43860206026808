import brandhk_logo from '../../assets/images/footer/brandhk_logo.png'
import w3c_logo from '../../assets/images/footer/w3c_logo.png'
import webForAll_logo from '../../assets/images/footer/webForAll_logo.png'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const { t, i18n:{language} } = useTranslation();
    return(
        <footer className="border-default border-t bg-white">
            <div className="head-foot-container layout-container">
                <div className="footer flex justify-between items-center flex-col xl:flex-row">
                    <div className="footer-links">
                        <ul className="flex gap-sm md:gap-x-big text-center flex-wrap md:text-start text-surface-primary justify-around items-center">
                            {/*<li className="footer-label flex1">*/}
                            {/*    <Link to={t("access_to_infomation_link")} className="footer-link label1 hover:underline" target={"_blank"}>*/}
                            {/*        {t('Access to Information')}*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li className="footer-label flex1">
                                <Link to={`/${language}/privacy-policy`} className="footer-link label1 hover:underline">
                                    {t('Privacy Policy')}
                                </Link>
                            </li>
                            <li className="footer-label flex1">
                                <Link to={`/${language}/important-notice`}
                                      className="footer-link label1 hover:underline">
                                    {t('Important Notice')}
                                </Link>
                            </li>
                            <li className="footer-label flex1">
                                <Link to={`/${language}/site-map`} className="footer-link label1 hover:underline">
                                    {t('Site Map')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-logos flex gap-x-big mt-x-big items-center md:gap-x-lg xl:mt-0">
                        <Link to="https://www.w3.org/WAI/WCAG2AA-Conformance"
                              title={t('Level Double-A conformance, W3C WAI Web Content Accessibility Guidelines 2.1')}
                              target={"_blank"}>
                            <img src={w3c_logo} alt={t('Level Double-A conformance, W3C WAI Web Content Accessibility Guidelines 2.1')}/>
                        </Link>
                        <Link to={`https://www.digitalpolicy.gov.hk/${language}/our_work/digital_government/digital_inclusion/accessibility/nurturing_expertise/`} title={t('Web Accessibility Recognition Scheme')} target={"_blank"}>
                            <img src={webForAll_logo} alt={t('Web Accessibility Recognition Scheme')}/>
                        </Link>
                        <Link to="https://www.brandhk.gov.hk/" title={t('Brand Hong Kong')} target={"_blank"}>
                            <img src={brandhk_logo} alt={t('Brand Hong Kong')}/>
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;