import PropertyTemplate from "../../components/property/Template";
import React, { useState, useEffect, useMemo } from "react";
import Switcher from "../../components/button/Switcher";
import WhiteSelect from "../../components/input/portal/WhiteSelect";
import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import DataTable from "../../components/table/DataTable";
import AssessmentAndTRNSearch from "../../components/search/AssessmentAndTRNSearch";
import { EditButton, ArrowOutwardButton } from "../../components/button/SingleButton";
import Breadcrumb from "../../components/header/Breadcrumb";
import useDocumentTitle from "../../hooks/useDocument";

const statusOptions = [
    { label: 'Submitted', value: 1 },
    { label: 'Draft', value: 2 },
];
const yearsOptions = [
    { label: '2003', value: 2003 },
    { label: '2004', value: 2004 },
    { label: '2020', value: 2020 },
    { label: '2024', value: 2024 },
];

const TransactionHistoryPage = ({ lng }) => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [searchBy, setSearchBy] = useState(1);
    const [data,setData] = useState({});
    const [tableLoading, setTableLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [draftRows, setDraftRows] = useState([]);
    const { t } = useTranslation();
    const { userLoading } = useUserLoginContext();
    const [loading, setLoading] = useState(false);
    const { postForm } = SubmitAndFetch({lng, t});
    useDocumentTitle("Transaction History");

    const updateFields = (fields) => setData(prev => ({ ...prev, ...fields }));
    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const cancelAction = () => {
        setData({});
    }

    const getTableData = () => {
        setTableLoading(true);
        // setRows( [
        //     {
        //         id: 1,
        //         subject: "Submission of e-XXX",
        //         trn: "9999 9999 9999 9999",
        //         no_of_property: '20',
        //         no_of_withdrawn: '0',
        //         created_at: '2024/01/01 09:41:45',
        //         updated_at: '2024/01/01 09:41:45',
        //     },
        //     {
        //         id: 2,
        //         subject: "Submission of e-XXX",
        //         trn: "9999 9999 9999 9999",
        //         no_of_property: '200',
        //         no_of_withdrawn: '0',
        //         created_at: '2024/01/01 09:41:45',
        //         updated_at: '2024/01/01 09:41:45',
        //     },
        // ]);
        //
        // setDraftRows( [
        //     {
        //         id: 1,
        //         subject: "Submission of e-XXX",
        //         no_of_property: '20',
        //         updated_at: '2024/01/01 09:41:45',
        //     },
        //     {
        //         id: 2,
        //         subject: "Submission of e-XXX",
        //         no_of_property: '200',
        //         updated_at: '2024/01/01 09:41:45',
        //     },
        // ]);
        setTableLoading(false);
    }

    useEffect(() => {
        getTableData();
    }, [])

    const columns = useMemo(
        () => [
            {
                accessorKey: 'subject',
                header: t('Subject'),
                size: 340,
                grow: false,
                enableSorting: false,
            },
            {
                accessorKey: 'trn',
                header: t('Transaction Reference No.'),
                minSize: 220,
                grow: false,
                
            },
            {
                accessorKey: 'no_of_property',
                header: t('No. of Property'),
                maxSize: 120,
                grow: false,
                
            },
            {
                accessorKey: 'no_of_withdrawn',
                header: t('No. of withdrawn property'),
                size: 220,
                grow: false,
                
            },
            {
                accessorKey: 'created_at',
                header: t('Submission Time'),
                maxSize: 270,
                grow: true,
                
            },
            {
                accessorKey: 'button',
                header: '',
                size: 94,
                enableSorting: false,
                grow: false,
                Cell: ({ cell, row }) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <EditButton t={t} href={`/${lng}/transaction-history/details/${row.original.id}`} searchIcon={true} />
                    </div>
                ),
            },
        ],
        [rows, lng]
    );

    const draftColumns = useMemo(
        () => [
            {
                accessorKey: 'subject',
                header: t('Subject'),
                size: 400,
                grow: false,
                enableSorting: false,
            },
            {
                accessorKey: 'no_of_property',
                header: t('No. of Property'),
                size: 220,
                grow: false,
                
            },
            {
                accessorKey: 'updated_at',
                header: t('Last Modified at'),
                maxSize: 540,
                grow: true,
                
            },
            {
                accessorKey: 'outward_button',
                header: '',
                size: 104,
                enableSorting: false,
                grow: false,
                Cell: ({ cell, row }) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ArrowOutwardButton href='' t={t} />
                    </div>
                ),
            },
        ],
        [draftRows, lng]
    );

    if (userLoading) return <LoadingUI/>;

    return (
        <>
            <Breadcrumb lng={lng}/>
            <PropertyTemplate
                t={t}
                lng={lng}
                title="Transaction History"
            >
                <form className="flex-col flex gap-x-big" onSubmit={handleSubmit}>
                    <div className="flex gap-md items-center flex-col md:flex-row">
                        <div className="w-full">
                            <Switcher activeIndex={activeIndex} name='status' options={statusOptions} setActiveIndex={setActiveIndex} bgColor="bg-surface-card" t={t} action={getTableData}/>
                        </div>
                        {/*<div className="w-full flex-1 md:min-w-[228px]">*/}
                        {/*    {activeIndex === 1 && <WhiteSelect options={yearsOptions} placeholder="Year" name='year'/>}*/}
                        {/*</div>*/}
                    </div>
                    {/*{activeIndex === 1 &&*/}
                    {/*    <AssessmentAndTRNSearch searchBy={searchBy} t={t} loading={loading} setSearchBy={setSearchBy} data={data} updateFields={updateFields} cancelAction={cancelAction}/>*/}
                    {/*}*/}
                    <DataTable columns={activeIndex === 1 ? columns : draftColumns} data={activeIndex === 1 ? rows :draftRows} border={false} loading={tableLoading} pageSizeOptions={[20, 50]} pageSize={20}/>
                </form>
            </PropertyTemplate>
        </>
    );
}

export default TransactionHistoryPage;