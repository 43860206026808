import PropertyTemplate from "../../components/property/Template";
import React, { useState, useMemo, useEffect, useRef } from "react";
import CateSelect2 from "../../components/input/portal/CateSelect2";
import Tooltip from "../../components/tooltip/Tooltip";
import Switcher from "../../components/button/Switcher";
import WhiteInput from "../../components/input/portal/WhiteInput";
import DataTable from "../../components/table/DataTable";
import AddIcon from "@mui/icons-material/Add";
import SearchPopupTable from "../../components/table/SearchPopupTable";
import CloseIcon from "@mui/icons-material/Close";
import Breadcrumb from "../../components/header/Breadcrumb";
import { useTranslation } from "react-i18next";
import {
  OutlineBackButton,
  PrimaryButton,
} from "../../components/button/SingleButton";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { useClickAway } from "@uidotdev/usehooks";
import RemoveIcon from "@mui/icons-material/Remove";
import Swal from "sweetalert2";
import Popup from "../../components/popup/Popup";

const AddProperty = ({ lng }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const { uploadData } = state || {};
  const { token } = useUserLoginContext();
  const [cateName, setCateName] = useState([]);
  const [existData, setExistData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const searchForm = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  const [isUploadData, setIsUploadData] = useState(false);
  const [data, setData] = useState({});
  const [searchFormData, setSearchFormData] = useState({ search_type: "1" });
  const [selectRow, setSelectRow] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const [error, setError] = useState([]);
  const [filterOption, setFilterOption] = useState({});
  const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
  const [isFetching, setIsFetching] = useState(false);
  const defaultPagination = {
    pageIndex: 0,
    pageSize: 10,
    rowCount: 0,
    totalPages: 0,
    pageSizeOptions: [10, 50],
  }

  const [paginationModel, setPaginationModel] = useState(defaultPagination);

  const [selectPaginationModel, setSelectPaginationModel] = useState({
    pageIndex: 0,
    pageSize: 20,
    rowCount: 0,
    totalPages: 0,
    pageSizeOptions: [20, 50],
  });

  const searchBoxRef = useClickAway(() => {
    setShowSearchBox(false);
    handleReset();
  });

  useEffect(() => {
    if (selectedRowsData.length > 0) {
      // setPaginationModel((prevModel) => ({
      //   ...prevModel,
      //   totalPages: Math.ceil(selectedRowsData.length / prevModel.pageSize),
      //   // rowCount: selectedRowsData.length,
      // }));
    }
  }, [selectedRowsData]);

  useEffect(() => {
    if (uploadData) {
      const dataForSelectRow = uploadData?.allValidList.map((item) => ({
        assessmentNo: item.assessmentNo,
      }));
      setSelectedRowsData(uploadData?.allValidList);
      setSelectRow(dataForSelectRow);
      setIsUploadData(true);
    }
  }, [uploadData]);

  useEffect(() => {
    document.title = `${t(
      "web_portal_title_text"
    )} - Add Property | ${cateName}`;
  }, [cateName]);

  const handleGetFilterData = (data) => {
    if (data) {
      setFilterOption(data);
      const cat_name =
        data?.categoryList.find((cate) =>
          id === "0" ? cate.id === "" : cate.id === id
        )?.name || "";
      if (cat_name) {
        setCateName(cat_name);
      } else {
        if (id === location?.state?.parentId) {
          setCateName(location.state.parentCateName);
        }
      }
    }
  };

  useEffect(() => {
    const getFilterData = async () => {
      await postForm(
        "property/add/filter",
        null,
        null,
        setError,
        null,
        false,
        handleGetFilterData,
        token,
        null,
        null,
        null,
        false
      );
    };
    if (!Object.keys(filterOption).length) {
      getFilterData();
    }
  }, []);

  const changeCateAction = (event) => {
    navigate(
      `/${lng}/property-list/view-property/add/${event.target.value || "0"}`
    );
    setCateName(
      filterOption?.categoryList.find((cate) => cate.id === event.target.value)
        ?.name || ""
    );
  };

  const options = [
    { value: 1, label: "Assessment No." },
    { value: 2, label: "Billing Account No." },
    { value: 3, label: "Consolidated Account No. & Payer Name" },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "assessmentNo",
        header: t("Assessment No."),
        grow: true,
        enableSorting: false,
        Header: ({ column }) => (
          <div className="ps-md">{column.columnDef.header}</div>
        ),
        Cell: ({ cell, row }) => (
          <div className="flex flex-col md:flex-row md:items-center">
            <div className="md:min-w-[200px] mb-sm md:mb-0">{cell.getValue()}</div>
            <div className="break-words text-wrap">
              {row.original.address}
            </div>
          </div>
        ),
      },
      // {
      //   accessorKey: "address",
      //   header: "Address or Description of Tenement",
      //   minSize: 300,
      //   grow: true,
      //   enableSorting: false,
      //   Cell: ({ cell, row }) => (
      //     <div className="relative flex items-center me-10px w-full h-full gap-md group">
      //       <div className="flex-1">{cell.getValue()}</div>
      //     </div>
      //   ),
      // },
      {
        accessorKey: "id",
        header: "",
        size: 80,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => (
          <div className="flex items-center me-[10px] w-[30px] md:w-full h-full gap-md group justify-end">
            <div className="flex gap-sm">
              {Object.entries(selectRow)?.length < 2000 &&
                !row.original.haveSelected && (
                  <div
                    title={t("Add to list")}
                    className="add-icon bg-surface-primary rounded-full hover:bg-surface-primary-hover flex justify-center items-center w-x-big h-x-big p-sm cursor-pointer opacity-0 group-hover:opacity-100"
                    onClick={() => addSelectedRow(row.original.assessmentNo)}
                  >
                    <AddIcon className="text-white" fontSize="inherit" />
                  </div>
                )}
              {row.original.haveSelected && (
                <div
                  title={t("Remove from list")}
                  className="bg-surface-primary rounded-full hover:bg-surface-primary-hover flex justify-center items-center w-x-big h-x-big p-sm cursor-pointer"
                  onClick={() => removeSelectedRow(row.original.assessmentNo)}
                >
                  <RemoveIcon className="text-white" fontSize="inherit" />
                </div>
              )}
            </div>
          </div>
        ),
      },
    ],
    [selectRow, lng]
  );

  const selectedColumns = useMemo(
    () => [
      {
        accessorKey: "assessmentNo",
        header: t("Assessment No."),
        minSize: 320,
        grow: false,
        enableSorting: true,
        Header: ({ column }) => (
          <div className="ps-md">{column.columnDef.header}</div>
        ),
        Cell: ({ cell, row }) => <div className="ps-md">{cell.getValue()}</div>,
      },
      {
        accessorKey: "address",
        header: t("Address or Description of Tenement"),
        minSize: 720,
        grow: true,
        enableSorting: false,
        Cell: ({ cell, row }) => (
          <div className="justify-between items-center flex me-10px w-full gap-md">
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: "button",
        header: "",
        grow: false,
        maxSize: 180,
        enableSorting: false,
        Cell: ({ cell, row }) => (
          <div
            className="flex justify-center w-full"
            title={t("Remove from list")}
            onClick={() => removeSelectedRow(row.original.assessmentNo)}
          >
            <div className="bg-surface-primary rounded-full hover:bg-surface-primary-hover flex justify-center items-center w-x-big h-x-big p-sm cursor-pointer">
              <CloseIcon className="text-white" fontSize="inherit" />
            </div>
          </div>
        ),
      },
    ],
    [lng, selectRow]
  );

  // const addSelectedRow = (assessmentNo) => {
  //     if (selectRow.length < 2000) {
  //         setData((prevState) => {
  //             const selectedData = prevState.find((row) => row.assessmentNo === assessmentNo);
  //             if (selectedData) {
  //                 const pageIndex = paginationModel.pageIndex;
  //                 setSelectRow((prevState) => [...prevState, { assessmentNo, page: pageIndex }]);
  //                 setSelectedRowsData((prevState) => [...prevState, { ...selectedData, page: pageIndex }]);
  //                 return prevState.filter((item) => item.assessmentNo !== assessmentNo);
  //             }
  //             return prevState;
  //         });
  //     }
  // };
  //
  // const selectAll = (event) => {
  //     event.preventDefault();
  //     if (selectRow.length < 2000) {
  //         const pageIndex = paginationModel.pageIndex;
  //         setSelectRow((prevState) => [
  //             ...new Set([...prevState, ...data.map(item => ({ assessmentNo: item.assessmentNo, page: pageIndex }))])
  //         ]);
  //         setSelectedRowsData((prevState) => [
  //             ...new Set([...prevState, ...data.map(item => ({ ...item, page: pageIndex }))])
  //         ]);
  //         setData([]);
  //         setPaginationModel((prevState) => ({
  //             ...prevState,
  //             pageIndex: prevState.pageIndex + 1,
  //         }));
  //     }
  // };

  const addSelectedRow = (assessmentNo) => {
    if (selectRow?.length < 2000) {
      setData((prevState) => {
        const selectedData = prevState.find(
          (row) => row.assessmentNo === assessmentNo
        );
        if (selectedData) {
          setSelectRow((prevState) => [...prevState, { assessmentNo }]);
          setSelectedRowsData((prevState) => [
            ...prevState,
            { ...selectedData },
          ]);
          return prevState.map((item) =>
            item.assessmentNo === assessmentNo
              ? { ...item, haveSelected: true }
              : item
          );
        }
        return prevState;
      });
    }
  };


  const selectAll = () => {
    if (selectRow?.length < 2000) {
      setData((prevState) => {
        const filteredData = prevState.filter((item) => !item.haveSelected);
        const remainingCapacity = 2000 - selectRow?.length;
        const itemsToAdd = filteredData.slice(0, remainingCapacity);
        setSelectRow((prevState) => [
          ...new Set([
            ...prevState,
            ...itemsToAdd.map((item) => ({ assessmentNo: item.assessmentNo })),
          ]),
        ]);

        setSelectedRowsData((prevState) => [
          ...new Set([
            ...prevState,
            ...itemsToAdd.map((item) => ({ ...item })),
          ]),
        ]);

        return prevState.map((item) =>
          itemsToAdd.some(
            (filteredItem) => filteredItem.assessmentNo === item.assessmentNo
          )
            ? { ...item, haveSelected: true }
            : item
        );
      });
    }
  };

  const removeSelectedRow = (assessmentNo) => {
    setSelectRow((prevState) =>
      prevState.filter((item) => item.assessmentNo !== assessmentNo)
    );
    setSelectedRowsData((prevState) =>
      prevState.filter((row) => row.assessmentNo !== assessmentNo)
    );
    setData((prevState) => {
      if (Array.isArray(prevState)) {
        return prevState.map((item) =>
          item.assessmentNo === assessmentNo
            ? { ...item, haveSelected: false }
            : item
        );
      }
      return prevState;
    });
  };

  const selectedRows = useMemo(() => {
    return selectedRowsData;
  }, [selectedRowsData]);

  const handleSearch = (event) => {
    const formData = new FormData(event.target);
    event.preventDefault();
    setPopupLoading(true);
    setShowSearchBox(true);
    setIsFetching(true);
    formData.append("search_type", activeIndex);
    setSearchFormData(formData);
  }


  const findItemById = (array, id) => {
    return array.find(item => item.id === id);
  };
  const handleRemoveRepeatDataAndSubmit = (event) => {
    setShowPopup(false)
    const uniqueAssessmentNumbers = new Set(
      existData?.map((item) => item.assessmentNo)
    );
    const updatedProperties = selectRow.filter(
      (value) => !uniqueAssessmentNumbers.has(value.assessmentNo)
    );

    const updatedPayload = {
      category_id: id === "0" ? "" : findItemById(filterOption?.categoryList, id) ? id : "",
      assessment_no_list: updatedProperties.map((item) => item.assessmentNo),
    };

    existData?.map((item) => removeSelectedRow(item?.assessmentNo))

    handleSubmit(event, updatedPayload);
  };

  const handleSubmit = async (event = null, updatedPayload = null) => {
    if (event) event.preventDefault();
    const payload = updatedPayload
      ? updatedPayload
      : {
          category_id: id === "0" ? "" : findItemById(filterOption?.categoryList, id) ? id : "",
          assessment_no_list: selectRow.map((item) => item.assessmentNo),
        };
    await postForm(
      "property/add",
      null,
      payload,
      setError,
      `/${lng}/property-list/view-property/${id}`,
      false,
      () => setShowPopup(false),
      token,
      null,
      null,
      true,
      false,
      (result) => {
        if (result.code === 202) {
          if (result?.data?.existCategoryList?.length) {
            setExistData(result?.data?.existCategoryList);
            setShowPopup(true);
          }else if (result?.data?.existList?.length) {
            setExistData(result?.data?.existList);
            setShowPopup(true);
          }
        } else {
          Swal.fire({
            html: `<p>${
              result.code === 201
                ? formatErrors(result.errors)
                : JSON.stringify(result.message)
            }</p>`,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("Ok"),
            customClass: {
              confirmButton: "primary-btn",
              title: "alert-title",
              content: "alert-content",
            },
          });
        }
      }
    );
  };

  const formatErrors = (errors) => {
    const formattedErrors = Object.entries(errors)
      .map(([key, errorMessages]) => {
        return t(errorMessages);
      })
      .join("\n");

    return formattedErrors;
  };

  const handleReset = () => {
    setPaginationModel(defaultPagination);
    setData({});
    setSearchFormData({});
    setShowSearchBox(false);
    setIsFetching(false);
  };

  const assessmentNoInput = () => (
    <WhiteInput
      name="assessment_no"
      searchbar={true}
      placeholder="Assessment No."
      resetAction={handleReset}
      t={t}
      action={() => setIsFetching(false)}
    />
  );

  const billingAccountInput = () => (
    <WhiteInput
      name="building_account_no"
      searchbar={true}
      placeholder="Billing Account No."
      resetAction={handleReset}
      t={t}
      action={() => setIsFetching(false)}
    />
  );

  const consolidatedAccountInput = () => (
    <WhiteInput
      flexInput={[
        {
          name: "consolidated_account_no",
          placeholder: "Consolidated Account No.",
        },
        {
          name: "consolidated_payer_name",
          placeholder: "Consolidated Payer Name",
        },
      ]}
      action={() => setIsFetching(false)}
      searchButton={true}
      resetAction={handleReset}
      t={t}
    />
  );

  const popupText = (
    <div>
      {t('There are')} <span style={{ fontWeight: "700" }}>{existData?.length} </span>
      {t('properties already existing.')} <br />
      {t('Do you want to proceed and save the data, ignoring these existing properties?')}
    </div>
  );

  const PopupProps = (setState) => ({
    t,
    setState,
    apiProps: {
      loading,
      setError,
      customSubmit: handleRemoveRepeatDataAndSubmit,
    },
  });

  const SearchPopupTableProps = {
    columns: columns,
    data: data,
    tableProps: {
      emptyMessage: (
        <div className="body1 text-body px-md py-sm">
          {t("No Property found")}
        </div>
      ),
      paginationModel,
      setPaginationModel,
      loading: popupLoading,
      selectAll,
      totalSelectRow: selectRow?.length,
      selectRow,
      showSearchBox,
    },
    apiProps: {
      setData,
      setPopupLoading,
      setIsFetching,
      isFetching,
      formData: searchFormData,
      setError,
      token,
    },
  };

  const tableDataProps = {
    columns: selectedColumns,
    data: selectedRowsData,
    border: false,
    isServerSide: false,
    ...selectPaginationModel,
    emptyMessage: (
      <div
        className="label1 text-title px-xxx-big py-big"
        style={{ marginTop: "-1px" }}
      >
        {t('No Property')}
      </div>
    ),
  };

  return (
    <>
      <Breadcrumb
        lng={lng}
        customElement={[
          {
            name: "Property List",
            link: `property-list`,
          },
          {
            name: cateName,
            link: `property-list/view-property/${id}`,
          },
        ]}
        currentElement="Add Property"
      />
      {showPopup && (
        <Popup
          {...PopupProps(setShowPopup)}
          confirm_popup={true}
          title={t("Save Data with Existing Properties?")}
          text={popupText}
        />
      )}
      <PropertyTemplate t={t} lng={lng} title="Add Property">
        <form
          className="flex flex-col gap-lg md:gap-xx-lg md:mt-xx-lg"
          onSubmit={handleSearch}
          ref={searchForm}
        >
          <div className="flex flex-col gap-x-big">
            <h3 className="h3 text-title">{t("Select Category")}</h3>
            <CateSelect2
              cateId={id}
              categories_options={filterOption?.categoryList || []}
              changeCateAction={changeCateAction}
            />
          </div>
          <div className="flex flex-col gap-x-big">
            {!isUploadData && (
              <h3 className="h3 text-title flex items-center gap-x-sm">
                {t('Search Property')}
                <Tooltip
                  title="You can search the properties by entering the assessment no., billing account no., and consolidated account no. & payer name."
                  t={t}
                />
              </h3>
            )}
            <div className="flex flex-col gap-md relative">
              {!isUploadData && (
                <>
                  <Switcher
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    options={options}
                    bgColor="bg-surface-card"
                    t={t}
                    action={handleReset}
                  />
                  <div className="flex gap-10px">
                    {activeIndex === 1 && assessmentNoInput()}
                    {activeIndex === 2 && billingAccountInput()}
                    {activeIndex === 3 && consolidatedAccountInput()}
                  </div>
                  <div
                    className="absolute w-full md:top-[140px] top-[240px]"
                    style={{
                      minHeight: "67px",
                      zIndex: showSearchBox ? "2" : "0",
                      display: showSearchBox ? "table" : "none",
                    }}
                    ref={searchBoxRef}
                  >
                    {showSearchBox ? (
                      <SearchPopupTable {...SearchPopupTableProps} />
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
        <DataTable {...tableDataProps} />
        <div className="flex justify-between">
          <div className="w-fit">
            <OutlineBackButton
                t={t}
                href={`/${lng}/property-list/view-property/${id}`}
                label="Cancel"
            />
          </div>
          <div className="w-fit">
            <PrimaryButton
                t={t}
                label="Save"
                loading={loading}
                action={handleSubmit}
            />
          </div>
        </div>
      </PropertyTemplate>
    </>
  );
};

export default AddProperty;
