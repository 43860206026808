import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import UserLoginProvider from "../context/UserLoginProvider";

function LoginLayout() {
    const {i18n: {language}} = useTranslation();
    return (
        <UserLoginProvider lng={language}>
            <main>
                <Outlet />
            </main>
        </UserLoginProvider>
    );
}

export default LoginLayout;