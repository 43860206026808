import React, { useState, useEffect } from "react";
import changeStepForms from "../../components/step/ChangeStepForms";
import StepOne from "../../components/forgot-activate-form/StepOne";
import StepTwo from "../../components/forgot-activate-form/StepTwo";
import StepThree from "../../components/forgot-activate-form/StepThree";
import FormHeaderTemplate from "../../components/form/FormHeaderTemplate";
import StepFormTemplate from "../../components/form/StepFormTemplate";
import MtCaptchaScript from "../../components/MtCaptcha/MtCaptchaScript";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../hooks/useDocument";

const ForgetPassword = ({ lng }) => {
    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [loadingCapthcha, setLoadingCapthcha] = useState(false);
    const { t } = useTranslation();
    const { loadMtCaptcha } = MtCaptchaScript({loadingCapthcha, setLoadingCapthcha});
    const api_name = 'forgot';
    const slug = 'password';
    useDocumentTitle("Forgot Password");

    const props = (name, inputLabel, type="text") => {
        return {
            name,
            inputLabel,
            type,
            updateFields,
            error: error ? error[name] : '',
            data: data ? data[name] : '',
            setError,
            lng,
            t,
        }
    }

    const updateFields = (fields => {
        setData(prevData => ({ ...prevData, ...fields }));
    });

    useEffect(() => {
        loadMtCaptcha();
    }, []);

    const {
        steps,
        currentStepIndex,
        step,
        isFirstStep,
        isLastStep,
        back,
        next
    } = changeStepForms([
        <StepOne
            key="1"
            api_name={api_name}
            slug={slug}
            props={props}
            data={data}
            error={error}
            setError={setError }
            loadingCapthcha={loadingCapthcha}
            t={t}
        />,
        <StepTwo
            key="2"
            props={props}
            data={data}
            slug={slug}
            error={error}
            setError={setError }
            api_name={api_name}
            t={t}
        />,
        <StepThree
            data={data}
            key="3"
            api_name={api_name}
            slug={slug}
            props={props}
            updateFields={updateFields}
            PasswordData={data ? data["password"] : ''}
            ConfirmData={data ? data["password2"] : ''}
            PasswordError={error ? error["password"] : ''}
            ConfirmError={error ?error["password2"] : ''}
            setError={setError }
            error={error}
            t={t}
        />
    ]);

    return (
        <div className="bg-50">
            <div className="registration-container py-xxx-lg layout-container">
                <FormHeaderTemplate
                    title='Forgot Password'
                    subtitle='Please follow the instructions to reset your password.'
                    t={t}
                />
                <StepFormTemplate
                    formData={data}
                    setError={setError}
                    step={step}
                    isLastStep={isLastStep}
                    back={back}
                    next={next}
                    currentStepIndex={currentStepIndex}
                    isFirstStep={isFirstStep}
                    api_name={api_name}
                    slug={isLastStep ? slug : slug}
                    back_path={`/login`}
                    captchaStepData={{
                        data: data["captcha_verify_code"] || "",
                        name: "captcha_verify_code"
                    }}
                    type={api_name}
                    lng={lng}
                    redirect_path={`/successfully?type=forgot`}
                    updateFields={updateFields}
                    getCode={isFirstStep}
                />
            </div>
        </div>
    );
};


export default ForgetPassword;