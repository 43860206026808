import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {useUserLoginContext} from "../../context/UserLoginProvider";

const Nav = ({ showNavBar, handleToggle, lng }) => {
    const { t } = useTranslation()
    const {profileData} = useUserLoginContext()
    const location = useLocation();
    const options = [
        { label: 'Home', link: `/${lng}` },
        { label: 'Property List', link: `/${lng}/property-list` },
        { label: 'Proposal Submission', link: `/${lng}/proposal-submission` },
        { label: 'Transaction History', link: `/${lng}/transaction-history` },
        { label: 'Submitted Documents', link: `/${lng}/submitted-documents` },
        { label: 'My Documents', link: `/${lng}/my-documents` },
        { label: 'Help Centre', link: `/${lng}/help-centre` },
    ];

    return (
        profileData && Object.entries(profileData).length ?
            <nav className="nav-bar bg-surface-primary sticky z-40 hidden xl:block" style={{ top: '-1px'}}>
            <ul className="menu-bar layout-container flex text-white justify-between py-sm gap-sm 2xl:gap-0">
                {options && options.map((option, index) => {
                    const { label, link } = option;
                    const matchPath = location.pathname === link;
                    return (
                        <li className={`menu-label rounded-xx-sm ${matchPath ? 'active' : ''}`} key={index}>
                            <Link to={link} className="h-full px-sm 2xl:px-md w-full hidden xl:flex  items-center label1">
                                {t(label)}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </nav> : null

    );
};

export default Nav;