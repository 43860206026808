import SectionTemplate from "../../components/proposal-submission-form/sectionTemplate";
import React, { useEffect, useRef, useState } from "react";
import Radio from "../../components/input/Radio";
import WhiteInput from "../../components/input/portal/WhiteInput";
import ErrorMessage from "../../components/error/ErrorMessage";
import CircularProgress from "@mui/material/CircularProgress";
import { useClickAway } from "@uidotdev/usehooks";

const StepOne = ({ data, t, error, setError, updateFields }) => {
    const [TRNTimer, setTRNTimer] = useState(2);
    const [searchLoading, setSearchLoading] = useState(false);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [TRNSearchData, setTRNSearchData] = useState([]);
    const searchBoxRef = useClickAway(() => {
        setShowSearchBox(false);
    });
    const is_underCompany = false;
    const textArea = useRef(null);

    const inputProps = (name, inputLabel ) => ({
        name,
        inputLabel,
        placeholder: inputLabel,
        data: data ? data[name] : '',
        error: error ? error[name] : '',
        updateFields,
        setError,
        t,
    });

    const channelOptions = [
        { value: 1, label: 'e-Form' },
        { value: 2, label: 'In-Paper Form' },
    ]

    useEffect(() => {
        const postSearchData = async () => {
            setTRNSearchData([
                { trn: '9999 9999 9999 9999', property_no: '20', properties: ['144-51365-1000-0A', '144-51365-1000-0A', '144-51365-1000-0A', '144-51365-1000-0A'] },
                { trn: '1234 1234 1234 1234', property_no: '5', properties: ['144-51365-1000-0A', '144-51365-1000-0A', '144-51365-1000-0A', '144-51365-1000-0A'] },
                { trn: '2222 2222 2222 2222', property_no: '10', properties: ['144-51365-1000-0B', '144-51365-1000-0B', '144-51365-1000-0B', '144-51365-1000-0B'] },
            ]);
            const response = 'hi';
            if (response) {
                setSearchLoading(false);
            }
        };

        if (TRNTimer === 0) {
            postSearchData();
        } else {
            const timer = setInterval(() => {
                setTRNTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [TRNTimer]);

    const showTRNPopup = (event) => {
        setTRNSearchData([])
        setTRNTimer(2);
        if (event.target.value < 1) {
            setShowSearchBox(false)
        } else {
            setShowSearchBox(true);
            setSearchLoading(true);
        }
    }


    return (
        <SectionTemplate title='Proposal Information' t={t}>
            <div className="flex md:items-center gap-md flex-col md:flex-row">
                <span className="body1 text-body">{t('Proposal Submission Channel')}:</span>
                <Radio {...inputProps('proposal_submission_channel')} options={channelOptions} gap='lg' action={() => {
                    updateFields({assessment_no: ''})
                    updateFields({trn: ''})
                    updateFields({pen: ''})
                    textArea.current.value = '';
                }}/>
            </div>
            {Number(data['proposal_submission_channel']) === 1 ?
                <>
                    <div className='relative'>
                        <div className="body1 text-body mb-sm">{t('Transaction Reference No.')}</div>
                        <WhiteInput {...inputProps('trn', 'Transaction Reference No.')} help_text='Enter or search transaction reference no.' action={showTRNPopup} />
                        {showSearchBox &&
                            <div className='bg-surface-card p-x-sm rounded-sm shadow-2xl w-full absolute top-[100px] min-h-[51px]'>
                                {searchLoading ?
                                    <div className='flex justify-center items-center py-sm px-md'>
                                        <CircularProgress style={{ width: '30px', height: '30px', color: 'var(--surface-primary)'}}/>
                                    </div>
                                    :
                                    <ul className='w-full' ref={searchBoxRef}>
                                        {TRNSearchData && TRNSearchData.length > 0 &&
                                            TRNSearchData.map((data, index) => (
                                                <li className='flex body1 text-body justify-between text-start w-full py-sm px-md rounded-sm hover:cursor-pointer hover:bg-surface-secondary' key={index} onClick={() => {
                                                    updateFields({assessment_no: data.properties, trn: data.trn});
                                                    setShowSearchBox(false);
                                                }}>
                                                    <span className='flex-1'>{data.trn}</span>
                                                    {!is_underCompany && <span className='flex-1'>{data.property_no} {t('Properties')}</span>}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                }
                            </div>
                        }
                    </div>
                </>
                :
                <div>
                    <div className="body1 text-body mb-sm">{t('Progress Enquiry No.')}</div>
                    <WhiteInput {...inputProps('pen', 'Progress Enquiry No.')}/>
                </div>
            }
            <div>
                <div className="body1 text-body mb-sm">{t('Assessment No.')}</div>
                <textarea name='assessment_no' value={Number(data['proposal_submission_channel']) === 1 && !is_underCompany && data['assessment_no'] ? data['assessment_no'].join('\n') : undefined} className="default_textarea w-full custom-scrollbar-textarea py-x-sm overflow-y-scroll resize" rows="15" readOnly={Number(data['proposal_submission_channel']) === 1 && !is_underCompany} ref={textArea}/>
                <ErrorMessage error={error['assessment_no']} t={t}/>
            </div>
    </SectionTemplate>
    )
}

export default StepOne;