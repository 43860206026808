import { Link } from "react-router-dom";
import React, {useEffect} from "react";
import { ReactComponent as Logo } from "../../assets/images/header/en_logo.svg";
import { ReactComponent as TCLogo } from "../../assets/images/header/tc_logo.svg";
import { ReactComponent as SCLogo } from "../../assets/images/header/sc_logo.svg";
import { ReactComponent as ErrorSVG } from "../../assets/images/error/Error.svg";
import { useTranslation } from "react-i18next";
import { BlueForwardLink } from "../../components/button/SingleButton";
import {AddDataToHTML} from "../../assets/js/global";
import {useCookies} from "react-cookie";
import {useUserLoginContext} from "../../context/UserLoginProvider";

const ErrorPage = ({ lng }) => {
  const { t } = useTranslation();
  const { userLoading} = useUserLoginContext()

  useEffect(() => {
        localStorage.removeItem("userData");
        AddDataToHTML("data-theme", "blue");
  }, []);

  return (
    <div className="layout-container content-container h-[100vh] w-full flex justify-center items-center flex-col gap-x-big">
      <div>
        <Link to={t("rvd_home_url")} className="rvd-logo">
          {lng === "tc" ? (
            <TCLogo alt="差餉物業估價署" width="100%" />
          ) : lng === "sc" ? (
            <SCLogo alt="差餉物業估價署" width="100%" />
          ) : (
            <Logo alt="差餉物業估價署" width="100%" />
          )}
        </Link>
      </div>
      <div className="bg-white py-xx-lg px-xx-big w-full max-w-[600px] rounded-big flex flex-col gap-lg items-center text-center">
        <div>
          <ErrorSVG />
        </div>
        <div>
          <h1 className="h1">{t("Error")}</h1>
          <p className="body2 mt-sm">
            {t("Something went wrong, please login again.")}
          </p>
          <div className="mt-x-big w-fit mx-auto">
            <BlueForwardLink href={`/${lng}/login`} label="Login" t={t} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
