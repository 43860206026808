import CircularProgress from "@mui/material/CircularProgress";
import ErrorMessage from "../../components/error/ErrorMessage";
import { useTranslation } from "react-i18next";

const MtCaptcha = ({ loadingCapthcha, error, id = "mtcaptcha1" }) => {
    const { t} = useTranslation();
    return (
        <div>
            {loadingCapthcha ? <CircularProgress style={{marginTop: '10px', color: 'var(--surface-primary)'}}/> : <div className='mtcaptcha' id={id}></div>}
            {/*<div className='mtcaptcha'></div>*/}
            {error &&  <ErrorMessage error={error} t={t}/>}
        </div>
    )
}

export default MtCaptcha;