import {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import Header from "../../components/header/Header";
import Nav from "../../components/header/Nav";

const HeaderAndNavBar = ({ lng }) => {
    const location = useLocation();
    const [showNavBar, setShowNavBar] = useState(false);
    const handleToggle = () => {
        setShowNavBar(prev => !prev);
    };

    useEffect(() => {
        setShowNavBar(false);
    }, [location]);

    return (
        <>
            <Header showNavBar={ showNavBar } handleToggle={ handleToggle } lng={lng}/>
            <Nav showNavBar={showNavBar} handleToggle={handleToggle} lng={lng}/>
        </>
    )
}

export default HeaderAndNavBar;