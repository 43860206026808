import DoneIcon from "@mui/icons-material/Done";
import React from "react";
import ErrorMessage from "../error/ErrorMessage";
import { useTranslation } from "react-i18next";

const Switcher = ({
  name,
  options,
  setActiveIndex,
  activeIndex,
  updateFields,
  data,
  setShowSMS,
  action,
  disabled,
  setError,
  clearAlldata,
  activeColor,
  hoverColor,
  bgColor,
  errorOption,
  icon,
  error,
  t,
}) => {
  const active = `${activeColor ? activeColor : "bg-surface-primary"} ${
    hoverColor ? "hover:" + hoverColor : "hover:bg-surface-primary-hover"
  } text-label-icon-on-primary`;
  const inactive = `${
    bgColor ?? "bg-surface-base"
  } text-body hover:bg-surface-hover`;
  const {
    i18n: { language },
  } = useTranslation();
  const lang = language.charAt(0).toUpperCase() + language.slice(1);
  const handleChange = (value) => {

    if (data && value !== data) {
      if (clearAlldata) {
        clearAlldata();
      } else {
        // for (let key in data) {
        //     updateFields({ [key]: "" });
        // }
      }
      if (errorOption && setError) {
        errorOption.map((error) => setError((prev) => ({ ...prev, [error]: "" })));
      } else if (setError) {
        setError("");
      }
    }

    if (setActiveIndex) setActiveIndex(value);
    if (setShowSMS) setShowSMS(false);
    if (action) action(value);
    if (updateFields) updateFields({ [name]: value });
  };
  const renderButton = (option) => {
    const isActive =
      Number(activeIndex) === Number(option.value) ||
      Number(data) === Number(option.value);

    return (
      <button
        type="button"
        key={option.value}
        className={`flex-1 body1 rounded-sm w-full flex gap-x-sm justify-center items-center h-x-lg p-sm ${
          isActive ? active : inactive
        } ${
          disabled
            ? "pointer-events-none cursor-default"
            : "pointer-events-auto cursor-pointer"
        }`}
        onClick={() => handleChange(option.value)}
        style={{ minWidth: "120px", whiteSpace: "nowrap" }}
      >
        {isActive ? option.icon ? option.icon : <DoneIcon /> : null}
        <span className="truncate">
          {option.label ? t(option.label) : option?.[`label${lang}`]}
        </span>
      </button>
    );
  };

  if (!options || !options.length) {
    return null;
  }

  return (
    <>
      <div
        className={`${
          bgColor ?? "bg-surface-base"
        } flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-sm p-xx-sm rounded-sm border border-light`}
      >
        {options && options.map(renderButton)}
        <input
          name={name}
          id={name}
          value={data || activeIndex}
          type="hidden"
        />
      </div>
      {error && <ErrorMessage error={error} t={t} />}
    </>
  );
};

export default Switcher;
