import NoteTemplate from '../../components/registration_form/NoteTemplate'
import FormHeaderTemplate from '../../components/form/FormHeaderTemplate';
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../components/header/Breadcrumb";
import ArticleTemplates from "../../components/articles/ArticlesTemplate";
import React from "react";
const PrivacyPolicy = ({ lng }) => {
    const { t } = useTranslation();

    const en = () => (
        <>
            The Government of the Hong Kong Special Administrative Region ("the Government") is concerned to ensure that all personal data submitted through this RVD Web Portal of the Rating and Valuation Department ("the Web Portal") are handled in accordance with the relevant provisions of the <a className='text-surface-primary hover:underline' href='https://www.pcpd.org.hk/english/data_privacy_law/ordinance_at_a_Glance/ordinance.html' target={"_blank"}>
            Personal Data (Privacy) Ordinance (Cap. 486).</a>
            <br/>
            <br/>
            The Government will record visits to the Web Portal without collecting any personal identifiable information of users. Such general statistics are collected for the compilation of statistical reports and the diagnosis of problems with concerning computer systems to help the Government improve the Web Portal.
            <br/>
            <br/>
            For different purposes and at different times you may be invited to provide personal data to the Government through the Web Portal on a voluntary basis. Personal information may include name, telephone number, mailing address or e-mail address. The Government will specify the collection purpose and intended usage of your data when it invites you to provide such information and advise you how you may request access to or correction of the personal data provided.
            <br/>
            <br/>
            Unless permitted or required by law, the Government will not disclose your personal data to any unauthorised third parties without your prior consent.
            <br/>
            <br/>
            The Web Portal uses SSL protocol to encrypt data during network transmission to protect your personal data. All personal data you provide to the Government via the Web Portal are secured, and access to them is restricted to authorised personnel only.
            <br/>
            <br/>
            This Privacy Policy has been translated into Chinese. If there is any inconsistency or ambiguity between the English version and the Chinese version, the English version shall prevail.
        </>
    )

    const tc = () => (
        <>
            香港特別行政區政府（下稱「政府」）會確保所有透過差餉物業估價署電子服務平台 (下稱「本電子服務平台」)遞交的個人資料，均按照《<a className='text-surface-primary hover:underline' href='https://www.pcpd.org.hk/tc_chi/data_privacy_law/ordinance_at_a_Glance/ordinance.html' target={"_blank"}>個人資料 (私隱) 條例</a>》（第486章）的有關條文處理。
            <br/>
            <br/>
            政府會記錄使用者瀏覽本電子服務平台的次數，但並不會收集任何足以辨識使用者身分的資料。所收集的瀏覽次數紀錄只會用於製作統計報告及調查電腦系統問題，以助政府改善本電子服務平台的服務。
            <br/>
            <br/>
            政府可能會於不同期間及為個別原因，邀請你自願提供個人資料。此等資料可能包括姓名、電話號碼、郵寄地址或電郵地址。政府邀請你提供資料時，會列明收集資料的目的和用途，並會告知你如何要求查閱或改正所提供的個人資料。
            <br/>
            <br/>
            除非法律許可或有所規定，政府不會在未得到你的同意下透露你的個人資料予未獲授權的第三者。
            <br/>
            <br/>
            本電子服務平台使用SSL通訊協定傳送網上登記的資料，以確保你的個人資料得到保護。政府會將你於本電子服務平台提供的個人資料保密，只有獲得授權的人士才可查閱。此中文私隱政策為英文版本譯本，如中、英文兩個版本有任何抵觸或不相符之處，應以英文版本為準。
        </>
    )
    const sc = () => (
        <>
            香港特别行政区政府（下称「政府」）会确保所有透过差饷物业估价署电子服务平台(下称「本电子服务平台」)递交的个人资料，均按照《<a className='text-surface-primary hover:underline' href='https://www.pcpd.org.hk/sc_chi/data_privacy_law/ordinance_at_a_Glance/ordinance.html' target={"_blank"}>个人资料(私隐) 条例</a>》（第486章）的有关条文处理。
            <br/>
            <br/>
            政府会记录使用者浏览本电子服务平台的次数，但并不会收集任何足以辨识使用者身分的资料。所收集的浏览次数纪录只会用于制作统计报告及调查电脑系统问题，以助政府改善本电子服务平台的服务。
            <br/>
            <br/>
            政府可能会于不同期间及为个别原因，邀请你自愿提供个人资料。此等资料可能包括姓名、电话号码、邮寄地址或电邮地址。政府邀请你提供资料时，会列明收集资料的目的和用途，并会告知你如何要求查阅或改正所提供的个人资料。
            <br/>
            <br/>
            除非法律许可或有所规定，政府不会在未得到你的同意下透露你的个人资料予未获授权的第三者。
            <br/>
            <br/>
            本电子服务平台使用SSL通讯协定传送网上登记的资料，以确保你的个人资料得到保护。政府会将你于本电子服务平台提供的个人资料保密，只有获得授权的人士才可查阅。此中文私隐政策为英文版本译本，如中、英文两个版本有任何抵触或不相符之处，应以英文版本为准。
        </>
    )

    return (
        <>
            <Breadcrumb
                lng={lng}
            />
            <ArticleTemplates subtitle='' title={t('Privacy Policy')}>
                <div className='bg-surface-card p-md md:p-lg rounded-big mt-x-big w-full'>
                    {lng === 'tc' ? tc() : lng === 'sc' ? sc() : en()}
                </div>
            </ArticleTemplates>
        </>
    )
}

export default PrivacyPolicy;