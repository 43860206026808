import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Pagination from "../table/Pagination";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { ReactComponent as SortingSVG } from "../../assets/images/arrow/sorting.svg";
import { tableStyles } from "./tableStyles";
import SubmitAndFetch from "../function/SubmitAndFetch";

const DataTable = ({
                     columns,
                     data,
                     pageSize = 20,
                     pageIndex = 0,
                     pageSizeOptions = [20, 20],
                     setResetPagination,
                     resetPagination,
                     enablePagination = true,
                     needMargin = false,
                     enableSelection = false,
                     multi = true,
                     selectAll = false,
                     setRowSelection,
                     disableSelection,
                     rowSelection,
                     emptyMessage,
                     customLoading,
                     setCustomLoading,
                     showFooter = true,
                     border = true,
                     apiProps,
                     enableSelectionCheckPermission = false,
                     enablePageSizeOptions = true,
                     isServerSide = true,
                     selectionLimited = null
                   }) => {
  const [cookies] = useCookies(["access_token"]);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const token = cookies["access_token"];
  const [isShowAllSelected, setIsShowAllSelected] = useState(false);
  const { postForm, loading } = SubmitAndFetch({ lng: language, t });
  const [sorting, setSorting] = useState(apiProps?.DefaultSorting || []);
  const [newData, setNewData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageIndex,
    pageSize,
  });

  const tableData = useMemo(
      () =>
          data && Object.entries(data).length ? data : apiProps?.data?.list || [],
      [data, apiProps?.data?.list]
  );
  const totalRows = isServerSide ? apiProps?.data?.total : tableData.length;
  const totalPages = isServerSide ? apiProps?.data?.pages : Math.ceil(tableData.length / paginationModel.pageSize);

    useEffect(() => {
        if (resetPagination) {
            setPaginationModel((prevModel) => ({
                ...prevModel,
                pageIndex: 0,
            }));
        }
    }, [resetPagination]);

  const useDataFetching = (
      apiProps,
      sorting,
      paginationModel,
      columns,
      token,
      isServerSide
  ) => {
    const fetchData = useCallback(async () => {
      if (!isServerSide) return;
      if (setCustomLoading) {
        setCustomLoading(true);
      }

      if (!apiProps?.api) return;

        const formDataObject = {};

        if (apiProps?.appandData) {
            Object.entries(apiProps?.appandData).forEach(([key, value]) => {
                formDataObject[key] = value;
            });
        }

        if (sorting.length) {
            const sortByArray = sorting.map((item) => {
                const column = columns.find((col) => col.accessorKey === item.id);
                return { [column.objKey]: item.desc ? "desc" : "asc" };
            });
            formDataObject.sort_by = sortByArray;
        }

        formDataObject.page_size = paginationModel.pageSize;
        formDataObject.page_num = resetPagination ? 0 : paginationModel.pageIndex + 1;

      await postForm(
          apiProps?.api,
          null,
          formDataObject,
          apiProps?.setError ? apiProps?.setError : null,
          null,
          null,
          updatePaginationModel,
          token,
          apiProps?.setState,
          setCustomLoading,
          false,
          false,
          () => apiProps?.setState({})
      );
      if (apiProps?.setIsFetching) apiProps?.setIsFetching(false);
      if (setCustomLoading) setCustomLoading(false);
      if (setResetPagination) setResetPagination(false);
    }, [
      apiProps,
      sorting,
      paginationModel,
      columns,
      token,
      postForm,
      apiProps?.isFetching,
      isServerSide,
    ]);

    return { fetchData };
  };

  const updatePaginationModel = (resData) => {
    setPaginationModel({
      pageIndex: resData?.pageNum - 1,
      pageSize: resData?.pageSize
    })
  }

  const getTableOptions = useCallback(
      (props) => {
        return {
          columns: props.columns,
          enableMultiSort: true,
          isMultiSortEvent: () => true,
          data: props.tableData,
          onSortingChange: (sorting) => {
            props?.setSorting(sorting);
            if (props.isServerSide) {
              apiProps?.setIsFetching(true);
            }
          },
          icons: {
            SortIcon: (props) => <SortingSVG {...props} />,
          },
          getSubRows: (row) => row.children,
          muiPaginationProps: {
            showRowsPerPage: false,
            showFirstButton: false,
            showLastButton: false,
            labelDisplayedRows: ({ from, to, count }) =>
                `Showing ${from}-${to} of ${count}`,
          },
          paginationDisplayMode: "custom",
          initialState: {
            columnVisibility: {
              "mrt-row-expand": false,
              ...(props.apiProps?.columnVisibility || {}),
            },
            expanded: props.tableData?.reduce((acc, row) => {
              if (row?.rowType === "parent") {
                acc[row.id] = true;
              }
              return acc;
            }, {}),
          },
          displayColumnDefOptions: {
            "mrt-row-select": {
              header: "",
            },
          },
          ...tableStyles,
          enableTopToolbar: false,
          enableColumnActions: false,
          enablePagination: props.enablePagination,
          enableExpanding: true,
          enableExpandAll: false,
          layoutMode: "grid",
          enableHiding: true,
          enableMultiRowSelection: props.multi,
          enableRowSelection: props.enableSelection
              ? (row) => {
                const baseCondition = props.enableSelectionCheckPermission
                    ? props.selectionCheckPermission(row)
                    : row.original.rowType !== "parent" && props.enableSelection;

                return baseCondition;
              }
              : false,
            muiSelectAllCheckboxProps: ({ rows }) => {
                const baseProps = {
                    sx: {
                        color: "var(--border-default)",
                        "&.Mui-checked, &.MuiCheckbox-indeterminate": {
                            color: "var(--surface-primary)",
                        },
                        padding: "0",
                        paddingBottom: "0.6rem",
                    }
                };

                if (!props?.selectionLimited) {
                    return baseProps;
                }

                return {
                    ...baseProps,
                    checked: isShowAllSelected,
                    onChange: (e) => {
                        const isChecked = !isShowAllSelected;
                        setIsShowAllSelected(isChecked);
                        console.log('isChecked', isChecked);
                        console.log('e.target.checked', e.target.checked);
                        if (isChecked) {
                            const limitedSelection = props.tableData.slice(0, props.selectionLimited).reduce((acc, row) => {
                                acc[row.id] = true;
                                return acc;
                            }, {});
                            props.setRowSelection(limitedSelection);
                        } else {
                            props.setRowSelection({});
                        }
                    }
                };
            },
          muiSelectCheckboxProps: ({ row }) => {
            const isSelected = row.getIsSelected();
            return {
              disabled:
                  (!isSelected && props.disableSelection) ||
                  (props.enableSelectionCheckPermission &&
                      !props.selectionCheckPermission(row)),
              sx: {
                color: "var(--border-default)",
                "&.Mui-checked": {
                  color: "var(--surface-primary)",
                },
                "&.Mui-disabled": {
                  display: "none",
                },
                padding: "0",
              },
            };
          },
          enableSelectAll: props.selectAll,
          enableSubRowSelection: false,
          enableColumnPinning: true,
          onRowSelectionChange: props.enableSelection
              ? props.setRowSelection
              : undefined,
          getRowId: props.enableSelection ? (row) => row.id : undefined,
          state: {
            isLoading: props.customLoading || props.loading || false,
            rowSelection: props.enableSelection ? props.rowSelection : {},
            sorting: props.sorting,
            pagination: paginationModel,
          },
          enableSorting: true,
          renderEmptyRowsFallback: () => (
              <div className="label1 text-title p-md">
                {props.emptyMessage || t("No records")}
              </div>
          ),
          renderBottomToolbar: () =>
              props.showFooter && props.customBottomToolBar(),
          manualPagination: props.isServerSide,
          manualSorting: props.isServerSide,
          enableGlobalFilter: !props.isServerSide,
        };
      },
      [tableData, paginationModel, isShowAllSelected]
  );

  const { fetchData } = useDataFetching(
      apiProps,
      sorting,
      paginationModel,
      columns,
      token,
      isServerSide
  );

  useEffect(() => {
    if (apiProps?.isFetching) fetchData();
  }, [apiProps?.isFetching]);

  const customBottomToolBar = useCallback(
      () => (
          <Pagination
              paginationModel={paginationModel}
              enablePageSizeOptions={enablePageSizeOptions}
              totalRows={totalRows}
              pageSizeOptions={enablePageSizeOptions ? pageSizeOptions : null}
              setPaginationModel={setPaginationModel}
              totalPages={totalPages}
              isServerSide={isServerSide}
              setIsRefetching={apiProps?.setIsFetching}
              setResetPagination={setResetPagination}
              resetPagination={resetPagination}
          />
      ),
      [
        enablePageSizeOptions,
        paginationModel,
        totalRows,
        pageSizeOptions,
        totalPages,
        isServerSide,
        setPaginationModel,
        apiProps,
      ]
  );

  const selectionCheckPermission = useCallback(
      (row) => {
        if (row?.original?.isShared) return false;
        if (row?.original?.categoryIds && row?.original?.permissions) {
          const id = apiProps?.id;
          const cateArray = row.original.categoryIds.split(",");
          const permissionArray = row.original.permissions.split(",");
          return !permissionArray.some(
              (item, index) =>
                  item !== "1" &&
                  item !== "4" &&
                  item !== "0" &&
                  id === cateArray[index]
          );
        }
        return false;
      },
      [apiProps?.id]
  );

  const table = useMaterialReactTable(
      getTableOptions({
        columns,
        tableData,
        loading,
        sorting,
        setSorting,
        enablePagination,
        needMargin,
        enableSelection,
        disableSelection,
        multi,
        selectAll,
        setRowSelection,
        rowSelection,
        setCustomLoading,
        customLoading,
        emptyMessage,
        showFooter,
        border,
        apiProps,
        enableSelectionCheckPermission,
        selectionCheckPermission,
        customBottomToolBar,
        isServerSide,
        selectionLimited,
      })
  );

  return (
      <MaterialReactTable
          table={table}
          getRowCanExpand={(row) => row.original.rowType !== "parent"}
          onPaginationChange={setPaginationModel}
      />
  );
};

export default DataTable;