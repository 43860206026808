import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useRemoveError = (setError) => {
    const { i18n: {language} } = useTranslation();

    useEffect(() => {
        if (setError) setError({});
    }, [language]);
};

export default useRemoveError;