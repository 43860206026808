import React, { useEffect, useMemo, useState } from "react";
import PropertyTemplate from "../../components/property/Template";
import PropertyDataTableTemplate from "../../components/property/PropertyDataTableTemplate";
import BasicTag from "../../components/tags/BasicTag";
import LoadingUI from "../../components/loading/LoadingUI";
import ModelMenu from "../../components/menu/ModelMenu";
import { ReactComponent as DownArrow } from "../../assets/images/arrow/down_arrow.svg";
import Popup from "../../components/popup/Popup";
import ProfileSection from "../../components/my-profile/ProfileSection";
import { useTranslation } from "react-i18next";
import Input from "../../components/input/Input";
import DataTable from "../../components/table/DataTable";
import CloseIcon from "@mui/icons-material/Close";
import CateSelect2 from "../../components/input/portal/CateSelect2";
import UploadPopup from "../../components/popup/UploadPopup";
import Breadcrumb from "../../components/header/Breadcrumb";
import WhiteStatueSelect from "../../components/input/portal/WhiteStatueSelect";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import Swal from "sweetalert2";
import {
  AddButton,
  UploadButton,
  EditButton,
  ProceedButton,
} from "../../components/button/SingleButton";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { useParams, useNavigate } from "react-router-dom";
import searchBarField from "../../data/search_bar_field.json";
import ErrorMessage from "../../components/error/ErrorMessage";
import Snackbar from "@mui/material/Snackbar";
import Grow from "@mui/material/Grow";
import UPLOAD_DOC_TYPE from "../../data/variable/upload_doc_type.json";
import USER_TYPE from "../../data/variable/user_type.json";
import PERMISSIONS from "../../data/variable/property_permission.json";
import SmallFunction from "../../components/function/SmallFunction";

const defaultApi = "property/list";

const no_permission = PERMISSIONS.NO_PERMISSION;
const full_access = PERMISSIONS.FULL_ACCESS;
const share = PERMISSIONS.SHARE;
const editable = PERMISSIONS.EDITABLE;
const view_only = PERMISSIONS.VIEW_ONLY;
const restricted = PERMISSIONS.RESTRICTED;
const shared = PERMISSIONS.SHARED;



const ViewProperty = ({ lng }) => {
  const { t } = useTranslation();
  const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
  const { id } = useParams();
  const navigate = useNavigate();
  const { userLoading, admin, token, account_type } = useUserLoginContext();
  const { postForm } = SubmitAndFetch({ lng, t });
  const [cateName, setCateName] = useState("");
  const [rowData, setRowData] = useState([]);
  const [saveAllrowData, setSaveAllrowData] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);
  const [disabledProceed, setDisabledProceed] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const { checkAndChangeUploadCate } = SmallFunction();

  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [showChangeCategory, setShowChangeCategory] = useState(false);
  const [showRemoveCurrent, setShowRemoveCurrent] = useState(false);
  const [showRemoveAll, setShowRemoveAll] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showChangeAsLet, setShowChangeAsLet] = useState(false);
  const [showUploadProperty, setShowUploadProperty] = useState(false);
  const [showUploadRent, setShowUploadRent] = useState(false);
  const [state, setState] = React.useState({
    open: false,
  });

  const [currentCategories, setCurrentCategories] = useState([]);
  const [selectStatus, setSelectStatus] = useState({});
  const [error, setError] = useState([]);
  const [errorState, setErrorState] = useState({});
  const [resetPagination, setResetPagination] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadFile] = useState([]);
  const [selectCate, setSelectCate] = useState(id);
  const [selectUploadCate, setSelectUploadCate] = useState("");
  const [filterOption, setFilterOption] = useState({});
  const [searchData, setSearchData] = useState(searchBarField);
  const [preSearchData, setPreSearchData] = useState({});

  useEffect(() => {
    const getFilterData = async () => {
      await postForm(
        `${defaultApi}/filter`,
        null,
        null,
        setError,
        null,
        false,
        handleGetFilterData,
        token,
        null,
        null,
        null,
        false
      );
    };
    getFilterData();
    // setIsFetching(true)
  }, [lng]);


  useEffect(() => {
    setDisabledProceed(Object.keys(rowSelection).length === 0);
  }, [rowSelection]);

  useEffect(() => {
    if (!showUploadProperty) {
      setUploadFile([]);
    }
  }, [showUploadProperty]);

  useEffect(() => {
    document.title = `${t("web_portal_title_text")} - ${t("Category")} | ${cateName}`;
  }, [cateName]);

  useEffect(() => {
    if (rowData.list) {
      setSaveAllrowData((prevState) => {
        const newData = rowData.list.filter(
          (newItem) =>
            !prevState.some((existingItem) => existingItem.id === newItem.id)
        );
        return [...prevState, ...newData];
      });
    }
  }, [rowData]);

  useEffect(() => {
    if (filterOption?.assignCategoryList && id !== undefined) {
      changeUploadCate(id);
    }
  }, [filterOption]);

  const changeUploadCate = (currentCateId) => {
    checkAndChangeUploadCate(
      currentCateId,
      setSelectUploadCate,
      filterOption?.assignCategoryList
    );
  };

  const handleGetFilterData = (data) => {
    setFilterOption(data);
    setCateName(data.categoryList.find((cate) => cate.id === id)?.name || "");
  };

  const closeAllPopup = () => {
    if (showDeleteAll || showRemoveAll || showRemoveCurrent)
      setRowSelection({});
    setShowChangeStatus(false);
    setShowChangeAsLet(false);
    setShowChangeCategory(false);
    setShowRemoveAll(false);
    setShowRemoveCurrent(false);
    setShowDeleteAll(false);
    setErrorState({});
  };

  const handleSuccess = (data) => {
    closeAllPopup();
    setIsFetching(true);
  };

  const getFileResData = (uploadedFile) => {
    const fileKey = Object.keys(uploadedFile)[0];
    const file = uploadedFile[fileKey];
    return uploadedFile[fileKey].resData;
  };

  const handlePopupSubmit = async (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);
    if (showUploadProperty) {
      navigate(
        `/${lng}/property-list/view-property/add/${selectUploadCate || 0}`,
        {
          state: {
            uploadData: getFileResData(uploadedFile["upload_property_file"]),
          },
        }
      );
    } else {
      if (showRemoveCurrent) {
        formData = {
          list: Object.keys(rowSelection).map((ids) => ({
            id: ids,
            category_ids: id,
          })),
        };
      } else {
        formData.append("ids", Object.keys(rowSelection).join(","));
      }

      const showFailAlert =
        showChangeCategory ||
        showRemoveCurrent ||
        showRemoveAll ||
        showDeleteAll;
      const api_name = showChangeStatus
        ? `${defaultApi}/update/status`
        : showChangeAsLet
        ? `${defaultApi}/update/rent`
        : showChangeCategory
        ? `${defaultApi}/assign/category`
        : showRemoveCurrent
        ? `${defaultApi}/remove/category`
        : showRemoveAll
        ? `${defaultApi}/remove/allcategory`
        : showDeleteAll
        ? `${defaultApi}/delete`
        : "";

      await postForm(
        api_name,
        null,
        formData,
        setError,
        null,
        false,
        handleSuccess,
        token,
        setErrorState,
        setLoading,
        true,
        showFailAlert,
      );
    }
  };

  const updateFields = (fields, setState) =>
    setState((prev) => ({ ...prev, ...fields }));
  const updateUploadData = (fields) => updateFields(fields, setUploadFile);
  const updatePreSearchData = (fields) => updateFields(fields, setPreSearchData);
  const updateSearchData = (fields) => updateFields(fields, setSearchData);

  const changeCateAction = (event) => {
    const newCateId = event.target.value || "0";
    setSelectCate(newCateId);
    changeUploadCate(newCateId);
    navigate(`/${lng}/property-list/view-property/${newCateId}`);
    setResetPagination(true)
    setIsFetching(true);
    setSearchData({
      ...searchBarField,
      is_include_sub_category: searchData.is_include_sub_category,
    });
    setSelectStatus({});
    setRowSelection({});
    setCateName(
      filterOption.categoryList.find((cate) => cate.id === newCateId)?.name
    );
  };

  const stateProps = {
    currentCategories,
    setCurrentCategories,
    filterOption,
    setResetPagination,
    resetPagination,
    // cateListOption: filterOption?.assignCategoryList,
    setIsFetching,
    lang,
    searchData,
    preSearchData,
    setError,
    error,
    setSearchData,
    setPreSearchData,
    action: (event) => setIsFetching(true),
    changeCateAction,
    updateFields: updateSearchData,
    updatePreFields: updatePreSearchData,
  };

  const PopupProps = (setState) => ({
    id: rowSelection,
    setState,
    t,
    apiProps: {
      setError,
      lng,
      token,
      customSubmit: handlePopupSubmit,
      setErrorState,
      loading,
    },
  });
  const uploadPopupProps = (
    name,
    showUpload,
    setShowUpload,
    UploadContextProps,
    DropzoneProps,
    apiProps,
    SelectCateProps
  ) => ({
    name,
    uploadedFile,
    showUpload,
    setShowUpload,
    stateProps,
    apiProps,
    PopupProps,
    updateFields: updateUploadData,
    UploadContextProps,
    DropzoneProps,
    SelectCateProps,
    t,
    lng,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "assessmentNo",
        header: t("Assessment No."),
        minSize: 210,
        objKey: "assessment_number",
        grow: false,
      },
      {
        accessorKey: "address",
        header: t("Address or Description of Tenement"),
        objKey: "address_or_desc_of_tenement",
        size: 400,
        enableSorting: false,
        grow: false,
        Cell: ({ cell }) => {
          return <div className="pe-big">{cell.getValue()}</div>;
        },
      },
      // {
      //     accessorKey: 'proposal_submission',
      //     header: 'Proposal Submission',
      //     objKey: 'proposal_submission',
      //     maxSize: 140,
      //     enableSorting: false,
      //     grow: false,
      //     Cell: ({ cell, row }) => (
      //         <div className="flex w-full">
      //             {cell.getValue() ? <YesSVG width={24} /> : <NoSVG width={24} />}
      //         </div>
      //     ),
      // },
      // {
      //   accessorKey: "changeRv",
      //   header: t("% Change in RV"),
      //   objKey: "change_of_rv",
      //   minSize: 180,
      //   grow: false,
      //   Cell: ({ cell }) => {
      //     const value = cell.getValue();
      //     const numericValue = value ? parseFloat(value) : null;
      //     if (numericValue) {
      //       if (numericValue >= 0) {
      //         return <span className="text-red-600">{value}</span>;
      //       }
      //       if (numericValue < 0) {
      //         return <span className="text-green-600">{value}</span>;
      //       }
      //     } else {
      //       return <span className="text-neutral-600">N/A</span>;
      //     }
      //   },
      // },
      {
        accessorKey: "statusOccupation",
        header: t("Status"),
        objKey: "status_occupation",
        minSize: 280,
        grow: true,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          let occupation = null;
          let physical = null;

          if (cell.getValue()) {
            occupation = cell.getValue();
          }

          if (row.original.statusPhysical) {
            physical = row.original.statusPhysical;
          }

          if (occupation || physical) {
            return (
              <div className="justify-between items-center flex me-10px w-full gap-md">
                <div className="gap-xx-sm flex-wrap flex">
                  {occupation && (
                    <span className="body2">
                      <BasicTag
                        key={occupation.value}
                        label={occupation?.[`label${lang}`]}
                        color={occupation.color}
                      />
                    </span>
                  )}
                  {physical && (
                    <span className="body2">
                      <BasicTag
                        key={physical.value}
                        label={physical?.[`label${lang}`]}
                        color={physical.color}
                      />
                    </span>
                  )}
                </div>
              </div>
            );
          } else {
            return "-";
          }
        },
      },
      {
        accessorKey: "button",
        header: "",
        maxSize: 104,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => (
          <EditButton
            t={t}
            href={`/${lng}/property-list/view-property/edit/${row.original.id}`}
            stateObj={{ cid: selectCate, cateName: cateName ?? "" }}
          />
        ),
      },
      {
        accessorKey: "categoryIds",
        header: "",
        minSize: 0,
        grow: false,
        enableSorting: false,
        hide: true,
      },
      {
        accessorKey: "permissions",
        header: "",
        minSize: 0,
        grow: false,
        enableSorting: false,
        hide: true,
      },
    ],
    [selectCate, cateName, lng]
  );

  const selectedRows = useMemo(
    () =>
      Object.keys(rowSelection).map((key) =>
        saveAllrowData.find((row) => row.id == key)
      ),
    [rowSelection, saveAllrowData]
  );

  const selectRowcolumns = useMemo(
    () => [
      {
        accessorKey: "assessmentNo",
        header: t("Assessment No."),
        minSize: 210,
        grow: false,
        Header: ({ column }) => (
          <div className="ps-md">{column.columnDef.header}</div>
        ),
        Cell: ({ cell, row }) => <div className="ps-md">{cell.getValue()}</div>,
      },
      {
        accessorKey: "address",
        header: t("Address or Description of Tenement"),
        minSize: 360,
        enableSorting: false,
        grow: false,
      },
      // {
      //   accessorKey: "changeRv",
      //   header: t("% Change in RV"),
      //   minSize: 140,
      //   grow: true,
      //   Cell: ({ cell }) => {
      //     const value = cell.getValue();
      //     const numericValue = value ? parseFloat(value) : null;
      //     if (numericValue) {
      //       if (numericValue >= 0) {
      //         return <span className="text-red-600">{value}</span>;
      //       }
      //       if (numericValue < 0) {
      //         return <span className="text-green-600">{value}</span>;
      //       }
      //     } else {
      //       return <span className="text-neutral-600">-</span>;
      //     }
      //   },
      // },
      {
        accessorKey: "statusOccupation",
        header: t("Status"),
        minSize: 250,
        grow: true,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          let occupation = null;
          let physical = null;

          if (cell.getValue()) {
            occupation = cell.getValue();
          }

          if (row.original.statusPhysical) {
            physical = row.original.statusPhysical;
          }

          if (occupation || physical) {
            return (
              <div className="justify-between items-center flex me-10px w-full gap-md">
                <div className="gap-xx-sm flex-wrap flex">
                  {occupation && (
                    <span className="body2">
                      <BasicTag
                        key={occupation.value}
                        label={occupation?.[`label${lang}`]}
                        color={occupation.color}
                      />
                    </span>
                  )}
                  {physical && (
                    <span className="body2">
                      <BasicTag
                        key={physical.value}
                        label={physical?.[`label${lang}`]}
                        color={physical.color}
                      />
                    </span>
                  )}
                </div>
              </div>
            );
          } else {
            return "-";
          }
        },
      },
      {
        accessorKey: "selectButton",
        header: "",
        maxSize: 94,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <div
              className="flex justify-center w-full"
              title={t("Remove from list")}
              onClick={() => removeSelectedRow(row.original.id)}
            >
              <div className="bg-surface-primary rounded-full hover:bg-surface-primary-hover flex justify-center items-center w-x-big h-x-big p-sm cursor-pointer">
                <CloseIcon className="text-white" fontSize="inherit" />
              </div>
            </div>
          );
        },
      },
    ],
    [lng]
  );

  const removeSelectedRow = (id) => {
    setRowSelection((prevRowSelection) => {
      const newRowSelection = { ...prevRowSelection };
      delete newRowSelection[id];
      return newRowSelection;
    });
  };

  const getInvalidCategoriesString = (ids) => {
    return ids
      .map((id) => {
        const cat = filterOption?.categoryList?.find((cat) => cat.id === id);
        return cat ? cat.name : null;
      })
      .filter((name) => name)
      .join(", ");
  };


  const changeAsLetPopup = () => {
    return (
      <Popup
        {...PopupProps(setShowChangeAsLet)}
        sx={{ padding: "var(--spacing-xx-lg) var(--spacing-xxx-lg)" }}
        title="Change as Let"
        bottomSticky={
          <div className="flex flex-col gap-big">
            <ProfileSection title="Monthly Rent" t={t} />
            <Input
              inputLabel="Monthly Rent (HKD)"
              name="rent_monthly"
              error={error["rent_monthly"]}
              t={t}
              setError={setError}
            />
          </div>
        }
      >
        <div className="flex-1 overflow-y-auto gap-xxx-big custom-scrollbar">
          <div className="custom-scrollbar overflow-y-auto">
            <DataTable
              data={selectedRows}
              columns={selectRowcolumns}
              showFooter={false}
            />
          </div>
        </div>
        <div className="label1 text-body py-big px-md md:px-x-lg w-full text-end mt-sm border-t border-default">
          {t("Total Property: ") + Object.keys(rowSelection).length}
        </div>
        {error && <ErrorMessage error={error.message} t={t} />}
        {Object.entries(errorState).length && errorState?.invalidIds.length ? (
          <span className="caption2 text-warning">
            invalid Properties:{" "}
            {getInvalidCategoriesString(errorState.invalidIds)}
          </span>
        ) : null}
      </Popup>
    );
  };

  const changeStatusPopup = () => {
    let status_occupation = "";
    let status_physical = "";
    if (Object.keys(rowSelection).length === 1) {
      status_occupation = selectedRows?.[0]?.statusOccupation?.value;
      status_physical = selectedRows?.[0]?.statusPhysical?.value;
    }

    return (
      <Popup {...PopupProps(setShowChangeStatus)} title="Change Status">
        <div className="flex flex-col gap-xxx-big">
          <WhiteStatueSelect
            props={{ ...stateProps, data: status_occupation }}
            name="status_occupation"
            enableCheckbox={true}
          />
          <WhiteStatueSelect
            props={{ ...stateProps, data: status_physical }}
            name="status_physical"
            enableCheckbox={true}
          />
        </div>
        {error && <ErrorMessage error={error.message} t={t} />}
        {Object.entries(errorState).length && errorState?.invalidIds.length ? (
          <span className="caption2 text-warning">
            invalid Properties:{" "}
            {getInvalidCategoriesString(errorState.invalidIds)}
          </span>
        ) : null}
      </Popup>
    );
  };

  const changeCategoryPopup = () => {
    return (
      <Popup {...PopupProps(setShowChangeCategory)} title="Assign Category">
        <CateSelect2
          name="category_ids"
          multiple={true}
          categories_options={filterOption?.assignCategoryList || []}
        />
        {error && <ErrorMessage error={error.message} t={t} />}
        {Object.entries(errorState).length && errorState?.invalidIds.length ? (
          <span className="caption2 text-warning">
            invalid Properties:{" "}
            {getInvalidCategoriesString(errorState.invalidIds)}
          </span>
        ) : null}
        {Object.entries(errorState).length &&
        errorState?.invalidCategoryIds.length ? (
          <span className="caption2 text-warning">
            invalid Categories:{" "}
            {getInvalidCategoriesString(errorState.invalidCategoryIds)}
          </span>
        ) : null}
      </Popup>
    );
  };

  const RemoveCurrentPopup = () => {
    const text = (
      <div>
        {t("Are you sure you want to remove")}{" "}
        <span style={{ fontWeight: "700" }}>
          {Object.keys(rowSelection).length}
        </span>{" "}
        {t("properties from the current category")} -{" "}
        <span style={{ fontWeight: "700" }}>{cateName}</span>.
      </div>
    );
    return (
      <Popup
        {...PopupProps(setShowRemoveCurrent)}
        name="category_ids"
        showBtn={false}
        confirm_popup={true}
        title={t("Confirm to remove from current category?")}
        text={text}
      />
    );
  };

  const RemoveAllPopup = () => {
    const text = (
      <div>
        {t("Are you sure you want to remove")}{" "}
        <span style={{ fontWeight: "700" }}>
          {Object.keys(rowSelection).length}
        </span>{" "}
        {t("properties from all categories.")}
      </div>
    );
    return (
      <Popup
        {...PopupProps(setShowRemoveAll)}
        showBtn={false}
        confirm_popup={true}
        title={t("Confirm to remove from all category?")}
        text={text}
      />
    );
  };

  const DeleteAllPopup = () => {
    const text = (
      <div>
        {t("Are you sure you want to remove")}{" "}
        <span style={{ fontWeight: "700" }}>
          {Object.keys(rowSelection).length}
        </span>{" "}
        {t("properties from your list.")}
      </div>
    );
    return (
      <Popup
        {...PopupProps(setShowDeleteAll)}
        showBtn={false}
        confirm_popup={true}
        title={t("Confirm to delete property?")}
        text={text}
      />
    );
  };

  //     SelectCateProps, //name, cateId, setSelectCate
  //     UploadContextProps, //title, subtitle,downloadText,downloadPath,
  //     DropzoneProps, //progressBar, numberOfUploading, fileSize, type
  const uploadPropertyPopup = () => {
    let appendFiles = [];
    if (uploadedFile["upload_property_file"]) {
      appendFiles = Object.entries(uploadedFile["upload_property_file"]).map(
        ([key, value]) => {
          return value;
        }
      );
    }
    return (
      <UploadPopup
        {...uploadPopupProps(
          "upload_property_file",
          showUploadProperty,
          setShowUploadProperty,
          {
            title: t("Upload Property List"),
            subtitle: (
              <>
                {t('Maximum number of properties of one CSV file can be uploaded is 2,000.')}
                <br />
                {t('Each time can assign properties into one category only.')}
              </>
            ),
            downloadText: t("Download Property List Template"),
          },
          {
            progressBar: true,
            type: UPLOAD_DOC_TYPE.PROPERTY_LIST,
          },
          {
            api_name: "property/import",
            setError,
            error,
            appandData: {
              category_id: selectUploadCate,
              // file: appendFiles[0],
            },
            withFile: true,
            downloadTemplateAPI: "property/import/template",
            downloadInvalidListAPI: "property/import/invalid",
            customSubmit: handlePopupSubmit,
          },
          {
            name: "category_id",
            cateId: selectUploadCate,
            setSelectCate: setSelectUploadCate,
            cateListOption: filterOption?.assignCategoryList || [],
          }
        )}
      />
    );
  };

  const uploadRentPopup = () => {
    return (
      <UploadPopup
        {...uploadPopupProps(
          "upload_rent_file",
          showUploadRent,
          setShowUploadRent,
          {
            title: t("Upload Rental Information"),
            subtitle:
            t("You can utilise the rental Information excel template to provide supplementary information for your properties. Support maximum 2,000 properties for each file."),
            downloadText: t("Download Rental Information Excel Template"),
          },
          {
            progressBar: true,

            type: UPLOAD_DOC_TYPE.RENTAL_INFORMATION,
          },
          {
            api_name: "property/vv",
            setError,
            error,
            appandData: {
              category_id: selectCate,
            },

            customSubmit: handlePopupSubmit,
          }
        )}
      />
    );
  };

  function GrowTransition(props) {
    return <Grow {...props} />;
  }

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const SubmitToProposal = async (event) => {
    const handleProposalSubmission = (resData) => {
      setState({
        open: true,
        Transition: GrowTransition,
      });
    };

    const handleProposalSubmissionFail = (resData) => {
      if (
        resData?.code === 202 &&
        resData?.data?.invalidIds?.length &&
        resData?.message
      ) {
        Swal.fire({
          html: `<p>${resData?.message}: ${resData?.data?.invalidIds}, ${t("please remove them before proceeding.")}</p>`,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("Ok"),
          customClass: { confirmButton: "primary-btn" },
          timer: 5000,
        });
      }
    };

    const selectedIds = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .join(",");

    const selectedCategoryParentIds = filterOption?.categoryList.find(
      (item) => item.id === selectCate
    )?.parentId;

    const formData = new FormData();
    formData.append("category_id", selectedCategoryParentIds);
    formData.append("ids", selectedIds);

    await postForm(
      `${defaultApi}/add-to-proposal`,
      null,
      formData,
      setError,
      null,
      false,
      handleProposalSubmission,
      token,
      null,
      null,
      false,
      true,
      handleProposalSubmissionFail
    );
  };

  const checkPermission = (row, currentCategoryId, requiredPermissions, menuActionValue) => {
    const categoryIds = row.categoryIds.split(",");
    const permissions = row.permissions.split(",");

    console.log('currentCategoryId', currentCategoryId)
    console.log('row', row)
    const getPermission = (permission) => {
      if (menuActionValue === 6) {
        if (account_type === USER_TYPE.ADMIN) {
          return true
        } else {
          return false
        }
      } else {
        if (Array.isArray(requiredPermissions)) {
          return requiredPermissions.includes(Number(permission));
        } else {
          return Number(permission) === requiredPermissions;
        }
      }
    };

    const index = categoryIds.indexOf(String(row.categoryIds));
    const Pindex = categoryIds.indexOf(String(currentCategoryId));
    const Uncateindex = categoryIds.indexOf('0');

    if (Uncateindex !== -1) {
      return !(menuActionValue === 5 || menuActionValue === 6);
    }

    if ((currentCategoryId !== row.categoryIds) && (index !== -1 || Pindex !== -1)) {
      return !(menuActionValue === 5);
    } else if (index !== -1 || Pindex !== -1) {
      return getPermission(permissions[index]);
    } else {
      for (let i = 0; i < categoryIds.length; i++) {
        const parentId = filterOption?.categoryList.find(
          (item) => item.id === categoryIds[i]
        )?.parentId;
        if (parentId === currentCategoryId) {
          return getPermission(permissions[i]);
        }
      }
    }

    return false;
  };

  const hasPermission = (requiredPermissions, menuActionValue) => {
    if (Object.keys(rowSelection).length === 0) return false;
    return Object.keys(rowSelection).every((id) =>
      checkPermission(
        saveAllrowData.find((row) => row.id === id),
        selectCate,
        requiredPermissions,
        menuActionValue
      )
    );
  };

  const ProceedMenu = [
    {
      value: 1,
      action: SubmitToProposal,
      label: "Add to Proposal Submission",
      show: id !== '0' && hasPermission([full_access, editable, view_only], 1),
    },
    {
      value: 2,
      action: () => setShowChangeStatus(true),
      label: "Change Status",
      show: id === '0' || hasPermission([full_access, editable], 2),
    },
    {
      value: 3,
      action: () => setShowChangeAsLet(true),
      label: "Change as Let",
      show: id === '0' || hasPermission([full_access, editable], 3),
    },
    {
      value: 4,
      action: () => setShowChangeCategory(true),
      label: "Assign Category",
      show: id === '0' || hasPermission([full_access, editable], 4),
    },
    {
      value: 5,
      action: () => setShowRemoveCurrent(true),
      label: "Remove from Current Category",
      show: id !== '0' && hasPermission([full_access, editable], 5),
    },
    {
      value: 6,
      action: () => setShowRemoveAll(true),
      label: "Remove from All Categories",
      show: (admin || account_type === USER_TYPE.INDIVIDUAL) && id !== '0' && hasPermission([], 6),
    },
    {
      value: 7,
      action: () => setShowDeleteAll(true),
      label: "Delete",
      show: admin || account_type === USER_TYPE.INDIVIDUAL || id === '0',
    },
  ];

  const uploadOption = [
    {
      value: 1,
      action: () => setShowUploadProperty(true),
      label: "Property List",
    },
    {
      value: 2,
      action: () => setShowUploadRent(true),
      label: "Rental Information",
      disabled: true,
    },
  ];

  const buttonBar = () => (
    <div className="flex flex-col sm:flex-row gap-md">
      <div className="flex gap-md items-center">
        <div className="w-fit">
          <AddButton
            t={t}
            href={`/${lng}/property-list/view-property/add/${id}`}
            stateObj={{ parentCateName: cateName, parentId: id }}
            buttonClassNames="mt-0"
            label={'Add Property'}
          />
        </div>
        <ModelMenu
          options={uploadOption}
          buttons={<UploadButton t={t} outline={true} selectIcon={true} />}
        />
      </div>
      <ModelMenu
        buttonsChild={<ProceedButton disabled={disabledProceed} t={t}/>}
        disabled={disabledProceed}
        options={ProceedMenu}
        selected={rowSelection}
      />
    </div>
  );

  const tableDataProps = {
    columns,
    data: rowData.list,
    enableSelection: true,
    enableSelectionCheckPermission: false,
    setRowSelection,
    rowSelection,
    setResetPagination,
    resetPagination,
    selectAll: true,
    apiProps: {
      setError,
      error,
      id,
      isFetching,
      setIsFetching,
      setState: setRowData,
      data: rowData,
      api: defaultApi,
      appandData: {
        category_id: selectCate,
        ...searchData,
      },
      columnVisibility: {
        categoryIds: false,
        permissions: false,
      },
    },
    pageSize: 20,
    pageSizeOptions: [20, 50],
  };

  if (userLoading) return <LoadingUI />;

  return (
    <>
      <Breadcrumb
        lng={lng}
        currentElement={cateName}
        pervElement={{
          skip: true,
          position: 2,
        }}
      />
      <PropertyTemplate t={t} lng={lng} title="Property List" button_bar={buttonBar}>
        {showChangeStatus && changeStatusPopup()}
        {showChangeAsLet && changeAsLetPopup()}
        {showChangeCategory && changeCategoryPopup()}
        {showRemoveCurrent && RemoveCurrentPopup()}
        {showRemoveAll && RemoveAllPopup()}
        {showDeleteAll && DeleteAllPopup()}
        {showUploadProperty && uploadPropertyPopup()}
        {showUploadRent && uploadRentPopup()}
        <PropertyDataTableTemplate
          stateProps={stateProps}
          cateId={id}
          tableProps={tableDataProps}
          t={t}
        />
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={state.open}
            onClose={handleClose}
            message={t("The statutory period for lodging a proposal to alter the current Valuation List or Government Rent Roll had already expired on 31 May 2024.")}
            key='proposal-snakbar'
            ContentProps={{
              className: "label1 text-body",
              sx: {
                borderRadius: "var(--radius-sm)",
                boxShadow: "var(--box-shadow-lg)",
                background: "var(--surface-secondary)",
                color: "#262626",
                padding: "var(--spacing-md) var(--spacing-x-big)",
                "& .MuiSnackbarContent-message": {
                  padding: "0",
                },
              },
            }}
        />
        {/*<Snackbar*/}
        {/*  open={state.open}*/}
        {/*  autoHideDuration={4000}*/}
        {/*  TransitionComponent={state.Transition}*/}
        {/*  onClose={handleClose}*/}
        {/*  message={*/}
        {/*    Object.keys(rowSelection).length +*/}
        {/*    " " +*/}
        {/*    t("properties added to Proposal Submission!")*/}
        {/*  }*/}
        {/*  key={state.Transition.name}*/}
        {/*  ContentProps={{*/}
        {/*    className: "label1 text-body",*/}
        {/*    sx: {*/}
        {/*      borderRadius: "var(--radius-sm)",*/}
        {/*      boxShadow: "var(--box-shadow-lg)",*/}
        {/*      background: "var(--surface-secondary)",*/}
        {/*      color: "#262626",*/}
        {/*      padding: "var(--spacing-md) var(--spacing-x-big)",*/}
        {/*      "& .MuiSnackbarContent-message": {*/}
        {/*        padding: "0",*/}
        {/*      },*/}
        {/*    },*/}
        {/*  }}*/}
        {/*/>*/}
      </PropertyTemplate>
    </>
  );
};

export default ViewProperty;
