import AccountManagementTemplate from "../../components/account_management/Template";
import React, { useState, useMemo } from "react";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import { useTranslation } from "react-i18next";
import {
    DownloadButton,
    OutlineButton,
    ImportButton,
    UploadButton,
    EditButton
} from "../../components/button/SingleButton";
import ModelMenu from "../../components/menu/ModelMenu";
import ProfileSection from "../../components/my-profile/ProfileSection";
import AssessmentTenementRVSearch from "../../components/search/AssessmentTenementRVSearch";
import DataTable from "../../components/table/DataTable";
import YesSVG from "../../assets/images/others/yes.svg";
import NoSVG from "../../assets/images/others/cancel.svg";
import Breadcrumb from "../../components/header/Breadcrumb";

const TransactionHistreyDetails = ({ lng }) => {
    const { t } = useTranslation();
    // const { proposalSubmissionSearchBy } = AssessmentTenementRVSearch();
    const { profileData, userLoading } = useUserLoginContext();
    const [tableLoading, setTableLoading] = useState(false);

    const downloadOptions = [
        {value: 1, label: 'Download PDF ', action: () => true},
        {value: 2, label: 'Download Excel', action: () => true},
    ]

    const details = [
        {
            label: 'Subject',
            data: 'Submission of e-R20A Proposal',
        },
        {
            label: 'Transaction Reference No.',
            data: '9999 9999 9999 9999',
            borderTop: true,
        },
        {
            label: 'No. of Property',
            data: 20,
            borderTop: true,
        },
        {
            label: 'No. of withdrawn property',
            data: 0,
            borderTop: true,
        },
        {
            label: 'Submission Time',
            data: '2023/04/04 09:41:45',
            borderTop: true,
        },
        {
            label: 'Last Updated Time',
            data: '2023/04/04 09:41:45',
            borderTop: true,
        },
    ]

    const uploadOptions = [
        // {value: 1, label: 'Identity Proof Document', action: () => true, disabled: false},
        {value: 3, label: 'Authorisation Letter', action: () => true, disabled: false},
        {value: 4, label: 'Rental Information', action: () => true, disabled: false},
        {value: 5, label: 'Proposal Supporting Fact', action: () => true, disabled: false},
        {value: 2, label: 'Other Supporting Documents', action: () => true, disabled: false },
    ]

    const importOptions = [
        // {value: 1, label: 'Identity Proof Document', action: () => true, disabled: false},
        {value: 3, label: 'Authorisation Letter', action: () => true, disabled: false},
        {value: 4, label: 'Rental Information', action: () => true, disabled: false},
        {value: 5, label: 'Proposal Supporting Fact', action: () => true, disabled: false},
        {value: 2, label: 'Other Supporting Documents', action: () => true, disabled: false },
    ]

    const handleSearchSubmit = (searchData) => {
        console.log(searchData)
    }

    const templateButtonSet = () => {
        return (
            <div className='flex gap-sm'>
                <OutlineButton t={t} label='Withdraw Proposal'/>
                <ModelMenu options={downloadOptions} buttons={<DownloadButton t={t} selectIcon={true}/>} />
            </div>
        )
    }

    const documentButtonSet = () => {
        return (
            <div className='flex gap-sm'>
                <ModelMenu options={importOptions} buttons={<ImportButton t={t} selectIcon={true} />} />
                <ModelMenu options={uploadOptions} buttons={<UploadButton t={t} selectIcon={true} />} />
            </div>
        )
    }

    const handleSubmit = () => {

    }

    const rows = [];

    const columns = useMemo(
        () => [
            {
                accessorKey: 'assessment_number',
                header: 'Assessment No.',
                minSize: 200,
                
                grow: false,
            },
            {
                accessorKey: 'address_or_desc_of_tenement',
                header: 'Address or Description of Tenement',
                size: 260,
                enableSorting: false,
                grow: false,
            },
            // {
            //     accessorKey: 'change_of_rv',
            //     header: '% Change in RV',
            //     maxSize: 180,
            //
            //     grow: false,
            //     Cell: ({ cell }) => {
            //         const value = cell.getValue();
            //         const numericValue = parseFloat(value);
            //
            //         if (numericValue > 0) {
            //             return <span className="text-red-600">{value}</span>;
            //         }
            //         if (numericValue < 0) {
            //             return <span className="text-green-600">{value}</span>;
            //         }
            //         return <span className="text-neutral-600">{value}</span>;
            //     }
            // },
            {
                accessorKey: 'supporting_document',
                header: 'Supporting Documents',
                size: 420,
                enableSorting: false,
                grow: true,
                Cell: ({ cell, row }) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <EditButton t={t} href={`/${lng}/transaction-history/details/${row.original.id}`} searchIcon={true}/>
                    </div>
                ),
            },
            // {
            //     accessorKey: 'button',
            //     header: '',
            //     size: 94,
            //     enableSorting: false,
            //     grow: false,
            //     Cell: ({ cell, row }) => (
            //         <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            //             <EditButton t={t} href={`/${lng}/transaction-history/details/${row.original.id}`} searchIcon={true}/>
            //         </div>
            //     ),
            // },
        ],
        [rows]
    );

    return (
       <>
           <Breadcrumb lng={lng}/>
           <form onSubmit={handleSubmit} className="w-full">
               <AccountManagementTemplate
                   lng={lng}
                   title='Submission of e-R20A Proposal'
                   button_bar={templateButtonSet()}
                   noMenu={true}
               >
                   <div className='flex gap-xx-big flex-col'>
                       <div className="bg-white flex-col flex gap-xx-sm profile-field w-full rounded-big">
                           {details.map((item, index) => {
                               return (
                                   <div className={`flex items-center gap-big py-md justify-between md:justify-start max-w-full ${item.borderTop ? 'border-t border-neutral-100 border-dashed' : ''}`} key={index}>
                                       <div className="w-xx-huge md:w-240px body2 text-title">{item.label}</div>
                                       <div className="text-body label1 break-words whitespace-normal text-end">{item.data ?? '--' }</div>
                                   </div>
                               )
                           })}
                       </div>

                       <div className="bg-white flex-col flex gap-x-big profile-field w-full rounded-big">
                           <ProfileSection t={t} title={'Supplementary Documents'} button={documentButtonSet()}>
                           </ProfileSection>
                       </div>
                       <div className="bg-white flex-col flex gap-x-big profile-field w-full rounded-big">
                           <ProfileSection t={t} title={'Submitted Property List'}>
                               {/*{proposalSubmissionSearchBy(handleSearchSubmit)}*/}
                               <DataTable columns={columns} data={rows} border={true} loading={tableLoading}/>
                           </ProfileSection>
                       </div>
                   </div>
               </AccountManagementTemplate>
           </form>
       </>
    )
}

export default TransactionHistreyDetails;