import { render } from "@testing-library/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const MtCaptchaScript = ({ loadingCapthcha, setLoadingCapthcha }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();


  useEffect(() => {
    const getMtCaptcha = async () => {
      await loadMtCaptcha();
    };

    if (window.mtcaptcha) {
      const is_verified = window.mtcaptcha.getStatus()?.isVerified || false;
      if (!is_verified) {
        // window.mtcaptcha.remove();
        window.mtcaptchaConfig.lang = language === "tc" ? "zh-hk" : language === "sc" ? "zh" : "en";
        // window.mtcaptcha.renderUI();
      }
    }
  }, [language]);

  const loadScript = (src, id) => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        resolve();
        return;
      }
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script ${src}`));
      document.body.appendChild(script);
    });
  };


  const loadMtCaptcha = async () => {
    setLoadingCapthcha(true);

    window.mtcaptchaConfig = {
      sitekey: process.env.REACT_APP_PUBLIC_SITE_KEY,
      renderQueue: [],
      render: "auto",
      loadAnimation: true,
      "autoFormValidate": true,
      lang: language === "tc" ? "zh-hk" : language === "sc" ? "zh" : "en",
      customLangText: {
        en: {
          emptyCaptcha: "Please enter the English letter(s) and/or number(s) shown in the image to continue.",
          verifyFail:
            "Input characters do not match with the image. Please try again.",
        },
        "zh-hk": {
          emptyCaptcha: "請輸入圖像內的英文字母及／或數字繼續。",
          verifyFail: "輸入的字元與圖像不相符。請再嘗試。",
        },
        zh: {
          emptyCaptcha: "请输入图像内的英文字母及／或数字继续。",
          verifyFail: "输入的字元与图像不相符。请再尝试。",
        },
      },
    };

    try {
      await loadScript(
        `${process.env.PUBLIC_URL}/static/js/mtcaptcha.min.js`,
        "mtcaptcha-script"
      );
      setLoadingCapthcha(false);
    } catch (error) {
      console.error(t("Failed to load mtcaptcha scripts"), error);
    }
  };

  return {
    loadMtCaptcha,
  };
};

export default MtCaptchaScript;
