import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SmallFunction from "./SmallFunction";

const SubmitAndFetch = ({ lng, t }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { sweetFailAlert, sweetSuccessAlert } = SmallFunction();

  const createHeaders = (withFile) => {
    const headers = new Headers();
    headers.append("Accept-Language", lng);
    if (!withFile) headers.append("Content-Type", "application/json");
    return headers;
  };

  const handleFormData = (formData, withFile) => {
    if (!withFile && formData instanceof FormData) {
      return Object.fromEntries(formData.entries());
    }
    return formData;
  };

  const handleSuccess = (
    resData,
    successAction,
    successAlert,
    redirect_path,
    reload,
    setError
  ) => {
    if (successAction) successAction(resData.data, resData);
    if (successAlert) {
      sweetSuccessAlert(resData, t).then(() => {
        handleRedirect(reload, redirect_path, setError);
      });
    } else {
      handleRedirect(reload, redirect_path, setError);
    }
  };

  const handleRedirect = (reload, redirect_path, setError) => {
    if (setError) setError({});
    if (reload) {
      window.location.reload();
    } else if (redirect_path) {
      navigate(redirect_path);
    } else {
      setLoading(false);
    }
  };

  const handleError = (resData, failAction, failAlert, setError) => {
    if (resData.code === 501) {
      navigate(`/${lng}/error`);
    } if (resData.code === 502) {
      navigate(`/${lng}/403`);
    } else if (failAction) {
      failAction(resData);
    } else if (failAlert) {
      sweetFailAlert(resData, t);
    }

    if (setError && (resData.errors || resData.message)) {
      setError(resData.errors ? resData.errors : { message: resData.message });
    } else {
      if (resData.code === 501) {
        navigate(`/${lng}/error`);
      } else if (resData.code === 502) {
        navigate(`/${lng}/403`);
      } else {
        sweetFailAlert(resData, t);
      }
    }
  };

  const postForm = async (
    api_name,
    slug,
    formData,
    setError,
    redirect_path,
    reload = false,
    successAction,
    authorization,
    setState,
    setDataLoading,
    successAlert = true,
    failAlert = false,
    failAction,
    withFile
  ) => {
    const url = `${process.env.REACT_APP_PUBLIC_API_URL}/v1/${api_name}${
      slug ? `/${slug}` : ""
    }`;
    const headers = createHeaders(withFile);
    const dataObject = handleFormData(formData, withFile);
    setDataLoading ? setDataLoading(true) : setLoading(true);
    try {
      const res = await fetch(url, {
        method: "POST",
        body: withFile ? dataObject : JSON.stringify(dataObject),
        headers: headers,
        credentials: 'include'
      });
      const resData = await res.json();
      if (res.ok) {
        if (slug && slug.includes("proof") && setState) {
          setState(res);
        }
        if (resData.data && setState) {
          setState(resData.data);
        }
        if (resData.code === 200) {
          handleSuccess(
            resData,
            successAction,
            successAlert,
            redirect_path,
            reload,
            setError
          );
        } else {
          handleError(resData, failAction, failAlert, setError);
        }
      } else {
        handleError(resData, failAction, failAlert, setError);
      }
    } catch (error) {
      sweetFailAlert(error, t);
      setDataLoading ? setDataLoading(false) : setLoading(false);
    } finally {
      setDataLoading ? setDataLoading(false) : setLoading(false);
    }
  };

  const fetchData = async (
    api_name,
    slug,
    setState,
    setLoading,
    lng,
    authorization,
    successAction
  ) => {
    const url = `${process.env.REACT_APP_PUBLIC_API_URL}/v1/${api_name}${
      slug ? `/${slug}` : ""
    }`;
    const headers = createHeaders();

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headers,
        credentials: 'include',
      });
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await res.json();
      if (setState) setState(data.data);
      if (successAction) successAction(data.data);
    } catch (error) {
      sweetFailAlert(error, t);
    } finally {
      if (setLoading) setLoading(false);
    }
  };

  const downloadData = async (
    api_name,
    authorization,
    appendData,
    withFile
  ) => {
    const apiUrl = `${process.env.REACT_APP_PUBLIC_API_URL}/v1/${api_name}`;
    const headers = createHeaders(withFile);
    if (withFile) {
      headers.append(
        "Accept",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }

    const body = withFile ? new FormData() : {};
    if (appendData) {
      Object.entries(appendData).forEach(([key, value]) => {
        withFile ? body.append(key, value) : (body[key] = value);
      });
    }

    try {
      const res = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: withFile ? body : JSON.stringify(body),
      });
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await res.blob();
      const fileName = getFileName(res.headers.get("Content-Disposition"));
      downloadBlob(blob, fileName);
    } catch (error) {
      sweetFailAlert(error, t);
    }
  };

  const getFileName = (contentDisposition) => {
    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
      const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      if (fileNameMatch && fileNameMatch.length === 2) {
        return fileNameMatch[1];
      }
    }
    return "";
  };

  const downloadSubmitDoc = async (id, authorization) => {
    const apiUrl = `${process.env.REACT_APP_PUBLIC_API_URL}/v1/document/submitted/download/${id}`;
    const headers = createHeaders(true);

    try {
      const res = await fetch(apiUrl, {
        method: "POST",
        headers: headers,
      });
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await res.blob();
      const fileName = getFileName(res.headers.get("Content-Disposition"));
      downloadBlob(blob, fileName);
    } catch (error) {
      sweetFailAlert(error, t);
    }
  }

  const downloadBlob = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const previewFile = async (api_name, authorization, method="POST", appendData) => {
    let apiUrl = `${process.env.REACT_APP_PUBLIC_API_URL}/v1/${api_name}`;
    const headers = createHeaders(true);

    if (appendData && method === "GET") {
      const queryParams = new URLSearchParams(appendData).toString();
      apiUrl += `?${queryParams}`;
    }

    try {
      const res = await fetch(apiUrl, {
        method: method,
        headers: headers,
      });
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await res.blob();
      const fileName = getFileName(res.headers.get("Content-Disposition"));
      const fileUrl = window.URL.createObjectURL(blob);
      if (!appendData && method !== "GET") {
        window.open(fileUrl, "_blank");
      } else {
        downloadBlob(blob, fileName);
      }
    } catch (error) {
      sweetFailAlert(error, t);
    }
  };

  return {
    postForm,
    fetchData,
    downloadData,
    previewFile,
    loading,
    downloadSubmitDoc,
  };
};

export default SubmitAndFetch;
