import CateSelect2 from "../../components/input/portal/CateSelect2";
import DataTable from "../../components/table/DataTable";
import UploadPopup from "../../components/popup/UploadPopup";
import React, { useState, useMemo, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AssessmentTenementRVSearch from "../../components/search/AssessmentTenementRVSearch";
import {
  UploadButton,
  ImportButton,
  OutlineBackButton,
} from "../button/SingleButton";
import UPLOAD_DOC_TYPE from "../../data/variable/upload_doc_type.json";
import USER_TYPE from "../../data/variable/user_type.json";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import SmallFunction from "../../components/function/SmallFunction";

const StepOne = ({
  lng,
  error,
  setError,
  t,
  stateProps,
  uploadedFile,
  updateFields,
}) => {
  const { checkAndChangeUploadCate } = SmallFunction();
  const [popupError, setPopupError] = useState({});
  const [showUpload, setShowUpload] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [actionData, setActionData] = useState([]);
  const [filterOption, setFilterOption] = useState([]);
  const [tableLoading, setTableLoading] = useState(
    !stateProps?.rowData?.list?.length
  );
  const { postForm } = SubmitAndFetch({ lng, t });
  const { token, account_type } = useUserLoginContext();
  const defaultApi = "proposal/property";
  const is_corporate = account_type === USER_TYPE.ADMIN || account_type === USER_TYPE.OFFICER;

  const { proposalSubmissionSearchBy } = AssessmentTenementRVSearch({
    props: {
      setIsFetching,
      setSearchData,
      setError,
      error,
      searchData,
      updateFields: (fields) =>
        setSearchData((prev) => ({ ...prev, ...fields })),
    },
  });

  useEffect(() => {
    const getFilterData = async () => {
      await postForm(
        "proposal/init",
        null,
        null,
        null,
        null,
        false,
        null,
        token,
        setFilterOption,
        null,
        false
      );
    };

    getFilterData();
  }, [token]);

  const handleRemoveProperty = async (id, CateId) => {
    if (id && CateId) {
      const formData = new FormData();
      formData.append("category_id", CateId);
      formData.append("id", id);
      await postForm(
        `${defaultApi}/delete`,
        null,
        formData,
        null,
        null,
        false,
        () => setIsFetching(true),
        token,
        null,
        null,
        false,
        true
      );
    }
  };

  const handlePopupSubmit = async () => {
    const uploadPropertyIds = actionData?.allValidList
      ?.map((item) => item.id)
      .join(",");
    if (uploadPropertyIds) {
      const formData = new FormData();
      formData.append("category_id", stateProps?.selectUploadCate);
      formData.append("ids", uploadPropertyIds);

      const handleUploadSuccess = () => {
        setShowUpload(false);
        stateProps?.setSelectCate(stateProps?.selectUploadCate);
        setIsFetching(true);
      };

      await postForm(
        "proposal/property/add",
        null,
        formData,
        setError,
        null,
        false,
        handleUploadSuccess,
        token,
        null,
        null,
        true
      );
    }
  };

  const handleSubmit = (value) => {
    setSearchData(value);
    setIsFetching(true);
  };

  const changeCateAction = (event) => {
    const newCateId = event.target.value || "0";
    stateProps?.setSelectCate(newCateId);
    checkAndChangeUploadCate(
      newCateId,
      stateProps?.setSelectUploadCate,
      filterOption?.categoryIdList
    );
    setIsFetching(true);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "assessmentNo",
        header: "Assessment No.",
        minSize: 210,
        grow: false,
        objKey: "assessment_no",
        Header: ({ column }) => (
          <div className="ps-sm">{column.columnDef.header}</div>
        ),
      },
      {
        accessorKey: "address",
        header: "Address or Description of Tenement",
        minSize: 664,
        enableSorting: false,
        grow: false,
        Header: ({ column }) => (
          <div className="ps-big">{column.columnDef.header}</div>
        ),
      },
      {
        accessorKey: "changeRv",
        header: "% Change in RV",
        minSize: 100,
        objKey: "change_rv",
        Cell: ({ cell, row }) => {
          const value = cell.getValue();
          const numericValue = value ? parseFloat(value) : null;
          if (numericValue) {
            if (numericValue >= 0) {
              return <span className="text-red-600">{value}</span>;
            }
            if (numericValue < 0) {
              return <span className="text-green-600">{value}</span>;
            }
          } else {
            return <span className="text-neutral-600">N/A</span>;
          }
        },
      },
      {
        accessorKey: "id",
        header: "",
        minSize: 100,
        enableSorting: false,
        grow: false,
        Cell: ({ cell }) => {
          return (
            <div className="flex justify-end w-full items-center mr-lg">
              <div
                className="bg-surface-primary rounded-x-sm hover:bg-surface-primary-hover flex justify-center items-center w-xxx-big h-xxx-big p-sm cursor-pointer"
                onClick={() =>
                  handleRemoveProperty(cell.getValue(), stateProps?.selectCate)
                }
              >
                <CloseIcon className="text-white" fontSize="inherit" />
              </div>
            </div>
          );
        },
      },
    ],
    [stateProps?.selectCate, stateProps?.rowData]
  );

  const tableDataProps = {
    data: stateProps?.rowData.list,
    columns,
    enableSelection: false,
    customLoading: tableLoading,
    setCustomLoading: setTableLoading,
    apiProps: {
      setError,
      id: stateProps?.selectCate,
      isFetching,
      setIsFetching,
      setState: stateProps?.setRowData,
      api: `${defaultApi}/list`,
      appandData: {
        category_id: stateProps?.selectCate,
        ...searchData,
      },
    },
    pageSize: 20,
    pageSizeOptions: [20, 50],
  };

  const PopupProps = (setState) => ({
    id: stateProps?.selectUploadCate,
    setState,
    t,
    apiProps: {
      setError: setPopupError,
      lng,
      token,
      onClickInnerFormSubmit: handlePopupSubmit,
    },
  });

  const uploadPopupProps = (
    name,
    showUpload,
    setShowUpload,
    UploadContextProps,
    DropzoneProps,
    apiProps,
    SelectCateProps
  ) => ({
    name,
    uploadedFile,
    showUpload,
    setShowUpload,
    stateProps,
    PopupProps,
    apiProps,
    updateFields,
    UploadContextProps,
    DropzoneProps,
    SelectCateProps,
    t,
    lng,
  });

  const uploadPopup = () => {
    return (
      <UploadPopup
        {...uploadPopupProps(
          "upload_property_file",
          showUpload,
          setShowUpload,
          {
            title: "Upload Property List",
            subtitle: (
              <>
                Maximum number of properties of one CSV file can be uploaded is
                2,000.
                <br />
                Each time can assign properties into one category only.
              </>
            ),
            downloadText: "Download Property List Template",
          },
          {
            progressBar: true,
            type: UPLOAD_DOC_TYPE.PROPERTY_LIST,
          },
          {
            api_name: "proposal/property/import",
            setError: setPopupError,
            error: popupError,
            appandData: {
              category_id: stateProps?.selectUploadCate,
            },
            downloadTemplateAPI: "proposal/property/import/template",
            downloadInvalidListAPI: "proposal/property/import/invalid",
            withFile: true,
            successAction: (data) => setActionData(data),
          },
          {
            name: "category_id",
            cateId: stateProps?.selectUploadCate,
            setSelectCate: stateProps?.setSelectUploadCate,
            cateListOption: filterOption?.categoryIdList || [],
          }
        )}
      />
    );
  };

  const importPopup = () => {
    return (
      <></>
      // <UploadPopup
      //     {...uploadPopupProps(
      //         'import_property_file',
      //         showImport,
      //         setShowImport,
      //         {
      //             title: 'Property List Import',
      //             subtitle: <>
      //                 Maximum number of properties of one CSV file can be uploaded is 2,000.
      //             </>,
      //         },
      //     )}
      // />
    );
  };

  return (
    <>
      {showUpload && uploadPopup()}
      {showImport && importPopup()}
      <div className="flex flex-col gap-md">
        <div className="flex gap-sm flex-col md:flex-row">
          <div className="flex-1">
            <CateSelect2
              name="category_id"
              error={error?.category_id}
              cateId={stateProps?.selectCate}
              setSelectCate={stateProps?.setSelectCate}
              categories_options={filterOption?.categoryIdList || []}
              changeCateAction={changeCateAction}
            />
          </div>
          <div className="w-full md:w-fit">
            {is_corporate ?
                <UploadButton
                t={t}
                setState={setShowUpload}
                label="Upload Property List"
            /> : null}
          </div>
        </div>
        {proposalSubmissionSearchBy(handleSubmit, searchData)}
        <div className="mt-sm">
          <div
            className={
              stateProps?.selectCate && stateProps?.rowData?.list?.length > 0
                ? "block"
                : "hidden"
            }
          >
            <DataTable {...tableDataProps} />
          </div>
          <div
            className={`bg-white py-xxx-lg text-center w-full flex flex-col justify-center items-center ${
              stateProps?.rowData?.list?.length > 0 ? "hidden" : "block"
            }`}
          >
            <div className="label1 text-black mb-lg max-w-460px">
              No property selected for proposal submission, please {is_corporate ? 'upload property list or' : ''} add from property list to continue
            </div>
            <div className="flex flex-col gap-md">
              {is_corporate ?
                  <ImportButton
                  t={t}
                  setState={setShowUpload}
                  label="Upload Property List"
              /> : null}
              <OutlineBackButton
                t={t}
                label="My Property List"
                href={`/${lng}/property-list/view-property/${
                  stateProps?.selectCate ? stateProps?.selectCate : null
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepOne;
