import { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Switch } from "@mui/material";
import ErrorMessage from "../../components/error/ErrorMessage";

const MySwitch = ({
  name,
  action,
  checked,
  inputLabel,
  data,
  error,
  setError,
  lng,
  updateFields,
  labelPlacement = "end",
  labelClass,
  disabled,
  verified = true,
  t
}) => {
  const [value, setValue] = useState(Boolean( data || false));
  const handleChange = (event) => {
    const newValue = event.target.checked;
    if (action) action(event);
    if (newValue && !verified) return;
    setValue(newValue);
    if (updateFields) updateFields({ [name]: newValue ? 1 : 0 });
  };

  useEffect(() => {
    setValue(data);
  }, [data]);

  useEffect(() => {
    setValue(Boolean( data || false));
  }, []);

  return (
    <FormControl component="fieldset" variant="standard">
      <FormControlLabel
        component="legend"
        label={
          inputLabel ? (
            <span
              className={`m-0  ${
                labelClass ? labelClass : "text-body label1-400 pl-sm"
              }`}
            >
              {t ? t(inputLabel) : inputLabel}
            </span>
          ) : (
            ""
          )
        }
        labelPlacement={labelPlacement}
        control={
          <Switch
            onChange={handleChange}
            checked={value}
            className="relative"
            name={name}
            disabled={disabled}
            sx={{
              width: "50px",
              height: "28px",
              padding: "0",
              "& .MuiSwitch-switchBase": {
                padding: "0",
                margin: "2px",
                transitionDuration: "300ms",
                "&.Mui-checked": {
                  transform: "translateX(22px)",
                  color: "#fff",
                  "& + .MuiSwitch-track": {
                    backgroundColor: "var(--surface-primary)",
                    opacity: "1",
                    border: "0",
                    transition: "background-color 300ms ease",
                  },
                  "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: "0.4",
                    backgroundColor: "var(--surface-primary)",
                  },
                },
                "&.Mui-focusVisible .MuiSwitch-thumb": {
                  backgroundColor: "var(--surface-primary)",
                  border: "6px solid #fff",
                },
                "&.Mui-disabled .MuiSwitch-thumb": {
                  color: "#fff",
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  backgroundColor: "var(--Neutral-300)",
                  opacity: "0.4",
                },
              },
              "& .MuiSwitch-thumb": {
                boxShadow: "none",
                boxSizing: "border-box",
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                transition: "background-color 300ms ease",
              },
              "& .MuiSwitch-track": {
                borderRadius: "14px",
                backgroundColor: "var(--Neutral-300)",
                opacity: "1",
                transition: "background-color 300ms ease",
              },
            }}
          />
        }
        sx={{
          margin: 0,
          "& .MuiTypography-root": {
            paddingLeft: labelPlacement === "end" ? "var(--spacing-sm)" : "",
            paddingRight: labelPlacement === "start" ? "var(--spacing-sm)" : "",
          },
        }}
      />
      {error && <ErrorMessage error={error} />}
    </FormControl>
  );
};

export default MySwitch;
