import {useTranslation} from "react-i18next";
import Breadcrumb from "../../components/header/Breadcrumb";
import ArticleTemplates from "../../components/articles/ArticlesTemplate";
import React from "react";
const ImportantNotice = ({ lng }) => {
    const { t } = useTranslation();

    const en = () => (
        <>
            <h2 className='font-bold'>Disclaimer</h2>
            The information provided by the Government of the Hong Kong Special Administrative Region ("the Government")
            on this Web Portal of the Rating and Valuation Department ("the Web Portal") is for reference only. Whilst
            the Government endeavours to ensure the accuracy of the information on the Web Portal, no express or implied
            warranty is given by the Government as to the accuracy of the Government's information.
            <br/>
            <br/>
            The Web Portal also contains information input by other parties and users may link this site to
            other sites and obtain information provided by other parties (collectively called "the other
            information").
            <br/>
            <br/>
            The Government expressly states that it has not approved nor endorsed the other
            information contained in or in connection with this site.
            <br/>
            <br/>
            The Government does not accept any responsibilities for any loss or damage whatsoever arising from
            any cause whatsoever in connection with the Web Portal. The Government is entitled to delete,
            suspend or edit all information on this site at any time at its absolute discretion without giving
            any reason. Users are responsible for making their own assessments of all information contained in
            or in connection with this site and are advised to verify such information by making reference to
            its original publication and obtain independent advice before acting on it.
            <br/>
            <br/>
            <h2 className='font-bold'>Copyright Notice </h2>
            Unless otherwise indicated, the materials found on the Web Portal are subject to copyright owned by the
            Government. The Government copyright protected materials may be reproduced and distributed free of charge in
            any format or medium for personal or internal use within an organisation subject to the following conditions
            -
            <br/>
            <br/>
            <ul className='list-disc ps-lg'>
                <li>the copy or copies made must not be for re-sale or commercial distribution purpose;</li>
                <li>the materials must be reproduced accurately and must not be used in a manner adversely affecting the
                    moral rights of the Government; and
                </li>
                <li>the source of the materials must be identified and the copyright status acknowledged. Please note
                    that this permission only applies to Government copyright materials. Where third party copyright is
                    involved, permission to reproduce or otherwise use such material must be obtained from the copyright
                    owners concerned.
                </li>
            </ul>
            <br/>
            Prior written consent of the Government is required if you want to use the aforesaid materials in any way
            other than that permitted above. Such requests should be addressed to the Rating and Valuation Department
            (“the Department”) via email at <a className='text-surface-primary hover:underline'
                                               href="mailto: enquiries@rvd.gov.hk">enquiries@rvd.gov.hk</a>.
            The Government reserves the right to withdraw any such permission at any time.
            <br/>
            <br/>
            This Copyright Notice has been translated into Chinese. If there is any inconsistency or ambiguity between
            the English version and the Chinese version, the English version shall prevail.
            <br/>
            <br/>
            <h2 className='font-bold'>Web Accessibility Conformance Statement </h2>
            We are committed to ensuring that the Web Portal conforms to the World Wide Web Consortium (W3C) Web Content
            Accessibility Guidelines (WCAG) 2.1 Level AA requirements to the maximum extent possible. However, some web
            pages with archive materials, multi-media contents and Portable Document Format (PDF) files may not have all
            Level AA accessibility requirements incorporated due to technical constraints. For enquiries, please email
            us at
            <a className='text-surface-primary hover:underline'
               href="mailto: enquiries@rvd.gov.hk"> enquiries@rvd.gov.hk</a>.
            <br/>
            <br/>
            <h2 className='font-bold'>Security Statement</h2>
            The Government does not guarantee that any message sent over the Internet will be completely secure.
            Communications over the Internet may be subject to interruption, delayed transmission due to the internet
            traffic or incorrect data transmission due to the public nature of the Internet or otherwise. The Government
            does not accept responsibilities for any loss or damage incurred by a user if he sends a message to the Web
            Portal, or if the Web Portal generates a message to the user at his request, over the Internet.
            <br/><br/>
            Web Portal registered users are responsible for keeping their account login passwords confidential.
            Registered users are encouraged to change passwords periodically. If a registered user suspects that his
            account login password has been maliciously tampered with, please contact the Department immediately. In the
            case where a registered user allows an unauthorized individual to gain access to the account login password,
            the Government will not be held responsible for any consequences resulting from this action.
        </>
    )
    const tc = () => (
        <>
            <h2 className='font-bold'>免責條款</h2>
            香港特別行政區政府（下稱「政府」）在差餉物業估價署電子服務平台
            (下稱「本電子服務平台」)所提供的資料只供參考之用。雖然政府已盡力確保本電子服務平台上的資料準確無誤，但政府並不對該等資料的準確性作出任何明示或隱含的保證。
            <br/>
            <br/>
            本電子服務平台亦載有由其他各方輸入的資料，使用者亦可透過將本電子服務平台連接到其他網址而取得由其他各方所提供的資料（統稱「其他資料」）。
            <br/>
            <br/>
            政府明確聲明並沒有批准或認可本電子服務平台所載的其他資料或與本電子服務平台有關連的其他資料。
            <br/>
            <br/>
            對於與本電子服務平台有關連的任何因由所引致的任何損失或損害，政府概不負責。政府有絕對酌情權隨時刪除、暫時停載或編輯本電子服務平台上的各項資料而無須給予任何理由。使用者須負責自行評估本電子服務平台所載的各項資料或與本電子服務平台有關連的各項資料，並應在根據該等資料行事前，藉參照原本發布的文本以核實該等資料，以及徵詢獨立意見。
            <br/>
            <br/>
            <h2 className='font-bold'>版權公告</h2>
            本電子服務平台所載的資料，除另有註明外，皆受政府的版權保護。受政府版權保護的資料可以任何形式或媒體免費複製和分發，作個人用途或供機構內部使用，唯須符合下列條件︰
            <br/>
            <br/>
            <ul className='list-disc ps-lg'>
                <li>複製本不得轉售或以商業形式分發；</li>
                <li>複製的資料必須準確，並不得用以侵犯政府的精神權利；以及
                </li>
                <li>必須註明資料來源和版權擁有人。
                </li>
            </ul>
            <br/>
            上列允許用途只適用於由政府擁有版權的資料。若涉及第三者版權，在複製或以其他形式使用有關資料前，必須獲得版權擁有人的允許。
            <br/>
            <br/>
            如欲使用上述資料作上述允許用途以外的其他用途，須事先得到政府的書面同意。有關申請可電郵至差餉物業估價署（下稱「本署」），電郵地址 <a
            className='text-surface-primary hover:underline'
            href="mailto: enquiries@rvd.gov.hk">enquiries@rvd.gov.hk</a> 。政府保留隨時撤回允許的權利。
            此中文版權告示為英文版本譯本。如中、英文兩個版本有任何抵觸或不相符之處，應以英文版本為準。
            <br/>
            <br/>
            <h2 className='font-bold'>無障礙網頁守則聲明</h2>
            我們承諾盡力確保本電子服務平台符合萬維網聯盟（W3C）《無障礙網頁內容指引》（WCAG）2.1AA
            級別標準。惟部分網頁之存檔資料、多媒體內容和Portable Document Format (PDF)文件或因技術限制而未能完全符合有關要求。如有查詢，請電郵至
            <a className='text-surface-primary hover:underline'
               href="mailto: enquiries@rvd.gov.hk"> enquiries@rvd.gov.hk</a>.
            <br/>
            <br/>
            <h2 className='font-bold'>安全聲明</h2>
            政府不保證網上傳遞的任何訊息完全安全可靠。網上通訊會受到網絡交通或資料傳送錯誤等因素引致的傳送中斷或延誤所影響。政府亦不會就使用人在網上向本電子服務平台傳遞訊息，或本電子服務平台應使用人的要求在網上向其傳遞訊息而引致或涉及的損失、毀壞或損害而承擔責任。
            <br/><br/>
            本電子服務平台的登記用户須負責保密自已的帳戶登入密碼。本署建議登記用戶定期更改密碼。如登記用户懷疑自己的帳戶登入密碼遭人惡意干攘，請立即與本署聯絡。如登記用户讓未經授權個人使用其帳户登入密碼，則政府將無須為由此引致的任何後果承擔責任。

        </>
    )
    const sc = () => (
        <>
            <h2 className='font-bold'>免责条款</h2>
            香港特别行政区政府（下称「政府」）在差饷物业估价署电子服务平台
            (下称「本电子服务平台」)所提供的资料只供参考之用。虽然政府已尽力确保本电子服务平台上的资料准确无误，但政府并不对该等资料的准确性作出任何明示或隐含的保证。
            <br/>
            <br/>
            本电子服务平台亦载有由其他各方输入的资料，使用者亦可透过将本电子服务平台连接到其他网址而取得由其他各方所提供的资料（统称「其他资料」）。
            <br/>
            <br/>
            政府明确声明并没有批准或认可本电子服务平台所载的其他资料或与本电子服务平台有关连的其他资料。
            <br/>
            <br/>
            对于与本电子服务平台有关连的任何因由所引致的任何损失或损害，政府概不负责。政府有绝对酌情权随时删除、暂时停载或编辑本电子服务平台上的各项资料而无须给予任何理由。使用者须负责自行评估本电子服务平台所载的各项资料或与本电子服务平台有关连的各项资料，并应在根据该等资料行事前，借参照原本发布的文本以核实该等资料，以及征询独立意见。
            <br/>
            <br/>
            <h2 className='font-bold'>版权公告</h2>
            本电子服务平台所载的资料，除另有注明外，皆受政府的版权保护。受政府版权保护的资料可以任何形式或媒体免费复制和分发，作个人用途或供机构内部使用，唯须符合下列条件︰
            <br/>
            <br/>
            <ul className='list-disc ps-lg'>
                <li>复制本不得转售或以商业形式分发；</li>
                <li>复制的资料必须准确，并不得用以侵犯政府的精神权利；以及
                </li>
                <li>必须注明资料来源和版权拥有人。
                </li>
            </ul>
            <br/>
            上列允许用途只适用于由政府拥有版权的资料。若涉及第三者版权，在复制或以其他形式使用有关资料前，必须获得版权拥有人的允许。
            <br/>
            <br/>
            如欲使用上述资料作上述允许用途以外的其他用途，须事先得到政府的书面同意。有关申请可电邮至差饷物业估价署（下称「本署」），电邮地址 <a
            className='text-surface-primary hover:underline'
            href="mailto: enquiries@rvd.gov.hk">enquiries@rvd.gov.hk</a> 。政府保留随时撤回允许的权利。
            此中文版权告示为英文版本译本。如中、英文两个版本有任何抵触或不相符之处，应以英文版本为准。
            <br/>
            <br/>
            <h2 className='font-bold'>无障碍网页守则声明</h2>
            我们承诺尽力确保本电子服务平台符合万维网联盟（W3C）《无障碍网页内容指引》（WCAG）2.1AA
            级别标准。惟部分网页之存档资料、多媒体内容和Portable Document Format (PDF)文件或因技术限制而未能完全符合有关要求。如有查询，请电邮至
            <a className='text-surface-primary hover:underline'
               href="mailto: enquiries@rvd.gov.hk"> enquiries@rvd.gov.hk</a>.
            <br/>
            <br/>
            <h2 className='font-bold'>安全声明</h2>
            政府不保证网上传递的任何讯息完全安全可靠。网上通讯会受到网络交通或资料传送错误等因素引致的传送中断或延误所影响。政府亦不会就使用人在网上向本电子服务平台传递讯息，或本电子服务平台应使用人的要求在网上向其传递讯息而引致或涉及的损失、毁坏或损害而承担责任。
            <br/><br/>
            本电子服务平台的登记用户须负责保密自已的帐户登入密码。本署建议登记用户定期更改密码。如登记用户怀疑自己的帐户登入密码遭人恶意干攘，请立即与本署联络。如登记用户让未经授权个人使用其帐户登入密码，则政府将无须为由此引致的任何后果承担责任。
        </>
    )

    return (
        <>
            <Breadcrumb
                lng={lng}
            />
            <ArticleTemplates subtitle='' title={t('Important Notice')}>
                <div className='bg-surface-card p-md md:p-lg rounded-big mt-x-big w-full'>
                    {lng === 'tc' ? tc() : lng === 'sc' ? sc() : en()}
                </div>
            </ArticleTemplates>
        </>
    )
}

export default ImportantNotice;