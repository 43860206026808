import AssessmentTenementRVSearch from "../../components/search/AssessmentTenementRVSearch";
import DataTable from "../../components/table/DataTable";

const PropertyDataTableTemplate = ({  stateProps, optionsList, cateId, tableProps }) => {
    const {
        allPropertiseTools,
        popertiseTools,
    } = AssessmentTenementRVSearch({props:stateProps, optionsList, cateId});

    return (
        <div className="flex flex-col gap-x-big">
            {cateId ? popertiseTools() : allPropertiseTools()}
            <DataTable {...tableProps}/>
        </div>
    );
};

export default PropertyDataTableTemplate;
