import {useTranslation} from "react-i18next";
import Breadcrumb from "../../components/header/Breadcrumb";
import ArticleTemplates from "../../components/articles/ArticlesTemplate";
import React from "react";
import {Link} from "react-router-dom";
import {useUserLoginContext} from "../../context/UserLoginProvider";
import USER_TYPE from "../../data/variable/user_type.json";

const SiteMap = ({ lng }) => {
    const { t } = useTranslation();
    const { account_type, profileData } = useUserLoginContext()

    return (
        <>
            <Breadcrumb
                lng={lng}
            />
            <ArticleTemplates subtitle='' title={t('Site Map')}>
                <div className='p-md md:p-lg rounded-big mt-x-big w-full sitemap-container'>
                    <ul className='body1 flex flex-col gap-md'>
                        {profileData && Object.entries(profileData).length ? (
                            <>
                                <li>
                                    <Link to={'/'}>{t('Home')}</Link>
                                    <ul className='list-disc ms-lg pt-sm flex-col flex gap-sm'>
                                        <li><Link to={`/${lng}/quick-links`}>{t('Edit Quick Link')}</Link></li>
                                    </ul>
                                </li>
                                <li className='list-none'><Link to={`/${lng}/property-list`}>{t('Property List')}</Link></li>
                                <li className='list-none'><Link to={`/${lng}/proposal-submission`}>{t('Proposal Submission')}</Link></li>
                                <li className='list-none'><Link to={`/${lng}/transaction-history`}>{t('Transaction History')}</Link></li>
                                <li className='list-none'><Link to={`/${lng}/submitted-documents`}>{t('Submitted Documents')}</Link></li>
                                <li className='list-none'><Link to={`/${lng}/my-documents`}>{t('My Documents')}</Link></li>
                                <li className='list-none'>
                                    <Link to={`/${lng}/help-centre`}>{t('Help Centre')}</Link>
                                    <ul className='list-disc ms-lg pt-sm flex-col flex gap-sm'>
                                        <li><Link to={`/${lng}/help-centre/getting-started`}>{t('Getting Started')}</Link></li>
                                        <li><Link to={`/${lng}/help-centre/articles`}>{t('Articles')}</Link></li>
                                        <li><Link to={`/${lng}/help-centre/faqs`}>{t('FAQs')}</Link></li>
                                    </ul>
                                </li>
                                <li className='list-none'><Link to={`/${lng}/notification-centre`}>{t('Notification Centre')}</Link></li>
                                <li className='list-none'><Link to={`/${lng}/my-profile`}>{t('My Profile')}</Link></li>
                                <li className='list-none'><Link to={`/${lng}/personalisation`}>{t('Personalisation')}</Link></li>
                                {account_type && account_type === USER_TYPE.ADMIN ? (
                                    <li className='list-none'>
                                        <Link to={`/${lng}/user-management`}>{t('User Management')}</Link>
                                        <ul className='list-disc ms-lg pt-sm flex-col flex gap-sm'>
                                            <li><Link to={`/${lng}/user-management/account-creation`}>{t('Account Creation')}</Link></li>
                                        </ul>
                                    </li>
                                ) : null}
                            </>
                        ) : null}
                        <li className='list-none'><Link to={`/${lng}/login`}>{t('Login')}</Link></li>
                        <li className='list-none'><a href={`/portal/${lng}/registration`}>{t('Account Registration')}</a></li>
                        <li className='list-none'><a href={`/portal/${lng}/forgot-password`}>{t('Forgot Password')}</a></li>
                        <li className='list-none'><a href={`/portal/${lng}/forgot-username`}>{t('Forgot Username')}</a></li>
                        <li className='list-none'><a href={`/portal/${lng}/activate-account`}>{t('Activate Account')}</a></li>
                        <li className='list-none'><Link to={`/${lng}/privacy-policy`}>{t('Privacy Policy')}</Link></li>
                        <li className='list-none'><Link to={`/${lng}/important-notice`}>{t('Important Notice')}</Link></li>
                    </ul>
                </div>
            </ArticleTemplates>
        </>
    )
}

export default SiteMap;