import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  DeleteWithTextButton,
  DiscardButton,
  OutlineBackButton,
  OutlineButton,
} from "../../components/button/SingleButton";
import {
  AgentSelect,
  EffectPeriod,
  Name,
  SwitchAgent,
} from "../../components/agent_appointments/FieldSection";
import TabTemplate from "../../components/tab/TabTemplate";
import Input from "../../components/input/Input";
import ProfileSection from "../../components/my-profile/ProfileSection";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import AccountManagementTemplate from "../../components/account_management/Template";
import LoadingUI from "../../components/loading/LoadingUI";
import { DeepEqual } from "../../assets/js/global";
import BasicTag from "../../components/tags/BasicTag";
import Line from "../../components/line/Line";
import Popup from "../../components/popup/Popup";
import CategoryTable from "../../components/table/CategoryTable";
import Switcher from "../../components/button/Switcher";
import FileDropzone from "../../components/upload/FileDropzone";
import UploadFilePreview from "../../components/upload/UploadFilePreview";
import CircularProgress from "@mui/material/CircularProgress";
import SmallFunction from "../../components/function/SmallFunction";
import CloseIcon from "@mui/icons-material/Close";
import Breadcrumb from "../../components/header/Breadcrumb";

const principal_title = "Principal User";
const agent_title = "Agent";

const AppointmentDetails = ({ lng }) => {
  const requested = "1";
  const expired = "2";
  const rejected = "3";
  const active = "4";
  const inactive = "5";
  const cancelled = "6";
  const terminated = "7";

  const cancel = "6";
  const terminate = "7";

  const editable = "2";
  const view_only = "1";

  const { t } = useTranslation();
  const { id } = useParams();
  const { profileData, userLoading, account_type } =
    useUserLoginContext();
  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const { changeDate, sweetFailAlert } = SmallFunction();
  const [error, setError] = useState([]);
  const [data, setData] = useState({}); //new data
  const [oldData, setOldData] = useState({}); //old data
  const [originalData, setOriginalData] = useState({}); //original Data
  const [activeIndex, setActiveIndex] = useState(Number(active));
  const [getFileLoading, setGetFileLoading] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showConfirmTerminatePopup, setShowConfirmTerminatePopup] =
    useState(false);
  const [viewOnlyState, setViewOnlyState] = useState({});
  const [editableState, setEditableState] = useState({});
  const [oldViewOnlyState, setOldViewOnlyState] = useState({});
  const [oldEditableState, setOldEditableState] = useState({});
  const [changed, setChanged] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [principalToAgent, setPrincipalToAgent] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const api_name = "appointment";
  const status = oldData?.status?.value;
  const [noEndDate, setNoEndDate] = useState(false);
  const myForm = useRef(null);
  const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
  const user_id = "10";

  useEffect(() => {
    const fetchData = async () => {
      await postForm(
        `appointment/${id}`,
        null,
        null,
        null,
        false,
        false,
        handleSaveData,
        null,
        null,
        null,
        false,
      );
    };
    if (profileData) {
      fetchData();
    }
  }, [profileData]);

  useEffect(() => {
    if (oldData?.company_name) {
      document.title = `${t("web_portal_title_text")} - ${t("Appointment Details")} | ${oldData?.company_name}`;
    }
  }, [oldData?.company_name]);


  const handleSaveData = (resData) => {
    if (resData && id) {
      const is_principal = resData?.type === "PA";
      const letter_file = resData?.authLetter;
      let fileObject = {};
      if (letter_file) {
        fileObject = {
          name: letter_file?.fileName,
          type: letter_file?.fileType,
          size: letter_file?.fileSize,
          id: letter_file?.fileId,
          updated_at: letter_file?.fileUploadDate,
        };
      }
      const oldDataObject = {
        id: id,
        assign_company_name: !is_principal
          ? resData?.principalCorporateName
          : resData?.agentCorporateName,
        assign_email: !is_principal
          ? resData?.principalUserEmail
          : resData?.agentUserEmail,
        assign_officer_name: !is_principal
          ? resData?.principalUserName
          : resData?.agentUserName,
        company_name: !is_principal
          ? resData?.agentCorporateName
          : resData?.principalCorporateName,
        officer_name: !is_principal
          ? resData?.agentUserName
          : resData?.principalUserName,
        email: !is_principal
          ? resData?.agentUserEmail
          : resData?.principalUserEmail,
        reject_reason: resData?.rejectReason,

        start_date: resData?.startDate ? resData?.startDate : "N/A",
        end_date: resData?.endDate ? resData?.endDate : "N/A",
        remarks: resData?.remarks,
        status: resData?.status,

        auth_letter: fileObject,
        propertyCategoryList: resData?.propertyCategoryList,
      };
      setPrincipalToAgent(is_principal);
      setIsCreator(resData?.isCreator);
      setOriginalData(resData);
      setNoEndDate(!resData?.endDate);
      setData(oldDataObject);
      setOldData(oldDataObject);
      setChanged(false);
      setShowConfirmPopup(false);
      setShowConfirmTerminatePopup(false);
    }
  };

  const updateFields = (fields, setState = setData) =>
    setState((prev) => ({ ...prev, ...fields }));

  useDeepCompareEffect(() => {
    if (currentTab === 0 && !DeepEqual(data, oldData)) {
      setChanged(true);
    } else if (
      currentTab === 1 &&
      (!DeepEqual(viewOnlyState, oldViewOnlyState) ||
        !DeepEqual(editableState, oldEditableState))
    ) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [data, viewOnlyState, editableState]);

  const inputProps = (
    name,
    label,
    optional = false,
    disabled = false,
    action = null,
    type = "text"
  ) => ({
    name,
    inputLabel: label,
    data: data ? data[name] : "",
    error: error ? error[name] : "",
    optional,
    action,
    type,
    disabled,
    lng,
    setError,
    updateFields,
    t,
  });

  const getCheckboxStates = () => {
    if (originalData?.propertyCategoryList) {
      const initializeCheckboxStates = (category) => {
        setOldViewOnlyState((prevState) => ({
          ...prevState,
          [category.id]: category.permission === view_only,
        }));
        setOldEditableState((prevState) => ({
          ...prevState,
          [category.id]: category.permission === editable,
        }));
        setViewOnlyState((prevState) => ({
          ...prevState,
          [category.id]: category.permission === view_only,
        }));
        setEditableState((prevState) => ({
          ...prevState,
          [category.id]: category.permission === editable,
        }));
        if (category.children) {
          category.children.forEach(initializeCheckboxStates);
        }
      };
      originalData.propertyCategoryList.forEach(initializeCheckboxStates);
    }
  };

  useEffect(() => {
    getCheckboxStates();
  }, [originalData?.propertyCategoryList]);

  const options = [
    { name: "view_only_", stateName: viewOnlyState },
    { name: "editable_", stateName: editableState },
  ];

  const columns = useMemo(() => {
    const cols = [
      {
        accessorKey: "name",
        header: t("Category"),
        minSize: 450,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <div className={row.original.parent ? "ml-[-40px]" : ""}>
              {CategoryColumn(
                row,
                cell,
                profileData?.accountType?.value,
                row.original.rowType !== "parent"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "propertyTotal",
        header: t("No. of Property"),
        maxSize: 200,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return <div className="label1 text-body">{cell.getValue()}</div>;
        },
      },
    ];

    if (originalData?.enableEdit) {
      cols.push(
        {
          accessorKey: "view_only",
          header: t("View Only"),
          size: 140,
          enableSorting: false,
          className: "text-center",
          Cell: ({ cell, row }) => {
            return (
              <div className="pr-big">
                {row.original.canEdit
                  ? TableTreeCheckbox(
                      row,
                      cell,
                      setViewOnlyState,
                      viewOnlyState,
                      `view_only_${row.original.id}`,
                      false,
                      row.original.permission === view_only,
                      options,
                      false
                    )
                  : "-"}
              </div>
            );
          },
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
        },
        {
          accessorKey: "editable",
          header: t("Editable"),
          size: 140,
          enableSorting: false,
          Cell: ({ cell, row }) => {
            return (
              <div className="pr-lg">
                {row.original.canEdit
                  ? TableTreeCheckbox(
                      row,
                      cell,
                      setEditableState,
                      editableState,
                      `editable_${row.original.id}`,
                      false,
                      row.original.permission === editable,
                      options,
                      false
                    )
                  : "-"}
              </div>
            );
          },
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
          },
        }
      );
    } else {
      cols.push({
        accessorKey: "permission",
        header: t("Access"),
        maxSize: 200,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          if (cell.getValue() > 0) {
            return AccessIcon(row, cell, true);
          } else {
            return <div className="text-body label1">-</div>;
          }
        },
      });
    }

    return cols;
  }, [
    lng,
    profileData,
    data?.propertyCategoryList,
    originalData,
    setViewOnlyState,
    viewOnlyState,
    setEditableState,
    editableState,
  ]);

  const { CatTableTemplate, CategoryColumn, AccessIcon, TableTreeCheckbox } =
    CategoryTable({ rows: data?.propertyCategoryList, columns, t });

  const handleRemove = () => {
    return false;
  };

  const deleteBtn = () => {
    return <DeleteWithTextButton t={t} label="Delete" action={handleRemove} />;
  };

  const submitForm = (event, updatestatus = false) => {
    event.preventDefault();
    let slug = "";
    let withFile = false;
    let showFailAlert = currentTab === 1;
    let formData = new FormData();

    const update_api = () => {
      slug = "status/update";
      formData.append("id", id);
      formData.append("status", activeIndex);
      if (activeIndex === Number(rejected)) {
        formData.append("reject_reason", data?.reject_reason);
      }
    };

    // if (updatestatus) {
    //   updateFields({ auth_letter: "" });
    //   setShowConfirmPopup(false);
    //   update_api();
    // }

    const getCategoryPermissionList = () => {
      return Object.keys(viewOnlyState).map((category_id) => ({
        category_id,
        permission: viewOnlyState[category_id]
          ? view_only
          : editableState[category_id]
          ? editable
          : "0",
      }));
    };

    if (
      (updatestatus &&
        (originalData?.enableTerminate || originalData?.enableCancel)) ||
      originalData?.enableAcceptance
    ) {
      update_api();
    } else if (originalData?.enableEdit) {
      const category_list = getCategoryPermissionList();
      const dataObj = {
        id: id,
        remarks: data?.remarks,
        start_date: data?.start_date,
        end_date:
          data?.end_date === "N/A"
            ? ""
            : data?.end_date,
        category_list,
      };

      formData.append("data", JSON.stringify(dataObj));
      const file_name = "auth_letter";
      const file = data?.[file_name];
      if (file && Object.entries(file)?.length && !file.id)
        formData.append(file_name, file);
      slug = "update";
      withFile = true;
    }

    postForm(
      api_name,
      slug,
      formData,
      setError,
      null,
      false,
      handleSaveData,
      null,
      null,
      null,
      true,
      showFailAlert,
      handleFailData,
      withFile
    );
  };

  const handleFailData = (resData) => {
    if (resData.message) {
      sweetFailAlert(resData, t);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      (data.agent_id && data.agent_id !== oldData.agent_id) ||
      (data.email && data.email !== oldData.email)
    ) {
      event.preventDefault();
      setShowConfirmPopup(true);
    } else {
      submitForm(event, false);
    }
  };

  const setToDefaultValue = () => {
    setData(oldData);
    getCheckboxStates();
    setNoEndDate(oldData?.end_date === "N/A");
    setViewOnlyState(oldViewOnlyState);
    setEditableState(oldEditableState);
    setChanged(false);
  };

  const headData = () => (
    <div className="profile-field bg-white rounded-big flex flex-col gap-xxx-big">
      <ProfileSection
        title={`${id} - ${oldData?.company_name}`}
        t={t}
      >
        <div className="flex gap-sm flex-col md:flex-row md:items-center">
          <span
            className="bg-surface-primary py-xx-sm px-sm rounded-md text-white label2 w-fit items-center flex"
            style={{ lineHeight: "normal" }}
          >
            {changeDate(oldData?.start_date)} - {changeDate(oldData?.end_date)}
          </span>
          <BasicTag
            label={oldData?.status?.[`label${lang}`]}
            color={oldData?.status?.color}
          />
        </div>
        <div></div>
        {originalData?.rejectDate ||
        ((status === expired || status === requested) && isCreator) ? (
          <div className="flex gap-md flex-col">
            <div className="text-body label1">
              {status === rejected && originalData?.rejectDate
                ? t('Appointment rejected at') + `: ${originalData?.rejectDate}`
                : (status === expired || status === requested) && isCreator
                ? t('Appointment sent at') + `: ${originalData?.createDate}`
                : null}
            </div>
            {status === rejected && (
              <div className="text-body label1">
                Reason for Rejection:{" "}
                {originalData.rejectReason ? originalData.rejectReason : "--"}
              </div>
            )}
          </div>
        ) : null}
      </ProfileSection>
    </div>
  );

  const appointmentRequest = () => {
    const title = principalToAgent ? principal_title : agent_title;
    const switchTitle = principalToAgent ? agent_title : principal_title;
    return (
      <>
        <Name
          inputProps={inputProps}
          t={t}
          title={title}
          company_name="company_name"
          user_name="officer_name"
        />
        <Line my="my-x-big" />
        <ProfileSection title={switchTitle} t={t}>
          <div className="flex gap-sm md:gap-x-big">
            <AgentSelect
              inputProps={inputProps}
              data={data}
              is_principal={principalToAgent}
              disabled={true}
            />
          </div>
        </ProfileSection>
      </>
    );
  };

  const appointmentActive = () => {
    return isCreator ? (
      <>
        <Name
          inputProps={inputProps}
          t={t}
          title={principal_title}
          company_name={
            principalToAgent ? "company_name" : "assign_company_name"
          }
          user_name={principalToAgent ? "officer_name" : "assign_officer_name"}
        />
        <Line my="my-x-big" />
        <SwitchAgent
          inputProps={inputProps}
          is_principal={principalToAgent}
          t={t}
          data={data}
        />
      </>
    ) : (
      <>
        <Name
          inputProps={inputProps}
          t={t}
          title={principal_title}
          company_name={
            principalToAgent ? "company_name" : "assign_company_name"
          }
          user_name={principalToAgent ? "officer_name" : "assign_officer_name"}
        />
        <Line my="my-x-big" />
        <Name
          inputProps={inputProps}
          t={t}
          title={agent_title}
          company_name={
            principalToAgent ? "assign_company_name" : "company_name"
          }
          user_name={principalToAgent ? "assign_officer_name" : "officer_name"}
        />
      </>
    );
  };

  const appointmentDetail = () => {
    return (
      <>
        {status === requested ? appointmentRequest() : appointmentActive()}
        <Line my="my-x-big" />
        <EffectPeriod
          inputProps={inputProps}
          t={t}
          setNoEndDate={setNoEndDate}
          noEndDate={noEndDate}
          status={status}
          showEndDateButton={originalData?.enableEdit}
          disabled={!originalData?.enableEdit}
          updateFields={updateFields}
        />
        <Line my="my-x-big" />
        <ProfileSection title="Other" t={t}>
          <Input
            {...inputProps(
              "remarks",
              "Remarks",
              null,
              !originalData?.enableEdit
            )}
          />
        </ProfileSection>
      </>
    );
  };

  const authorisationletter = () =>
    getFileLoading ? (
      <CircularProgress />
    ) : !originalData?.enableEdit ? (
      <ProfileSection title="Authorisation Letter" t={t}>
        <UploadFilePreview
          t={t}
          data={data?.auth_letter}
          document_type={data?.document_type}
          disabled={true}
        />
      </ProfileSection>
    ) : (
      <FileDropzone
        // uploadButtonText={!data?.isAuthorised ? 'Upload' : null}
        title="Authorisation Letter"
        name="auth_letter"
        updateFields={updateFields}
        data={data ? data?.auth_letter : {}}
        error={error ? error["auth_letter"] : ""}
        type="image"
        numberOfUploading={1}
        fileSize={5}
        setError={setError}
        disabled={!originalData?.enableEdit}
        t={t}
        apiProps={{
          downloadApiProps: {
            downloadApi: `appointment/${id}/auth-letter`,
          },
          // document_type: originalData?.isAuthorised ?.data?.status,
        }}
        showDeleteButton={originalData?.enableEdit}
        // showDeleteButton={false}
      />
    );

  const createdByDetails = () => {
    const details = [
      {
        label: "Company Name",
        data: data?.company_name,
      },
      {
        label: "Officer Name",
        data: data?.officer_name + ` (${data?.email})`,
      },
      {
        label: "Effective Period",
        data: `${changeDate(data?.start_date)} - ${changeDate(data?.end_date)}`,
        borderTop: true,
      },
      {
        label: "Received At",
        data: originalData?.createDate,
        borderTop: true,
      },
      {
        label: "Remarks",
        data: data?.remarks || "N/A",
        borderTop: true,
      },
      {
        label: "Authorisation Letter",
        data: (
          <UploadFilePreview
            t={t}
            data={data?.auth_letter}
            handleDeleteFile={false}
            disabled={true}
            downloadApiProps={{ downloadApi: `appointment/${id}/auth-letter` }}
          />
        ),
        borderTop: true,
      },
    ];

    return (
      <ProfileSection
        title={
          principalToAgent ? "Created by Principal User" : "Created by Agent"
        }
        t={t}
      >
        {details &&
          details.map((item, index) => {
            return (
              <div
                className={`flex gap-sm md:gap-big py-md justify-between md:justify-start max-w-full flex-col md:flex-row ${
                  item.borderTop
                    ? "border-t border-neutral-100 border-dashed"
                    : ""
                }`}
                key={index}
              >
                <div className="w-full md:w-240px body2 text-title">
                  {item.label}
                </div>
                <div className="text-body label1 break-words whitespace-normal text-start md:text-end">
                  {item.data ?? "--"}
                </div>
              </div>
            );
          })}
      </ProfileSection>
    );
  };

  const acceptance = () => {
    return (
      <ProfileSection title="Acceptance" t={t}>
        <Switcher
          {...inputProps("acceptance")}
          options={[
            { label: "Accept Appointment", value: 4 },
            { label: "Reject Appointment", value: 3, icon: <CloseIcon /> },
          ]}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          activeColor={
            activeIndex === Number(active)
              ? "bg-label-icon-positive"
              : "bg-red-600"
          }
          hoverColor={
            activeIndex === Number(active) ? "bg-green-800" : "bg-red-800"
          }
        />
        {activeIndex === Number(rejected) && (
          <Input {...inputProps("reject_reason", "Reason for Rejection")} />
        )}
      </ProfileSection>
    );
  };

  const propertyCategories = () => {
    return CatTableTemplate("Property Categories");
  };

  let tab = [];
  tab = [
    {
      label: "Appointment Details",
      field:
        isCreator || status === active
          ? [appointmentDetail(), authorisationletter()]
          : [
              createdByDetails(),
              originalData?.enableAcceptance ? acceptance() : null,
            ],
    },
  ];
  if (data?.propertyCategoryList?.length) {
    tab.push({
      label: "Property Categories",
      field: propertyCategories(),
    });
  }

  const popupProps = (title, setState, text) => ({
    apiProps: {
      setError,
      customSubmit: (event) => submitForm(event, true),
      loading,
    },
    t,
    setState,
    title,
    text,
    lng,
    confirm_popup: true,
  });

  if (userLoading) return <LoadingUI />;

  return (
    <>
      <Breadcrumb lng={lng} currentElement={oldData?.company_name} pervElement={{
        skip: true,
        position: 2,
      }} />
      {showConfirmPopup && (
        <Popup
          {...popupProps(
              t("Confirm to change agent?"),
            setShowConfirmPopup,
          t("Once the agent is changed, the appointment status and property category access will be updated accordingly.")
          )}
        />
      )}
      {showConfirmTerminatePopup && (
        <Popup
          {...popupProps(
            t("Confirm to Terminate?"),
            setShowConfirmTerminatePopup,
            isCreator
              ? t("Once the appointment is terminated, you will no longer share those properties with your Principal or Agent.")
              : t("Once the appointment is terminated, you will no longer have access to those properties shared via this appointment.")
          )}
        />
      )}
      <form onSubmit={handleSubmit} className="w-full" ref={myForm}>
        <AccountManagementTemplate
          lng={lng}
          accountType={account_type}
          setError={setError}
          //   button_bar={isCreator && <DeleteWithTextButton t={t} />}
          title="Appointment Details"
        >
          <TabTemplate
            lng={lng}
            title="My Profile"
            headData={headData()}
            tab={tab}
            changed={changed}
            // backButton={(currentTab !== 1  && (user_id === data.created_by) || (user_id !== data.created_by && status !== active)) && (user_id !== data.created_by ? <OutlineBackButton t={t} href={`/${lng}/appointment-management`} /> : <DiscardButton t={t} action={setToDefaultValue} />)}
            // saveButton={(currentTab !== 1  && (user_id === data.created_by) || (user_id !== data.created_by && status !== active)) && (user_id !== data.created_by ? "Confirm" : status === expired || status === rejected || status === inactive ? "Resend" : "Save Changes")}
            backButton={
              originalData?.enableTerminate || originalData?.enableCancel ? (
                <OutlineButton
                  t={t}
                  red={true}
                  label="Terminate"
                  action={() => {
                    setShowConfirmTerminatePopup(true);
                    setActiveIndex(
                      originalData?.enableTerminate
                        ? terminate
                        : originalData?.enableCancel
                        ? cancel
                        : null
                    );
                  }}
                />
              ) : (
                <OutlineBackButton
                  t={t}
                  href={`/${lng}/agent-appointment`}
                  label="Cancel"
                />
              )
            }
            discardButton={
              originalData?.enableEdit ? (
                <DiscardButton t={t} action={setToDefaultValue} />
              ) : null
            }
            saveButton={
              (originalData?.enableEdit || originalData?.enableAcceptance) &&
              (!isCreator
                ? "Confirm"
                : status === expired ||
                  status === rejected ||
                  status === inactive
                ? "Resend"
                : "Save Changes")
            }
            loading={loading}
            setToDefaultValue={setToDefaultValue}
            setCurrentTab={setCurrentTab}
          />
        </AccountManagementTemplate>
      </form>
    </>
  );
};

export default AppointmentDetails;
