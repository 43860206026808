import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import tcLogo from "../assets/images/header/tc_logo.png";
import wpTcLogo from "../assets/images/header/tc_wplogo.png";
import scLogo from "../assets/images/header/sc_logo.png";
import wpScLogo from "../assets/images/header/sc_wplogo.png";
import logo from "../assets/images/header/en_logo.png";
import wpLogo from "../assets/images/header/en_wplogo.png";
import React from "react";

const useLogo = () => {
    const { t, i18n:{language} } = useTranslation();
    const rvd_home_url = t("rvd_home_url");
    const width = '200px'
    const wp_logo_width = '160px'
    switch (language) {
        case "tc":
            return (
                <div className="flex gap-sm items-center">
                    <Link to={rvd_home_url} className={`rvd-logo flex-1 max-w-[${width}]`}>
                        <img src={tcLogo} alt="差餉物業估價署" width="100%" />
                    </Link>
                    <hr className="vertical" />
                    <Link to={`/`} className={`wp-logo flex-1`} style={{ maxWidth: wp_logo_width }}>
                        <img src={wpTcLogo} alt="差餉物業估價署電子服務平台" width="100%"/>
                    </Link>
                </div>
            );
        case "sc":
            return (
                <div className="flex gap-sm items-center">
                    <Link to={rvd_home_url} className={`rvd-logo flex-1 max-w-[${width}]`}>
                        <img src={scLogo} alt="差饷物业估价署" width="100%" />
                    </Link>
                    <hr className="vertical" />
                    <Link to={`/`} className={`wp-logo flex-1`}  style={{ maxWidth: wp_logo_width }}>
                        <img src={wpScLogo} alt="差饷物业估价署电子服务平台" width="100%" />
                    </Link>
                </div>
            );
        default:
            return (
                <div className="flex gap-sm items-center">
                    <Link to={rvd_home_url} className={`rvd-logo flex-1 max-w-[${width}]`}>
                        <img src={logo} alt="Rating and Valuation Department" width="100%" />
                    </Link>
                    <hr className="vertical" />
                    <Link to={`/`} className={`wp-logo flex-1`} style={{ maxWidth: wp_logo_width }}>
                        <img src={wpLogo} alt="Rating and Valuation Department Web Portal" width="100%" />
                    </Link>
                </div>
            );
    }
};

export default useLogo;