import React, { useState, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import SubmitAndFetch from "../function/SubmitAndFetch";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../button/SingleButton";
const SearchPopupTable = ({ data, columns, apiProps, tableProps }) => {
    const { t, i18n: { language } } = useTranslation();
    const { postForm } = SubmitAndFetch({ lng: language, t });

    const handleFetchData = async (resData) => {
        if (resData) {
            const currentPage = tableProps.paginationModel.pageIndex;
            const lastIndex = tableProps.selectRow
                .filter(row => row.page === currentPage)
                .reduce((maxIndex, row) => Math.max(maxIndex, row.index || 0), -1);
            const newResData = resData?.list.map((item, idx) => ({
                ...item,
                page: currentPage,
                haveSelected: tableProps.selectRow.some(row => row.assessmentNo === item.assessmentNo),
                index: lastIndex + idx + 1
            }));

            apiProps?.setData(newResData);
            apiProps?.setIsFetching(false);
            tableProps?.setPaginationModel(prevModel => ({
                ...prevModel,
                rowCount: resData?.total,
                totalPages: resData?.pages,
            }));
        }
    };

    useEffect(() => {
        const search = async () => {
            apiProps?.formData.append('page_size', tableProps?.paginationModel.pageSize);
            apiProps?.formData.append('page_num', tableProps?.paginationModel.pageIndex + 1);
            await postForm('property/search', null, apiProps?.formData, apiProps.setError, null, null, handleFetchData, apiProps.token, null, apiProps?.setPopupLoading, false);
        };

        if (apiProps?.isFetching) {
            search();
        }

    }, [apiProps?.isFetching]);

    const handlePageChange = (event) => {
        let page = Number(event.target.value) - 1;
        page = Math.max(0, Math.min(page, tableProps?.paginationModel.totalPages - 1));
        tableProps?.setPaginationModel((prevModel) => ({
            ...prevModel,
            pageIndex: page,
        }));
        apiProps?.setIsFetching(true);
    };

    const customBottomToolBar = () => {
        const { rowCount, totalPages, pageIndex, pageSize } = tableProps?.paginationModel;
        const from = pageSize ? pageIndex * pageSize + 1 : 0;
        const to = rowCount ? Math.min(rowCount, (pageIndex + 1) * pageSize) : 1;

        return (
            <div className="bg-surface-card p-sm border-t border-default m-10px flex justify-between items-center">
                {tableProps?.totalSelectRow < 2000 &&
                    <div className="w-fit">
                        <PrimaryButton t={t} label="Add All" action={
                            tableProps?.selectAll} />
                    </div>
                }
                <div className="label2 text-800 flex items-center ms-auto">
                    {rowCount > 1 && (
                        <>
                            <span className="pr-md border-r border-default h-lg flex items-center">{t('Show')} {from} - {to} {t('of')} {rowCount || 1}</span>
                            <div className="flex gap-sm items-center">
                                <span className="md:pl-md text-body label2">{t('Go to page')}:</span>
                                <input
                                    className="defualt-number-input focus:outline-0 w-[81px]"
                                    type="number"
                                    value={pageIndex + 1}
                                    onChange={handlePageChange}
                                    min="1"
                                    max={totalPages || 1}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const table = useMaterialReactTable({
        columns,
        data,
        enableTableHead: false,
        initialState: {
            columnVisibility: {
                'mrt-row-expand': false,
            },
        },
        muiPaginationProps: {
            showRowsPerPage: false,
            showFirstButton: false,
            showLastButton: false,
        },
        paginationDisplayMode: 'custom',
        muiTableBodyRowProps: {
            sx: {
                "&:hover": { backgroundColor: "var(--surface-secondary) !important" },
                "&:hover td:after": { backgroundColor: "var(--surface-secondary) !important" },
                minHeight: '51px',
                "& td:first-of-type": { borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" },
                "& td:last-child": { borderTopRightRadius: "10px", borderBottomRightRadius: "10px" },
                borderRadius: "var(--radius-sm)",
                border: '0',
                background: 'var(--surface-card)',
                transition: 'none',
            },
        },
        muiTableBodyProps: {
            className: 'custom-scrollbar body1 text-body',
            sx: {
                minHeight: '67px',
                maxHeight: '306px',
                padding: 'var(--spacing-sm)',
                background: 'var(--surface-card)',
                borderRadius: 'var(--radius-md)',
                overflowY: 'auto',
            },
        },
        muiTableBodyCellProps: {
            className: 'body2',
            sx: {
                padding: '0 var(--spacing-big)',
                borderBottom: '0',
                borderRadius: 'var(--radius-md)',
            },
        },
        muiTablePaperProps: {
            elevation: 0,
            sx: {
                background: 'var(--surface-card)',
                border: '0',
                borderRadius: 'var(--radius-sm)',
                boxShadow: 'var(--box-shadow-2xl)',
            },
        },
        muiCircularProgressProps: {
            sx: { color: 'var(--surface-primary)' },
            thickness: 5,
            size: 55,
        },
        muiSkeletonProps: {
            animation: 'pulse',
            height: 28,
        },
        state: {
            isLoading: tableProps?.loading,
        },
        enableTopToolbar: false,
        enableBottomToolbar: tableProps?.paginationModel.rowCount > 1,
        enableColumnActions: false,
        enableExpanding: true,
        enableExpandAll: false,
        enableRowActions: false,
        enableHiding: true,
        layoutMode: 'grid',
        paginateExpandedRows: false,
        positionActionsColumn: 'last',
        renderEmptyRowsFallback: () => tableProps?.emptyMessage,
        renderBottomToolbar: customBottomToolBar,
    });

    return tableProps?.showSearchBox && (
        <div>
            <MaterialReactTable table={table} />
        </div>
    );
};

export default SearchPopupTable;
