import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import changeStepForms from "../../components/step/ChangeStepForms";
import StepOne from "../../components/registration_form/StepOne";
import StepTwo from "../../components/registration_form/StepTwo";
import StepThree from "../../components/registration_form/StepThree";
import StepFour from "../../components/registration_form/StepFour";
import StepFive from "../../components/registration_form/StepFive";
import StepSix from "../../components/registration_form/StepSix";
import formFields from "../../data/form_field.json";
import stepThreeFields from "../../data/step_three_fields.json";
import stepFiveFields from "../../data/step_five_fieds.json";
import StepFormTemplate from "../../components/form/StepFormTemplate";
import MtCaptchaScript from "../../components/MtCaptcha/MtCaptchaScript";
import FormHeaderTemplate from "../../components/form/FormHeaderTemplate";
import useDocumentTitle from "../../hooks/useDocument";

const stepsLabel = [
  "Input Particular",
  "Register Account",
  "Upload Proof",
  "Review/Submit",
];

const Registration = ({ lng }) => {
  const { t  } = useTranslation();
  const [disabledBtn, setDisableBtn] = useState(false);
  const [activeIndex, setActiveIndex] = useState(2);
  const [proofIndex, setProofIndex] = useState(1);
  const [data, setData] = useState(formFields);
  const [stepTwoData, setStepTwoData] = useState({
    privacy_policy: "",
    captcha_verify_code: "",
  });
  const [stepThreeData, setStepThreeData] = useState(stepThreeFields);
  const [stepFiveData, setStepFiveData] = useState(stepFiveFields);
  const [error, setError] = useState({});
  const [loadingCapthcha, setLoadingCapthcha] = useState(false);
  useDocumentTitle("Sign Up");

  const { loadMtCaptcha } = MtCaptchaScript({
    loadingCapthcha,
    setLoadingCapthcha,
  });
  const api_name = "register";
  const updateFields = (fields, setState) => {
    setState((prev) => ({ ...prev, ...fields }));
  };

  const updateFormData = (fields) => updateFields(fields, setData);
  const updateStepTwoFormData = (fields) =>
    updateFields(fields, setStepTwoData);
  const updateStepThreeFormData = (fields) =>
    updateFields(fields, setStepThreeData);
  const updateStepFiveFormData = (fields) =>
    updateFields(fields, setStepFiveData);

  const removeData = [
    "captcha_verify_code",
    "email_verification_code",
    "mobile_verification_code",
    "alternative_verification_code",
  ];

  let formData = {
    ...stepThreeData,
    ...data,
    ...stepFiveData,
  };

  removeData.forEach((key) => {
    delete formData[key];
  });

  const clearAlldata = (data_option) => {
    if (currentStepIndex === 2) {
      if (data_option && data_option?.length) {
        data_option.forEach((key, value) => {
          if (key in stepThreeData) stepThreeData[key] = "";
          if (key in stepFiveData) stepFiveData[key] = "";
          if (key in data) data[key] = "";
        });
      } else {
        setStepThreeData(stepThreeFields);
        setStepFiveData(stepFiveFields);
        setData(formFields);
        setProofIndex(1);
        setActiveIndex(1);
      }
    }
  };

  useEffect(() => {
    loadMtCaptcha();
  }, []);

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    changeStepForms([
      <StepOne key="importantNotes" t={t} />,
      <StepTwo
        disabledBtn={disabledBtn}
        setDisableBtn={setDisableBtn}
        key="accountRegistration"
        updateFields={updateStepTwoFormData}
        data={stepTwoData}
        error={error}
        setError={setError}
        t={t}
        loadingCapthcha={loadingCapthcha}
      />,
      <StepThree
        setActiveIndex={setActiveIndex}
        active={activeIndex}
        setProofIndex={setProofIndex}
        proofIndex={proofIndex}
        key="accountRegistrationForm"
        updateFields={updateStepThreeFormData}
        data={stepThreeData}
        clearAlldata={clearAlldata}
        error={error}
        setError={setError}
        t={t}
        lng={lng}
        api_name={api_name}
      />,
      <StepFour
        active={activeIndex}
        key="createAccountForm"
        updateFields={updateFormData}
        data={data}
        error={error}
        setError={setError}
        t={t}
      />,
      <StepFive
        active={proofIndex}
        key="uploadProofForm"
        updateFields={updateStepFiveFormData}
        data={stepFiveData}
        account_type={activeIndex}
        error={error}
        setError={setError}
        clearAlldata={clearAlldata}
        t={t}
        lng={lng}
      />,
      <StepSix
        key="confirmSubmission"
        active={activeIndex}
        data={data}
        stepThreeData={stepThreeData}
        stepFiveData={stepFiveData}
        error={error}
        t={t}
        lng={lng}
      />,
    ]);

  useEffect(() => {
    if (currentStepIndex === 1 && !stepTwoData["privacy_policy"]) {
      setDisableBtn(true);
    }
  }, [currentStepIndex]);

  const renderStepLabels = () =>
    stepsLabel.map((label, index) => {
      const newIndex = index + 2;
      return (
        <div
          className="flex step-group flex-col text-center col-span-1 items-center"
          id={"step" + newIndex}
          key={index}
        >
          <div>
            <p
              className={`label2 block h-10 ${
                currentStepIndex === newIndex ? "text-body" : "text-placeholder"
              }`}
            >
              {t(label)}
            </p>
          </div>
          <div className="relative">
            <div
              className={`bg-50 rounded-full border w-42px h-42px flex justify-center items-center ${
                currentStepIndex === newIndex
                  ? "text-headline border-highlight bg-surface-secondary"
                  : currentStepIndex > newIndex
                  ? "text-headline border-highlight bg-surface-primary"
                  : "border-placeholder"
              }`}
            >
              {currentStepIndex > newIndex ? (
                <DoneIcon className="text-white" />
              ) : (
                <span className="label1">{index + 1}</span>
              )}
            </div>
            {newIndex < steps.length - 1 && (
              <div className="step-line absolute"></div>
            )}
          </div>
        </div>
      );
    });

  const renderMobileHeader = () => (
    <div className="flex md:hidden items-center mb-lg justify-between">
      <div className="bg-surface-secondary border-highlight border rounded-50px w-85px h-42px flex justify-center items-center">
        <span className="label1 text-headline">
          {lng === 'tc' || lng === 'sc'
              ? `${stepsLabel.length} 分之 ${currentStepIndex - 1}`
              : `${currentStepIndex - 1} of ${stepsLabel.length}`
          }
        </span>
      </div>
      <div className="flex flex-col text-end">
        {!isLastStep && (
          <span className="text-placeholder">
           {t('_REGISTARTION_Next')}: {t(stepsLabel[currentStepIndex - 1])}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <div className="bg-50">
      <div className="md:py-xxx-lg layout-container flex justify-center">
        <div className="registration-container">
          {currentStepIndex > 1 && (
            <>
              {/* Desktop */}
              <div className="step hidden md:grid grid-cols-4 mb-xxx-big">
                {renderStepLabels()}
              </div>
              {/* Mobile */}
              {renderMobileHeader()}
              <FormHeaderTemplate
                title={stepsLabel?.[currentStepIndex - 2]}
                subtitle="Please review the following information before registering an account."
                t={t}
              />
            </>
          )}

          <StepFormTemplate
            setError={setError}
            formData={formData}
            step={step}
            isLastStep={isLastStep}
            back={back}
            next={next}
            currentStepIndex={currentStepIndex}
            isFirstStep={isFirstStep}
            disabledBtn={disabledBtn}
            api_name={api_name}
            back_path="/login"
            type="registration"
            lng={lng}
            redirect_path="/successfully?type=registration"
            captchaStepData={{
              data:stepTwoData["captcha_verify_code"],
              name:'captcha_verify_code',
            }}
            updateStep2Fields={updateStepTwoFormData}
            appendFile={
              currentStepIndex === 4 || currentStepIndex === 5
                ? ["confirmation_letter_file", "identity_proof_file"]
                : []
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Registration;
