import TextHeadline from "../../components/TextHeadline";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../components/header/Breadcrumb";
import {Link, useSearchParams} from "react-router-dom";
import { SmallHelpCentreQuickLinkCard } from "../../components/help-centre/HelpCentreQuickLink";
import HelpCentreSearchBar from "../../components/help-centre/HelpCentreSearchBar";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import React, {useEffect, useState} from "react";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import Pagination from "../../components/table/Pagination";
import useDocumentTitle from "../../hooks/useDocument";

const SearchResult = ({ lng }) => {
    const { t } = useTranslation();
    const lang = lng ? lng[0].toUpperCase() + lng.slice(1) : '';
    const [searchParams] = useSearchParams();
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isFetching, setIsFetching] = useState(true);
    const [paginationModel, setPaginationModel] = useState({
        pageIndex: 0,
        pageSize: 4,
    });
    const [results, setResults] = useState([]);
    const searchQuery = searchParams.get('search');
    const { smallSearchBar, cannotFind } = HelpCentreSearchBar({ t, lng });
    const { userLoading, token } = useUserLoginContext();
    const { postForm } = SubmitAndFetch({ lng, t});
    useDocumentTitle("Search result");

    useEffect(() => {
        const fetchData = async () => {
            await postForm('post/search', null, {
                keyword: searchQuery,
                page_num: paginationModel.pageIndex + 1,
                page_size: paginationModel.pageSize
            }, null, null, false, saveSearchData, token, null, null, false)
        }
        if (token && searchQuery || isFetching && searchQuery) {
            fetchData();
        }
        setIsFetching(false);
    }, [token, searchQuery, isFetching]);

    const saveSearchData = (resData) => {
        if (resData) {
            setTotalRows(resData?.total);
            setTotalPages(resData?.pages);
            setResults(resData.list);
        }
    }

    if (userLoading) return <LoadingUI/>;

    return (
        <>
            <Breadcrumb
                lng={lng}
            />
            <div className='layout-container content-container'>
                <TextHeadline title={t('Here’s what we found for') + ` “${searchQuery}”`} />
                <div className='mt-lg grid grid-cols-1 lg:grid-cols-12 gap-lg'>
                    <div className="block lg:hidden">
                        {smallSearchBar()}
                    </div>
                    <div className='lg:col-span-8 bg-surface-card p-md md:p-xxx-big rounded-big relative'>
                        <div className='label1 text-title pb-x-big border-b border-default'>
                            {totalRows} {t('articles found')}
                        </div>
                        <div className='mt-x-big flex flex-col gap-xxx-big pb-[30px]'>
                            {
                                results?.length ? results?.map(item => {
                                    return (
                                        <div className='flex flex-col gap-[6px]'>
                                            <Link key={item.id} to={`/${lng}/help-centre/articles/details/${item.id}`} className='w-fit'>
                                                <h2 className="subtitle text-headline hover:underline">{item?.[`title${lang}`]}</h2>
                                            </Link>
                                                <div dangerouslySetInnerHTML={{__html: item?.[`content${lang}`]}}
                                                     className="body2 text-caption line-clamp-2"></div>

                                        </div>
                                    )
                                }) : null
                            }
                        </div>
                        {results?.length && totalRows > 4 ?
                        <div className='relative bottom-md w-11/12'>
                            <Pagination paginationModel={paginationModel} setPaginationModel={setPaginationModel}
                                        totalPages={totalPages} totalRows={totalRows} setIsRefetching={setIsFetching}/>
                        </div>
                        : null}
                    </div>
                    <div className='lg:col-span-4'>
                        <div className="hidden lg:block">
                            {smallSearchBar()}
                        </div>
                        <SmallHelpCentreQuickLinkCard lng={lng} t={t} />
                    </div>
                </div>
            </div>
            {cannotFind()}
        </>
    );
}

export default SearchResult;