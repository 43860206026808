import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useUserLoginContext } from "./UserLoginProvider";
import LoadingUI from "../components/loading/LoadingUI";
import USER_TYPE from "../data/variable/user_type.json";
import { useTranslation } from "react-i18next";

const AdminRoute = ({ children }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const { account_type, userLoading, admin } = useUserLoginContext();
  useEffect(() => {
    if (!userLoading && !admin) {
      navigate(`/${language}/403`);
    }
  }, [userLoading, admin]);

  if (userLoading) return <LoadingUI />;

  return account_type === USER_TYPE.ADMIN ? <Outlet /> : null;
};

export default AdminRoute;
