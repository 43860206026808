import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useUserLoginContext } from "./UserLoginProvider";
import LoadingUI from "../components/loading/LoadingUI";
import USER_TYPE from "../data/variable/user_type.json";
import { useTranslation } from "react-i18next";

const AdminRoute = ({ children }) => {
    const {
        i18n: { language },
    } = useTranslation();
    const navigate = useNavigate();
    const { account_type, userLoading } = useUserLoginContext();
    useEffect(() => {
        if (!userLoading && account_type !== USER_TYPE.ADMIN && account_type !== USER_TYPE.OFFICER) {
            navigate(`/${language}/403`);
        }
    }, [userLoading]);

    if (userLoading) return <LoadingUI />;

    return account_type === USER_TYPE.ADMIN || account_type === USER_TYPE.OFFICER ? <Outlet /> : null;
};

export default AdminRoute;
