import { ReactComponent as GettingStartedSVG } from "../../assets/images/help-centre/Getting_Started.svg";
import { ReactComponent as ArticlesSVG } from "../../assets/images/help-centre/Articles.svg";
import { ReactComponent as FAQsSVG } from "../../assets/images/help-centre/FAQs.svg";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";

const QuickLinkList = (w, h, lng) => {
    return (
        [
            {label: 'Getting Started', icon: <GettingStartedSVG width={w} height={h}/>, link: `/${lng}/help-centre/getting-started`},
            {label: 'Articles', icon: <ArticlesSVG width={w} height={h}/>, link: `/${lng}/help-centre/articles`},
            {label: 'FAQs', icon: <FAQsSVG width={w} height={h}/>, link: `/${lng}/help-centre/faqs`},
        ]
    )
}

export const BigHelpCentreQuickLinkCard = ({ lng, t }) => {
    return (
        <div className='grid-cols-1 md:grid-cols-3 grid gap-xxx-big'>
            <style>{`
                .help_quick_link_link svg path {
                    fill: var(--surface-primary);
                 }
                .help_quick_link_link:hover{
                    transform: translateY(-10px);
                    transition: transform 0.3s ease;
                    box-shadow: var(--box-shadow-lg);
                }
                .help_quick_link_link:hover{
                    transition: transform 0.3s ease;
                }
                  
            `}</style>
            {QuickLinkList(64, 64, lng).map((item, index) => {
                return (
                    <Link key={index} className='help_quick_link_link bg-surface-card rounded-big py-xxx-big px-md flex items-center flex-col gap-x-big' to={item.link}>
                        {item.icon}
                        <span className='label1'>{t(item.label)}</span>
                    </Link>
                )
            })}
        </div>
    )
}

export const SmallHelpCentreQuickLinkCard = ({ lng, t }) => {
    return (
        <div className='flex flex-col md:flex-row lg:flex-col gap-sm my-x-big'>
            <style>{`
              .help_quick_link_sm svg path {
                fill: var(--surface-primary);
              }
              .help_quick_link_sm:hover .label1 {
                color: var(--surface-primary);
              }
           `}</style>
            {QuickLinkList(24, 24, lng).map((item, index) => {
                return (
                    <Link className='flex-1 help_quick_link_sm bg-surface-card rounded-sm py-x-big px-big flex gap-sm' key={index} to={item.link}>
                        {item.icon}
                        <span className='label1 text-600'>{t(item.label)}</span>
                    </Link>
                )
            })}
        </div>
    )
}