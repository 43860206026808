import { ReactComponent as SearchSVG } from "../../assets/images/others/search.svg";
import { PrimaryButton, PrimaryLink } from "../button/SingleButton";
import SubmitAndFetch from "../function/SubmitAndFetch";
import { useNavigate } from "react-router-dom";

const HelpCentreSearchBar = ({ t, lng, width='800px' }) => {
    const { postForm, loading } = SubmitAndFetch({ lng, t });
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        navigate(`/${lng}/help-centre/search-result?search=${formData.get('search_data')}`);
    }

    const bigSearchBar = () => (
        <form onSubmit={handleSubmit} className={`w-full mt-xx-big lg:w-[${width}] relative`}>
            <label className='w-full h-full'>
                <input className='help_centre_search_input p-xx-sm pl-60px rounded-full w-full h-[56px] border-default border bg-white placeholder:text-placeholder pe-[130px]' placeholder={t('keyword search')} name='search_data'/>
                <SearchSVG style={{ fill: 'var(--Neutral-800)', top: '17px', width: '24px', left: '20px'}} className='absolute w-[24px] h-[24px]'/>
                <div className="w-fit absolute right-[4px] top-[4px]">
                    <PrimaryButton t={t} label='Search' type='submit' loading={loading} sx={{ borderRadius: 'var(--radius-full)' }}/>
                </div>
            </label>
        </form>
    )

    const smallSearchBar = () => (
        <form onSubmit={handleSubmit} className={`w-full relative`}>
            <label className='w-full h-full'>
                <input className='help_centre_search_input p-xx-sm pl-60px rounded-full w-full h-[56px] shadow-md bg-white placeholder:text-placeholder pe-[110px]' placeholder={t('keyword search')} name='search_data'/>
                <SearchSVG style={{ fill: 'var(--Neutral-800)', top: '17px', width: '24px', left: '20px'}} className='absolute w-[24px] h-[24px]'/>
                <div className="absolute right-[4px] top-[4px]">
                    <PrimaryButton t={t} label='Search' type='submit' loading={loading} sx={{ borderRadius: 'var(--radius-full)' }}/>
                </div>
            </label>
        </form>
    )

    const cannotFind = () => (
        <div className="bg-surface-card">
            <div className='layout-container py-xx-lg flex flex-col items-center text-center gap-x-big'>
                <h3 className='h3'>{t('Can’t find your answer?')}</h3>
                <PrimaryLink label='Contact Us' href={`https://www.rvd.gov.hk/${lng}/contact_us/index.html`} t={t}/>
            </div>
        </div>
    )

    return {
        bigSearchBar,
        smallSearchBar,
        cannotFind
    }
}

export default HelpCentreSearchBar;