import WhiteSelect from "../../components/input/portal/WhiteSelect";
import WhiteInput from "../../components/input/portal/WhiteInput";
import { PrimaryButton, DiscardButton } from "../../components/button/SingleButton";

const AssessmentAndTRNSearch = ({ searchBy, t, loading, setSearchBy, cancelAction, data, updateFields }) => {
    const handleSearchBy = (value) => {
        if (value !== searchBy) {
            updateFields({ assessment_no: '', trn: '' });
        }
        setSearchBy(value);
    }

    const searchByOptions = [
        { label: 'Assessment No.', value: 1 },
        { label: 'Transaction Reference No.', value: 2 },
    ];

    const TRNInput = () => (
        <WhiteInput name="trn" t={t} placeholder="Please enter TRN" data={data ? data['trn'] : ''} updateFields={updateFields} />
    );

    const AssessmentNoInput = () => (
        <WhiteInput name="assessment_no" t={t} placeholder="Please enter Assessment Number" data={data ? data['assessment_no'] : ''} updateFields={updateFields} />
    );

    return (
        <>
            <div className="flex gap-sm items-center flex-col md:flex-row">
                <div className="w-full md:max-w-[312px]">
                    <WhiteSelect options={searchByOptions} placeholder="Search" name='search_by' defaultValue={searchBy} action={handleSearchBy}/>
                </div>
                <div className="w-full md:max-w-[700px] flex-grow flex-1">
                    {searchBy === 1 && AssessmentNoInput()}
                    {searchBy === 2 && TRNInput()}
                </div>
                <div className="flex gap-sm me-auto md:me-0">
                    <PrimaryButton t={t} label='Search' loading={loading} type='submit' className='w-[108px]' disabled={
                        searchBy === 1 ?
                        !data?.['assessment_no'] || data?.['assessment_no'] === '' :
                            searchBy === 2 ?
                                !data?.['trn'] || data?.['trn'] === '' :
                            true
                    }/>
                    <DiscardButton t={t} label='Reset' action={cancelAction} className='w-[108px]'/>
                </div>
            </div>
        </>
    )
}

export default AssessmentAndTRNSearch;