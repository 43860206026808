import TextHeadline from "../TextHeadline";
import { DeleteWithTextButton } from "../button/SingleButton";
import React from "react";
import {useTranslation} from "react-i18next";

const ArticleTemplates = ({ title, subtitle, buttonBar, children }) => {
    const { t, i18n: {language} } = useTranslation();
    const lang = language.charAt(0).toUpperCase() + language.slice(1);
    return (
        <div className="layout-container content-container">
            <div className='w-full max-w-[944px] mx-auto'>
                <div className={`flex flex-col md:flex-row justify-between items-baseline ${buttonBar ? 'gap-md' : ''}`}>
                    <div className="flex flex-col gap-x-sm">
                        <TextHeadline title={title} />
                        {subtitle ? subtitle : null}
                    </div>
                    {buttonBar? buttonBar : null}
                </div>
                { children }
            </div>
        </div>
    )
}

export default ArticleTemplates;