import NoteTemplate from '../../components/registration_form/NoteTemplate'
import FormHeaderTemplate from '../../components/form/FormHeaderTemplate';
import React from "react";
import {useTranslation} from "react-i18next";
const StepOne = ({ t }) => {
    const {i18n: {language}} = useTranslation();
    const sampleCofirmationLetterFileName = "Company_Confirmation_Letter_Sample.docx";

    const en = () => (
        <>
            <h2 className='font-bold underline'>For Company/Organisation user: </h2>
            <ol className='list-decimal ms-lg'>
                <li>You are registering as the first administrator account for your company/organisation.</li>
                <li>Please prepare the following documents for account registration:
                    <ol className='list-[lower-alpha] ms-lg'>
                        <li>Your Business Registration Certificate*;</li>
                        <li>A confirmation letter with company/organisation chop and authorized signature (<a
                            href={`${process.env.PUBLIC_URL}/sample_company_confirmation_letter.docx`} download={sampleCofirmationLetterFileName}
                            className='text-surface-primary hover:underline'>See sample</a>)*; and
                        </li>
                        <li>Latest quarterly “Demand for rates and/or Government Rent” of one property</li>
                    </ol>
                </li>
                <li>Registration for the first administrator account of a company/organisation account is subject to
                    the Department’s approval. Upon acceptance of account registration, the login details and
                    authentication code will be sent to the registered email address for activation of account.
                </li>
            </ol>
            <br/>
            <p>* Please prepare an image file (file size not exceeding 5MB) (format: jpg, pdf, png) for each
                document. The uploaded image must be clear to show all the information. Password-protected, blurred
                or masked image file will not be accepted.
            </p>
        </>
    )

    const tc = () => (
        <>
            <h2 className='font-bold underline'>公司／機構帳戶</h2>
            <ol className='list-decimal ms-lg'>
                <li>你正在為你的公司／機構申請第一個（管理員）帳戶。 </li>
                <li>請準備下列文件作帳戶登記︰
                    <ol className='list-[lower-alpha] ms-lg'>
                        <li>商業登記證*;</li>
                        <li>確認函連同公司 / 機構印章或授權簽署 (<a
                            href={`${process.env.PUBLIC_URL}/sample_company_confirmation_letter_tc.docx`}
                            download={sampleCofirmationLetterFileName}
                            className='text-surface-primary hover:underline'>見樣本</a>)*; 及
                        </li>
                        <li>一個物業的最新季度「徵收差餉及／或地租通知書」。</li>
                    </ol>
                </li>
                <li>公司／機構帳戶的首個管理員帳戶的登記申請須經本署批核。帳戶的登記申請獲得接納後，相關登入資料及驗證碼將會發送至已登記電郵地址，以供啟動帳戶之用。
                </li>
            </ol>
            <br/>
            <p>*請為每份文件準備一個圖像檔（檔案大小上限為5MB）（格式：jpg, pdf, png）。上傳圖像必須完整和清晰顯示所有資料，圖像檔如設有密碼、模糊或遮蓋將不獲接納。</p>
        </>
    )

    const sc = () => (
        <>
            <h2 className='font-bold underline'>公司／机构帐户</h2>
            <ol className='list-decimal ms-lg'>
                <li>你正在为你的公司／机构申请第一个（管理员）帐户。 </li>
                <li>请准备下列文件作帐户登记︰
                    <ol className='list-[lower-alpha] ms-lg'>
                        <li>商业登记证*;</li>
                        <li>确认函连同公司 / 机构印章或授权签署 (<a
                            href={`${process.env.PUBLIC_URL}/sample_company_confirmation_letter_sc.docx`} download={sampleCofirmationLetterFileName}
                            className='text-surface-primary hover:underline'>见样本</a>)*; 及
                        </li>
                        <li>一个物业的最新季度「征收差饷及／或地租通知书」。 </li>
                    </ol>
                </li>
                <li>公司／机构帐户的首个管理员帐户的登记申请须经本署批核。帐户的登记申请获得接纳后，相关登入资料及验证码将会发送至已登记电邮地址，以供启动帐户之用。
                </li>
            </ol>
            <br/>
            <p>*请为每份文件准备一个图像档（档案大小上限为5MB）（格式：jpg, pdf, png）。上传图像必须完整和清晰显示所有资料，图像档如设有密码、模糊或遮盖将不获接纳。 </p>
        </>
    )

    return (
        <>
            <FormHeaderTemplate title="Important Notes"
                                subtitle="Please read the important notes before registering a new account." t={t}/>
            <NoteTemplate t={t}>
                {language === 'tc' ? tc() : language === 'sc' ? sc() : en()}
            </NoteTemplate>
        </>
    )
}

export default StepOne;