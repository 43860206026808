import Breadcrumb from "../../components/header/Breadcrumb";
import React from "react";
import {useTranslation} from "react-i18next";
const ErrorPageTemplate = ({ svg, title, text, breadcrumbText, button }) => {
    const { t, i18n:{language} } = useTranslation();
    return (
        <>
            <Breadcrumb lng={language} currentElement={breadcrumbText ? breadcrumbText : "Error"} />
            <div className="layout-container min-h-[69vh]">
                <div className="content-container flex justify-center items-center flex-col">
                    <div className="mb-lg">
                        {svg ? svg : null}
                    </div>
                    <div className="flex gap-sm flex-col text-center">
                        <h1 className="h1">{title ? t(title) : null}</h1>
                        <p className="body2">{text ? t(text) : null}</p>
                    </div>
                    <div className="mt-x-big">
                        {button ? button : null}
                    </div>
                </div>
            </div>
        </>

    );
}

export default ErrorPageTemplate;