import { ReactComponent as Logo } from "../../assets/images/header/en_logo.svg";
import { ReactComponent as TCLogo } from "../../assets/images/header/tc_logo.svg";
import { ReactComponent as SCLogo } from "../../assets/images/header/sc_logo.svg";
import { ReactComponent as MobileLogo } from "../../assets/images/header/mobile_logo.svg";
import background from "../../assets/images/login/login_bg.png";
import React, {useState} from "react";
import LanguageAndFontSize from "../../components/header/LanguageAndFontSize";
import { useTranslation } from "react-i18next";
import Input from "../../components/input/Input";
import Password from "../../components/input/Password";
import Checkbox from "../../components/input/Checkbox";
import {
  IAmSmartButton,
  PrimaryButton,
} from "../../components/button/SingleButton";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import ErrorMessage from "../../components/error/ErrorMessage";
import useDocumentTitle from "../../hooks/useDocument";
import Popup from "../../components/popup/Popup";
import {Link, useNavigate} from "react-router-dom";
import SmallFunction from "../../components/function/SmallFunction";
import useRemoveError from "../../hooks/useRemoveError";
import useResetDataThemeOnPageChange from "../../hooks/useResetDataThemeOnPageChange";
import useLogo from "../../hooks/useLogo";

const Login = ({ lng }) => {
  const {
    t,
  } = useTranslation();
  const [isRemoveOtherSessions, setIsRemoveOtherSessions ] = useState("0");
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const {sweetSuccessAlert} = SmallFunction();
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState({is_remember: '1'});
  useDocumentTitle("Login");
  useRemoveError(setError);
  useResetDataThemeOnPageChange(null)

  const updateFields = (fields => {
    setData(prevData => ({ ...prevData, ...fields }));
  });

  const successAction = (resData, withoutData) => {
    if (resData) {
      if (resData?.profile && !resData?.isLoggedInOtherSession) {
        setIsRemoveOtherSessions('0');
        setShowLoginPopup(false);
        localStorage.setItem("userData", JSON.stringify(resData?.profile));
        sweetSuccessAlert(withoutData, t).then(() => {
            navigate(`/${lng}/`);
        })
      } else {
        setShowLoginPopup(true);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    await postForm(
      "login",
      null,
      formData,
      setError,
      null,
      false,
      successAction,
      null,
      null,
      null,
      false
    );
  };
  const handlePopupSubmit = async (event) => {
    event.preventDefault();
    setIsRemoveOtherSessions('1');
    const formData = new FormData();
    Object.entries(data).map(([key, value]) => {
      formData.append(key, value);
    })
    formData.append('is_remove_other_sessions', '1');
    await postForm(
        "login",
        null,
        formData,
        setError,
        null,
        false,
        successAction,
        null,
        null,
        null,
        false
    );
  }

  const inputProps = (name, inputLabel) => ({
    name,
    inputLabel,
    t,
    error: error ? error[name] : "",
    setError,
    updateFields,
  });

  const PopupProps = (title, setState, text ) => ({
    setState,
    t,
    title,
    text,
    confirm_popup: true,
    apiProps: {
      setError,
      lng,
      customSubmit: handlePopupSubmit,
      loading,
    },
  });

  return (
    <div id='login-page'>
      {showLoginPopup ?
          <Popup
            {...PopupProps(
                t("Concurrent Login Detected"),
                setShowLoginPopup,
                t("Your account has been logging in. Are you sure to log out the account for another login?")
            )}/>
      : null}
      <div
          className="login-container min-h-screen min-w-screen flex"
          style={{
            background: `url(${background}) center`,
            backgroundSize: "cover",
          }}
      >
        <div
            className="login-left flex flex-col gap-xxx-big min-h-screen p-md items-center md:px-xx-lg lg:pt-xx-lg lg:rounded-tr-lg lg:rounded-br-lg lg:pb-x-big lg:px-huge backdrop-blur-blur-30px w-full lg:max-w-800px"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.80)" }}
        >
          <div className="flex justify-center w-full lg:block">
            <div className="flex flex-col gap-lg w-full">
              <div className="w-full flex flex-wrap justify-between gap-md">
                {useLogo()}
              </div>
              <div className="flex flex-col gap-sm">
                <div className='flex items-center'>
                  <h2 className="h2">{t("Login")}</h2>
                  <div className="inline-flex ml-auto lg:hidden">
                    <LanguageAndFontSize/>
                  </div>
                </div>
                <p className="body1">{t("Welcome to the RVD Web Portal (for company users only)")}</p>
              </div>
              <form className="flex flex-col gap-xx-big" onSubmit={handleSubmit}>
                <div className="flex flex-col gap-xx-big">
                  <div className="flex flex-col gap-big">
                    <Input {...inputProps("username", "Username/Email")} />
                    <Password
                        {...inputProps("password", "Password")}
                        showEyeIcon={true}
                    />
                    {error && error.message && (
                        <ErrorMessage message={error.message} t={t} />
                    )}
                  </div>
                  <div className="flex gap-md label1 flex-col md:flex-row md:justify-between md:items-center">
                    <Checkbox
                        {...inputProps("is_remember", "Remember Me")}
                        check={"1"}
                        itemStart={false}
                        labelClass="label1"
                    />
                    <input type='hidden' name='is_remove_other_sessions' value={isRemoveOtherSessions}/>
                    <div className="label1">
                      {t("Forgot")}&nbsp;
                      <a
                          href={`/portal/${lng}/forgot-username`}
                          className="text-surface-primary hover:text-surface-primary-hover"
                      >
                        {t("Username")}
                      </a>
                      &nbsp;{t("or")}&nbsp;
                      <a
                          href={`/portal/${lng}/forgot-password`}
                          className="text-surface-primary hover:text-surface-primary-hover"
                      >
                        {t("Password")}
                      </a>
                      ?
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-sm links">
                  <PrimaryButton
                      t={t}
                      type="submit"
                      loading={loading}
                      label="Login"
                      className="w-full ms-0"
                  />
                  {/*<IAmSmartButton t={t} href="" label="Continue with iAM Smart" />*/}
                  <a
                      className="outline-btn line-height whitespace-nowrap rounded-sm flex items-center justify-center gap-x-sm"
                      href={`/portal/${lng}/activate-account`}
                  >
                      <span className="label1 leading-none">
                        {t("Activate Account")}
                      </span>
                  </a>
                </div>
              </form>
              <div className="flex flex-col gap-sm text-center links mt-xxx-big">
                <p className="body1">{t("Don't have an account")}?</p>
                <a
                    className="label1 blue-50-btn"
                    href={`/portal/${lng}/registration`}
                >
                  {t("Sign Up Now")}
                </a>
              </div>
              <div className="flex gap-sm flex-col">
                <div
                    style={{ width: "100%", height: "1px", background: "#737373" }}
                ></div>
                <p className="caption1 text-caption">
                  {t("2024 © Rating and Valuation Department")} |{" "}
                  {t("Last revision date: 31 October 2024")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="login-right flex-1 hidden lg:block lg:pt-xx-lg lg:pb-x-big lg:pr-xx-huge ml-lg">
          <div className="w-full flex justify-end ">
            <LanguageAndFontSize />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
