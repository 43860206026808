import React, { useEffect, useState} from 'react';
import { useCookies } from 'react-cookie'
import { ReactComponent as CalendarSVG } from '../../assets/images/home/calendar_icon.svg';
import welcomeBg from '../../assets/images/home/welcomeBg.png';
import homeBgTop from '../../assets/images/home/homeBgTop.png';
import homeBgBottom from '../../assets/images/home/homeBgBottom.png';
import aboutBg from '../../assets/images/home/aboutBg.png';
import HomeSwiper from "../../components/home/HomeSwiper";
import QuickLinkCard from '../../components/home/QuickLinkCard';
import { useTranslation } from "react-i18next";
import { useUserLoginContext} from "../../context/UserLoginProvider";
import { useShowPopupContext } from "../../context/ShowPopupProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import {LoadingImageSkeleton, LoadingTextSkeleton} from "../../components/loading/LoadingSkeleton";
import { RedForwardButton, PrimaryLink } from "../../components/button/SingleButton";
import SmallFunction from '../../components/function/SmallFunction'
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import EmptyDataText from "../../components/empty/EmptyDataText"
import { Link } from 'react-router-dom';
import useDocumentTitle from "../../hooks/useDocument";

const Home = ({ lng }) => {
    const [showPopup, setShowPopup] = useShowPopupContext();
    const [loadingNew, setLoadingNew] = useState(true);
    const [loadingAnnouncement, setLoadingAnnouncement] = useState(true);
    const [loadingQuickLink, setLoadingQuickLink] = useState(true);
    const [newsData, setNewsData] = useState([]);
    const [announcementsData, setAnnouncementsData] = useState([]);
    const [quickLinkData, setQuickLinkData] = useState([]);
    const { t } = useTranslation();
    const { changeDate } = SmallFunction();
    const { fetchData } = SubmitAndFetch({ lng, t });
    const [cookies] = useCookies(['close-popup'])
    const { profileData, userLoading, token } = useUserLoginContext();
    const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
    useDocumentTitle("Home");

    useEffect(() => {
        const getData = async () => {
            await fetchData('news', null, setNewsData, setLoadingNew, lng, token);
            await fetchData('announcement', null, setAnnouncementsData, setLoadingAnnouncement, lng, token);
            await fetchData('quicklink', null, setQuickLinkData, setLoadingQuickLink, lng, token);
        }
        getData();
    }, [token]);

    if (userLoading) return <LoadingUI/>;

    return (
        <section className="home relative" style={{
            background: `linear-gradient(180deg, rgba(226, 227, 229, 0.11) 24.56%, var(--surface-tertiary) 106.43%), url(${homeBgTop}) top no-repeat`,
            backgroundSize: 'contain',
        }}>
            <style>
                {
                    `
                      .home-welcome-box:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url(${welcomeBg});
                        background-size: 100%;
                        background-position: bottom;
                        background-repeat: no-repeat;
                        opacity: 0.1;
                      }
                      .home-about-box:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url(${aboutBg});
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        opacity: 1;
                        border-radius: var(--spacing-big);
                        filter: blur(2px);
                      }
                    `
                }
            </style>
            <div className="layout-container home-content content-container" style={{ background: `url(${homeBgBottom}) bottom no-repeat` }}>
                <div className="flex flex-col items-stretch gap-x-big md:gap-lg xl:flex-row">
                    <div className="flex flex-col w-full xl:min-w-[400px] xl:w-[400px] gap-x-big">
                        <div className="bg-surface-primary home-welcome-box flex flex-col justify-center home-box text-white relative">
                            <h2 className="h2">{t('Hello')} {profileData?.username},</h2>
                            <h3 className="h3">{t('Welcome to RVD Web Portal')}</h3>
                        </div>
                        <div className="bg-surface-card home-whats-new-box home-box">
                            <div className="home-card-title">
                                <h3 className="h3 md:pb-x-sm text-neutral-800">{t('What\'s New')}</h3>
                            </div>
                            <div className="border-b-2 md:border-b-4 border-default border-dashed my-sm md:my-md"></div>
                            <div className="home-card-body custom-scrollbar">
                                {loadingNew ?
                                    <LoadingTextSkeleton/>
                                    : newsData && newsData.length ? newsData.map((news, index) => {
                                    return (
                                        <div className="home-card-group" key={index}>
                                            <div className="label1 flex gap-x-sm items-center">
                                                <CalendarSVG width={24}/>
                                                <span className="home-date">{changeDate(news['postDate'])}</span>
                                            </div>
                                            <Link to={news?.[`externalLink${lang}`]} className="subtitle hover:text-surface-primary-hover">
                                                {news?.[`title${lang}`]}
                                            </Link>
                                        </div>
                                    )
                                })
                                    :
                                    <EmptyDataText text={t('No News Available')} />
                                }
                            </div>
                        </div>
                        <div className="bg-surface-card home-announcements home-box">
                            <HomeSwiper lng={lng} data={announcementsData} loading={loadingAnnouncement}/>
                        </div>
                        <div className="bg-surface-primary home-about-box home-box text-white hidden flex-col gap-xxx-big justify-center relative md:flex">
                            {/*<h2 className="h2 z-10">{t('Unlock the Potential of Property Assessment')}</h2>*/}
                            <RedForwardButton href={`https://www.rvd.gov.hk/${lng}/about_us/index.html`} label='About Us' t={t} blank={true} />
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:bg-surface-secondary rounded-big p-xx-sm md:p-xxx-big">
                        <div className="home-card-tool mb-xx-big flex items-center">
                            <h2 className="h2 text-headline">{t("Quick Links")}</h2>
                            <PrimaryLink t={t} href={`/${lng}/quick-links`} label='Edit' target={''} />
                        </div>
                        <div className="grid grid-cols-2 lg:grid-cols-12 gap-x-sm md:gap-xx-big">
                            {loadingQuickLink &&
                                Array.from({length: 8}, (_, i) => (
                                    <div key={i} className='col-span-1 md:col-span-6'>
                                        <LoadingImageSkeleton h={'170px'} w={'100%'} containerClassName={'image-container'}/>
                                    </div>
                                ))
                            }
                            {quickLinkData && quickLinkData.map((item, index) => {
                                if (item.id === '3' || item.id === '4' || item.id === '6') return;
                                return (
                                    <QuickLinkCard key={item.id} title={item?.[`title${lang}`]} link={`/${lng}/${item?.link}` || "#"}
                                                   alt={item?.[`title${lang}`]} image={item.iconPath}
                                                   loading={loadingQuickLink}/>
                                )
                            })}
                        </div>
                        {!loadingQuickLink && (!quickLinkData?.length || (quickLinkData && !quickLinkData?.length)) && (<EmptyDataText text={t('No Quick Link Available')} />)}
                    </div>
                    <div className="bg-surface-primary home-about-box home-box text-white flex flex-col gap-xxx-big justify-center relative md:hidden">
                        {/*<h2 className="h2 z-10">{t('Unlock the Potential of Property Assessment')}</h2>*/}
                        <RedForwardButton href='https://www.rvd.gov.hk/' t={t} blank={true} label='About Us' />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;