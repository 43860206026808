import { ReactComponent as LangIcon } from "../../assets/images/header/lang_icon.svg";
import { ReactComponent as DropDown } from "../../assets/images/arrow/arrow_drop_down.svg";
import { ReactComponent as FsIcon } from "../../assets/images/header/fs_icon.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useClickAway } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { useFontSizeContext } from "../../context/FontSizeProvider";
import SubmitAndFetch from "../function/SubmitAndFetch";
import { useUserLoginContext } from "../../context/UserLoginProvider";

const LanguageAndFontSize = ({ headVanMobile }) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const { profileData, setProfileData } = useUserLoginContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [showLangOptions, setShowLangOptions] = useState(false);
  const [mobileFsActive, setMobileFsActive] = useState(false);
  const [mobileLangActive, setMobileLangActive] = useState(false);
  const [fsSmallActive, setFsSmallActive] = useState(false);
  const [fsMiddleActive, setFsMiddleActive] = useState(false);
  const [fsLargeActive, setFsLargeActive] = useState(false);
  const [cookies, setCookies] = useCookies(["fontSize"]);
  const { setSmall, setMiddle, setLarge } = useFontSizeContext();
  const { postForm, loading } = SubmitAndFetch({ lng: language, t });
  const currentPath = location.pathname.split("/").slice(2).join("/");
  const query = location.search;
  const clickAwayHandler = () => setShowLangOptions(false);
  const optionRef = useClickAway(clickAwayHandler);

  const threeLanguages = {
    en: { key: "en", value: "Eng", id: "1" },
    tc: { key: "tc", value: "繁", id: "2" },
    sc: { key: "sc", value: "简", id: "3" },
  };

  // const callChangeLangAPI = async (id) => {
  //   await postForm(
  //     `lang/change/${id}`,
  //     null,
  //     null,
  //     null,
  //     null,
  //     false,
  //     saveData,
  //     null,
  //     null,
  //     null,
  //     false
  //   );
  // };

  const threeFontSize = [
    {
      size: "1rem",
      active: fsSmallActive,
      fun: setSmall,
      text: t("S_fs"),
      mobile: t("Small"),
    },
    {
      size: "1.2rem",
      active: fsMiddleActive,
      fun: setMiddle,
      text: t("M_fs"),
      mobile: t("Medium"),
    },
    {
      size: "1.3rem",
      active: fsLargeActive,
      fun: setLarge,
      text: t("L_fs"),
      mobile: t("Large"),
    },
  ];

  const toggleOptions = () => {
    setShowLangOptions((prev) => !prev);
  };

  const handleChangeLanguage = (lng) => {
    changeLanguage(lng);
    navigate(`/${lng}/${currentPath}${query}`);
  };

  const saveData = (newData) => {
    if (newData.profile) newData = newData.profile;
    setProfileData(newData);
    localStorage.setItem("userData", JSON.stringify(newData));
  };

  useEffect(() => {
    handleDisplayFontSize();
  }, []);

  const handleDisplayFontSize = () => {
    switch (cookies.fontSize) {
      case "small_size":
        setFsSmallActive(true);
        break;
      case "middle_size":
        setFsMiddleActive(true);
        break;
      case "large_size":
        setFsLargeActive(true);
        break;
      default:
        setFsMiddleActive(true);
    }
  };

  if (headVanMobile) {
    return (
      <>
        <div className="border border-s-0 border-e-0 border-light py-md">
          <div
            className="flex justify-between"
            onClick={() => setMobileFsActive((prevState) => !prevState)}
          >
            <div className="flex gap-x-sm">
              <LangIcon width="24" height="24" />
              <span className="label1 text-label-icon-on-primary">
                {threeLanguages[language]
                  ? threeLanguages[language].value
                  : threeLanguages.en.value}
              </span>
            </div>
            <div>
              <DropDown width="24" height="24" />
            </div>
          </div>
          <ul
            className={`mobile-nav-lang-fs-list text-label-icon-on-primary flex flex-col gap-x-sm ${
              mobileFsActive ? "active" : null
            }`}
          >
            {threeLanguages &&
              Object.values(threeLanguages)
                .filter((lng) => language !== lng.key)
                .map((lng, index) => {
                  return (
                    <li
                      className="rounded-sm py-xx-sm lang-nav-links w-full"
                      key={index}
                    >
                      <span
                        className="inline-block w-full cursor-pointer"
                        onClick={() => {
                          handleChangeLanguage(lng.key);
                        }}
                      >
                        <span style={{ paddingLeft: "35px" }}>{lng.value}</span>
                      </span>
                    </li>
                  );
                })}
          </ul>
        </div>
        <div className="border border-t-0 border-s-0 border-e-0 border-light py-md">
          <div
            className="flex justify-between"
            onClick={() => setMobileLangActive((prevState) => !prevState)}
          >
            <div className="flex gap-x-sm">
              <FsIcon width="24" height="24" />
              <span className="label1 text-label-icon-on-primary">
                {t("Font Size")}
              </span>
            </div>
            <div>
              <DropDown width="24" height="24" />
            </div>
          </div>
          <ul
            className={`mobile-nav-lang-fs-list text-label-icon-on-primary flex flex-col gap-x-sm ${
              mobileLangActive ? "active" : null
            }`}
          >
            {threeFontSize &&
              threeFontSize.map((option, index) => (
                <li
                  className="rounded-sm py-xx-sm lang-nav-links w-full"
                  key={index}
                >
                  <Link
                    to=""
                    className="inline-block w-full"
                    onClick={(event) => {
                      event.preventDefault();
                      setFsSmallActive(index === 0);
                      setFsMiddleActive(index === 1);
                      setFsLargeActive(index === 2);
                      option.fun();
                    }}
                  >
                    <span
                      style={{ fontSize: option.size, paddingLeft: "35px" }}
                    >
                      {option.mobile}
                    </span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  }

  return (
    <div className="header-lang-and-fs flex gap-md">
      <div className="header-fs relative flex flex-col items-center">
        <button className="flex bg-white">
          <FsIcon
            width="20"
            height="20"
            alt={t("Font Size")}
            title={t("Font Size")}
          />
        </button>
        <div className="header-options text-center">
          {threeFontSize &&
            threeFontSize.map((option, index) => (
              <Link
                key={index}
                to=""
                className={`header-options-items ${
                  option.active ? "active" : ""
                }`}
                onClick={(event) => {
                  event.preventDefault();
                  setFsSmallActive(index === 0);
                  setFsMiddleActive(index === 1);
                  setFsLargeActive(index === 2);
                  option.fun();
                }}
              >
                <div style={{ fontSize: option.size }}>{option.text}</div>
              </Link>
            ))}
        </div>
      </div>
      <div className="header-lang relative">
        <button
          className="flex bg-white"
          onClick={toggleOptions}
          title={t("language")}
        >
          <LangIcon width="20" height="20" alt={t("language")} />
          <span className="label2">
            {threeLanguages[language]
              ? threeLanguages[language].value
              : threeLanguages.en.value}
          </span>
          <DropDown width="20" height="20" />
        </button>
        <div
          className={`header-options text-center w-full ${
            showLangOptions && "active"
          }`}
          ref={optionRef}
          onClick={toggleOptions}
        >
          {threeLanguages &&
            Object.values(threeLanguages)
              .filter((lng) => language !== lng.key)
              .map((lng, index) => {
                return (
                  <span
                    className="header-options-items cursor-pointer"
                    key={index}
                    onClick={() => {
                      handleChangeLanguage(lng.key);
                    }}
                  >
                    <div>{lng.value}</div>
                  </span>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default LanguageAndFontSize;
