import Input from "../../components/input/Input";
import React, {useEffect, useState} from "react";
import DoneIcon from "@mui/icons-material/Done";
import {useTranslation} from "react-i18next";

const VerifyField = ({   name,
                         inputLabel,
                         show,
                         updateFields,
                         data,
                         disabled,
                         error,
                         parentData,
                         setFieldVerified,
                         isVerified=true,
                         uuid,
                         api_name,
                         setError,
                         codeError,
                         authorization,
                         start_counting=false,
                         slug,
                         additionalData,
                         t,
                     }) => {
    const [counter, setCounter] = useState(60);
    const [disable, setDisable] = useState( data || !show || start_counting);
    const [inputDisable, setInputDisable] = useState( false);
    const [startCounting, setStartCounting] = useState(start_counting);
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const {i18n: { language }} = useTranslation();
    let type = null;
    let api_after_slug_name = null;
    let parentName = Object.keys(parentData)[0];

    const apiType = {
        verify: 'verify',
        send: 'send',
    }

    const parentType = {
        email: 'email',
        email2: 'email2',
        mobile: 'mobile',
    }

    switch (parentName) {
        case "email":
            api_after_slug_name = parentType.email;
            type = parentType.email;
            break;
        case "email_alternative":
            api_after_slug_name = parentType.email2;
            type = parentType.email;
            break;
        default :
            api_after_slug_name = parentType.mobile;
            type = parentType.mobile;
            break;
    }

    const parent = { [type] : parentData[parentName] };
    const country_code = parentData.country_code || null;


    const postForm = async (value,formData, api_type) => {
        let newFormData= {};
        let newAdditionalData = {...additionalData};
        setError(preError => ({...preError, [name]: ''}));
        setError(preError => ({...preError, [parentName]: ''}));
        if (slug === 'password' && additionalData) {
            if(data?.['username']) newAdditionalData = {...newAdditionalData, 'username': data?.['username']}
            if(data?.['email_address_alternative']) newAdditionalData = {...newAdditionalData, 'email_address_alternative': data?.['email_address_alternative']}
        }

        newFormData = {
            ...formData,
            ...parent,
            ...(newAdditionalData ? newAdditionalData : {})
        };

        if (type === parentType.mobile || type === parentType.email || parentType.email2) {
            newFormData = {
                ...formData,
                ...parentData
            }
        }

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept-Language', language);
        if (authorization) myHeaders.append("Authorization", authorization);
        try{
            const res = await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/v1/${api_name}${slug ? '/' + slug : type === parentType.mobile ? '/sms' : '/' + api_after_slug_name}/${api_type}`, {
                method: "POST",
                body: JSON.stringify(newFormData),
                headers: myHeaders,
            });

            const resData = await res.json();

            setLoading(false);

            if (api_type === apiType.verify) {
                if (resData.code === 200) {
                    if (resData.data && updateFields) updateFields(resData.data)
                    if (updateFields) updateFields({ [name]: value })
                    setStartCounting(false)
                    setCounter(60);
                    if (setFieldVerified) {
                        setFieldVerified(true)
                    } else {
                        setVerified(true)
                        setInputDisable(true)
                    }
                } else {
                    if (!startCounting) {
                        setDisable(false)
                    }
                    if (updateFields) updateFields({ [name]: ""})
                    setInputDisable(false)
                    setVerified(false)
                }
            } else {
                if (resData.code === 200) {
                    if (updateFields) updateFields({ [`uuid_${parentName}`]: resData.data.uuid })
                } else {
                    setDisable(false)
                    setStartCounting(false)
                    setCounter(60);
                }
            }

            if (resData.code === 201) {
                if (resData.errors.code) {
                    setError(preError => ({...preError, [name]: resData.errors[type]}));
                } else if (resData.errors[type]) {
                    setError(preError => ({...preError, [parentName]: resData.errors[type]}));
                } else {
                    setError(resData.errors)
                }
            } else if (resData.code !== 200){
                setError(preError => ({...preError, [name]: resData.message}));
            }
        } catch (error) {
            console.error("Error while fetching API:", error);
        } finally {
            setLoading(false);
            setInputDisable(false);
        }
    };


    useEffect(() => {
        if (!startCounting) {
            setDisable(!show);
        }
        setVerified(false);
        setInputDisable(false);
    }, [show])

    useEffect(() => {
        if (!startCounting) {
            setDisable(!show);
        }
        setVerified(false);
        setInputDisable(false);
    }, [parentData])

    useEffect(() => {
        if (!isVerified) {
            updateFields({ [name]: "" })
            return;
        }
        if (data) {
            setVerified(true);
            setInputDisable(true);
            setDisable(false);
        }
    }, [data])

    useEffect(()=> {
        if (!startCounting) return;
        const intervalId = setInterval(() => {
            if (counter === 1 ) {
                setCounter(60);
                setStartCounting(false);
                if (show) {
                    setDisable(false)
                }
            } else {
                setCounter((t) => t - 1)
            }
        }, 1000)
        return () => clearInterval(intervalId);
    }, [counter, startCounting])

    const handleCountdown = () => {
        let formData = {};
        if (setError) setError(preError => ({ ...preError, [name]: '' }));
        updateFields({ [name]: "" })
        setStartCounting(true);
        setDisable(true)
        postForm('',formData, apiType.send);

    }

    const handleVerifyCode = (value, setValue) => {
        if (value.trim().length === 6) {
           let formData = {
               'code': value,
               'uuid': uuid,
           }
            setLoading(true)
            setInputDisable(true)
            postForm(value,formData, apiType.verify);
            if (!verified) {
                setValue("");
            }
        }
    }

    const a11yProps = {
        name,
        type: "tel",
        inputLabel,
        updateFields,
        setError,
        data,
        disabled:(inputDisable || disabled),
        error:(error ? error : codeError),
        maxLength: "6",
        t,
    };

    return (
        <>
            {!data && (
                <div className="flex gap-sm flex-col lg:flex-row">
                    <Input action={handleVerifyCode} {...a11yProps} setError={setError} disabled={!uuid}/>
                    <div className='md:w-fit'>
                        <button className={`px-big ${verified ? 'verified-btn flex items-center' : 'get-code-btn primary-btn'}`}
                                disabled={loading || disable}
                                style={{minWidth: '130px', height: '56px'}}
                                onClick={handleCountdown}>
                            {verified && <DoneIcon fontSize="10px" style={{marginRight: "-5px"}}/>}
                            {startCounting ? loading ? t('Loading') : t('Resend') + ' ' + counter : verified ? t('Verified') : t("Get Code")}
                        </button>
                    </div>
                    <input type='hidden' name={`uuid_${parentName}`} value={uuid || ''}/>
                </div>
            )}
        </>
    )
}

export default VerifyField;