import NoteTemplate from '../../components/registration_form/NoteTemplate'
import FormHeaderTemplate from '../../components/form/FormHeaderTemplate';
import Checkbox from "../../components/input/Checkbox";
import MtCaptcha from "../../components/MtCaptcha/MtCaptcha";
import { useTranslation } from "react-i18next";
import React from "react";


const StepTwo = ({ disabledBtn, setDisableBtn, updateFields, data, error, loadingCapthcha, t }) => {
    const {i18n: {language}} = useTranslation();
    const en = () => (
        <div>
            <h2 className='font-bold'>General</h2>
            <br/>
            <p>1. The following are the terms and conditions for the use of RVD Web Portal (“the Web Portal”) of the Rating and Valuation Department (“the Department”).  The Department agrees to provide services of the Web Portal, and the Registered User of the Web Portal agrees to use the services in accordance with the terms and conditions set out herein.  </p>
            <br/>
            <p>2. In these terms and conditions: </p>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>“Registered Account” means a user account registered and created in the Web Portal, including Company/Organisation (Administrator) Account and Company/Organisation (Officer) Account.
                </li>
                <li>“Registered User" means a user holding a Registered Account. </li>
                <li>“Registered Company/Organisation” means a body corporate holding a Registered Account.
                </li>
            </ol>
            <br/>
            <h2 className='font-bold'>Services and functions</h2>
            <br/>
            <p>3. The Web Portal will, subject to these terms and conditions, enable the Registered User to perform the services and functions provided on the Web Portal from time to time to handle the matters related to the rates and Government rent assessments.  </p>
            <br/>
            <p>4. The Department reserves the right to make or change procedures for the use of any of the Web Portal services and add, modify, suspend or terminate any services and functions as necessary without recourse to seeking the prior agreement or giving prior notice to any user.</p>
            <br/>
            <h2 className='font-bold'>Registration and Creation of Account</h2>
            <br/>
            <p>5. Any business entity/organisation shall apply through the Web Portal for a Registered Account for accessing and using services of the Web Portal. The user undertakes to provide true and accurate information as required for the registration.</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li className='underline'>Company/Organisation Account</li>
                <ol className='ms-md list-[lower-roman]'>
                    <li>A body corporate such as a business entity holding a valid business registration certificate issued under section 6 of the Business Registration Ordinance (Cap. 310), or an organisation (e.g. universities, schools, statutory bodies); or a charitable institution under section 88 of the Inland Revenue Ordinance (Cap. 112), which provides valid billing information of at least one property, can register to create a Registered Account in the Web Portal. The registration has to be accompanied by a copy of the certificate of incorporation of the body corporate and a confirmation letter with company/organisation chop and authorised signature.
                    </li>
                    <br/>
                    <li className='underline'>Company/Organisation (Administrator) Account</li>
                    <br/>
                    <ul>
                        <li>(1) The first account created for a company/organisation will be a Company/Organisation (Administrator) Account (“the Administrator Account”). All Administrator Accounts in one Company/Organization Account will have same functions and rights of access to the information within the Account.
                        </li>
                    </ul>
                    <br/>
                    <li className='underline'>Company/Organisation (Officer) Account</li>
                    <br/>
                    <ul>
                        <li>(1) Company/Organisation (Officer) Account(s) (“the Officer Account”) can be created by the Administrator Account.  The Administrator Account can set up access control for Officer Accounts to the information within the Company/Organisation Account.
                        </li>
                    </ul>
                    <br/>
                    <li>Registration for Company/Organisation Account (the first Administrator Account) is subject to the Department’s approval. Subject to the company/organisation’s business needs, the Administrator Account may create and delete additional Administrator and Officer Account(s).
                    </li>
                    <br/>
                    <li>Opening of additional Company/Organisation Account(s) by the same company/organisation under different account names may be made by separate registration.
                    </li>
                </ol>
            </ol>
            <br/>
            <p>6. One billing account number can be registered for one Company/Organisation Account only. </p>
            <br/>
            <p>7. The Department reserves the right not to accept any registration with an inappropriate account name or information which is considered likely to be offensive to a reasonable person. </p>
            <br/>
            <h2 className='font-bold'>Login</h2>
            <br/>
            <p>8. The Registered User shall take appropriate measures to protect the secrecy of his/her login name and password. Any user who could key in both the unique login name and the correct password of the Registered Account shall be deemed to be an authorised user of the Registered account to use the Web Portal services, and the Registered User shall be liable for all acts or omissions of himself/herself or such user for all purposes of these terms and conditions. </p>
            <br/>
            <p>9. The Registered User must not allow two or more persons to access the Web Portal services concurrently with the same login name. If concurrent access to the Web Portal Services with the same login name is detected by the system, the operation on the screen of previous login user will be stopped or cancelled or otherwise blocked immediately without any prior warning. </p>
            <br/>
            <h2 className='font-bold'>Termination of a Registered Account</h2>
            <br/>
            <p>10. The Registered User may write to the Department to cease his/her Registered Account, which will then be inactivated permanently. The Department will retain the personal data of terminated/deleted accounts for not more than two years.</p>
            <br/>
            <p>11. The Department may terminate the Registered Account without prior notice in any one of the following circumstances:</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>Non-active Account: the Registered Account has not been used or logged in for a continuous period of five years; or the Registered Company/Organisation ceases to operate.
                </li>
                <li>For Breach: there is any breach of the terms and conditions herein by the Registered User.
                </li>
            </ol>
            <br/>
            <h2 className='font-bold'>Responsibilities</h2>
            <br/>
            <p>12. The Registered User shall only handle the applications or services in the Web Portal for the property(ies) in the capacity of an owner, an occupier, or an authorised agent for an owner/occupier. </p>
            <br/>
            <p>13. The Registered User shall update his/her account profile provided to the Web Portal upon any change of the correspondence address, contact telephone number, email address, or any other information in the account profile.</p>
            <br/>
            <p>14. For a Registered Company/Organisation, the Registered User of Administrator Account shall</p>
            <br/>
            <ol className="ms-lg list-[lower-alpha]">
                <li>ensure that there is at least one valid Administrator Account at all times to serve as a contact point with the Department;
                </li>
                <li>manage the Administrator Account(s) and Officer Account(s) of the company/organisation properly and delete the user accounts if the Registered User is no longer a staff of the company/organisation; and
                </li>
                <li>notify the Department in writing immediately when the Registered Company/Organisation ceases to operate.
                </li>
            </ol>
            <br/>
            <p>15. The Registered User shall not use or launch any automated system or program, including but not limited to, “robots”, “spiders”, “offline readers” etc., for access to the Web Portal.</p>
            <br/>
            <h2 className='font-bold'>Communication</h2>
            <br/>
            <p>16. The Registered User undertakes that any information, communication or material submitted to the Department through the Web Portal is true and accurate and not misleading, incomplete or false in any material respect.</p>
            <br/>
            <p>17. Any communication, including acknowledgements, notices and documents, sent to the Registered User by means of contact as recorded in the Web Portal or the notification box is deemed to have been sent to and subsequently received by the Registered User at the time of sending, unless the contrary is proved. All these communications will be retained for not more than three years from the issue date.</p>
            <br/>
            <p>18. The acknowledgements, notices and documents including but not limited to the e-Notice of Decision sent to the Registered User in Web Portal may serve as a softcopy for record only.  One may need to resort to the original printed hardcopy for the purposes of any proceedings.</p>
            <br/>
            <h2 className='font-bold'>Restriction on use of product and service provided</h2>
            <br/>
            <br/>
            <p>19. Unless express permission or licence is given in writing by the Department, the Department always retains its proprietary interest and copyright in the programming work or documentation of any product or service provided by the Web Portal. All products and services obtained by the Registered User shall not be stored or used for any purposes other than the Registered User’s own use. </p>
            <br/>
            <p>20. The Registered User shall not use the Web Portal services or any product or service obtained thereunder for any activity which is unlawful or illegal, or which is objectionable as determined by the Department absolutely. In particular, they shall not be used for any activity or purpose in violation of any provisions of the Personal Data (Privacy) Ordinance (Cap. 486), the Copyright Ordinance (Cap. 528) or any laws of Hong Kong. </p>
            <br/>
            <h2 className='font-bold'>Exemption Clause</h2>
            <br/>
            <p>21. The Web Portal is provided on an “as is” and “as available” basis. The Department shall not be liable to the Registered User or any third party for any loss or damages, whatsoever and howsoever, arising out of or in connection with his/her access to the Web Portal. Without limiting the generality of the foregoing statement, the Department shall not be responsible for any loss or damages arising out of or in connection with the following: </p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>Any breakdown, suspension, non-function, mal-function, or any other fault or interruption of operation of the Web Portal and its connected system, network, equipment, or device;
                </li>
                <li>Any suspension or delay in execution of any order or provision of any service under the Web Portal;
                </li>
                <li>Any damage caused to the hardware or software of the Registered User’s network, computer, modem, telephone system, equipment or other device as a result of the Registered User’s access to the Web Portal; and
                </li>
                <li>Any damages or loss caused by applying the provided information for any purposes other than rates and Government Rent assessments.
                </li>
            </ol>
            <br/>
            <p>22. The Web Portal is an electronic information system with connection to various networks provided by third parties. The Department shall be under no obligation to assist any Registered User to trace or locate the source of any of his/her complaint or alleged problem relating to the design or operation of the hardware or software of the Web Portal or its connected system, network, equipment and device, nor shall the Registered User be entitled to check or inspect any of them for such or other purposes (including any litigation purposes connecting with it).</p>
            <br/>
            <h2 className='font-bold'>Personal Information Collection Statement</h2>
            <br/>
            <p>23. During the account registration process, the following personal data will be collected from you: </p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>English and/or Chinese name; and</li>
                <li>Email address and/or phone number or fax number</li>
            </ol>
            <br/>
            <p>24. The provision of personal data is voluntary. However, if you fail to provide information as requested, the Department may not be able to process your application.</p>
            <br/>
            <p>25. The information provided by you will be used for the purposes of:</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>carrying out the provisions of the Rating Ordinance (Cap. 117), the Landlord and Tenant (Consolidation) Ordinance (Cap. 7) and the Government Rent (Assessment and Collection) Ordinance (Cap. 515);  and may be forwarded to the Commissioner of Inland Revenue for the assessment and collection of tax imposed under the Inland Revenue Ordinance (Cap. 112);
                </li>
                <li>carrying out activities relating to your application for services and the supply of services by the Department pursuant to these terms and conditions;
                </li>
                <li>performing account link up and/or single sign-on functions with the Department’s other electronic systems or electronic forms;
                </li>
                <li>facilitating communication between the Department and yourself; and </li>
                <li>producing statistics relating to the Department’s services. </li>
            </ol>
            <br/>
            <p>26. Apart from the purposes stated above, personal information will not be transferred to any other parties, unless such parties are authorised by law and request the information. </p>
            <br/>
            <p>27. Under the Personal Data (Privacy) Ordinance (Cap. 486), you have a right to request access to and correction of your personal data. Such requests may be made in writing to the Department's Customer Service Officer at 15/F, Cheung Sha Wan Government Offices, 303 Cheung Sha Wan Road, Kowloon or by e-mail to <a
                    className='text-surface-primary hover:underline'
                    href="mailto: enquiries@rvd.gov.hk">enquiries@rvd.gov.hk</a> .</p>
            <br/>
            <p>28. The Department may from time to time vary, modify, delete and/or add any clause in the terms and conditions at its own discretion. Your accessing and using of Web Portal is taken as you agreement to be bound by the terms and conditions as may be modified and/or supplemented from time to time by the Department without prior notice.</p>
            <br/>
            <p>29. These terms and conditions have been translated into Chinese. If there is any inconsistency or ambiguity between the English version and the Chinese version, the English version shall prevail. </p>
        </div>
    )

    const tc = () => (
        <div>
            <h2 className='font-bold'>一般條款及條件</h2>
            <br/>
            <p>1.	以下是差餉物業估價署電子服務平台（下稱「電子服務平台」）的使用條款及條件。差餉物業估價署（下稱「本署」）同意提供電子服務平台服務，而電子服務平台的登記用戶同意根據本文所載的條款及條件使用服務。 </p>
            <br/>
            <p>2.	就本文所載條款及條件而言： </p>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>「登記帳戶」指在電子服務平台登記和建立的用戶帳戶，包括公司／機構（管理員）帳戶和公司／機構（人員）帳戶。
                </li>
                <li>「登記用戶」指持有登記帳戶的用戶。</li>
                <li>「登記公司／機構」指持有登記帳戶的法人團體。</li>
            </ol>
            <br/>
            <h2 className='font-bold'>服務與功能</h2>
            <br/>
            <p>3.	電子服務平台將按照本文所載的條款及條件，容許登記用戶使用電子服務平台的服務與功能以處理差餉地租估價事宜。 </p>
            <br/>
            <p>4.	本署保留權力制訂或更改任何電子服務平台服務的使用程序，並在有需要時增加、修改、暫停或終止任何服務與功能，而無須事先取得任何用戶同意或通知任何用戶。 </p>
            <br/>
            <h2 className='font-bold'>登記和建立帳戶</h2>
            <br/>
            <p>5.	任何商業實體／機構須透過電子服務平台申請一個登記帳戶，方可接達和使用電子服務平台服務。用戶須承諾，在登記時提供的所需資料均屬真確無誤。</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li className='underline'>公司／機構帳戶</li>
                <ol className='ms-md list-[lower-roman]'>
                    <li>任何法人團體，例如持有根據《商業登記條例》（第 310 章）第 6 條發出的有效商業登記證的商業實體，或機構（例如大學、學校、法定機構）、或《稅務條例》（第112章）第88條所指屬公共性質的慈善機構，如能提供最少某一物業的有效帳單資料，均可在電子服務平台建立登記帳戶。登記時須一併提交該法人團體的法團或公司註冊證書副本，以及附有該公司／機構印鑑和授權簽署的確認書。
                    </li>
                    <br/>
                    <li className='underline'>公司／機構（管理員）帳戶</li>
                    <br/>
                    <ul>
                        <li>(1)	為公司／機構建立的首個帳戶將為公司／機構（管理員）帳戶（「管理員帳戶」）。一個公司／機構帳戶內的所有管理員帳戶具有相同功能和對該帳戶上的資訊有相同權限。
                        </li>
                    </ul>
                    <br/>
                    <li className='underline'>公司／機構（人員）帳戶 </li>
                    <br/>
                    <ul>
                        <li>(1)	管理員帳戶可建立一個／多個公司／機構（人員）帳戶（「人員帳戶」）。管理員帳戶可為人員帳戶設置該公司／機構上的資訊權限控制。
                        </li>
                    </ul>
                    <br/>
                    <li>公司／機構帳戶（首個管理員帳戶）的登記申請須經本署批核。公司／機構可視乎業務需要，管理員帳戶可建立和刪除額外一個／多個管理員及人員帳戶。
                    </li>
                    <br/>
                    <li>同一公司／機構如欲以不同帳戶名稱開設一個／多個額外公司／機構帳戶，可另行登記。
                    </li>
                </ol>
            </ol>
            <br/>
            <p>6.	一個帳單帳目編號只可供一個公司／機構帳戶作登記用途。 </p>
            <br/>
            <p>7.	如帳戶的名稱或資料被視為不恰當，可能會令合情理的人反感，則本署保留權利拒絕接納該帳戶的登記申請。</p>
            <br/>
            <h2 className='font-bold'>登入</h2>
            <br/>
            <p>8.	登記用戶須採取適當措施把登入名稱和密碼保密。任何用戶如一併輸入登記帳戶獨有的登入名稱和密碼，則會被視為登記帳戶的獲授權使用者，有權使用電子服務平台服務。就本條款及條件的所有目的而言，登記用戶須為自己及上述使用者的一切作為或不作為負上責任。</p>
            <br/>
            <p>9.	登記用戶不得容許兩名或以上人士同時以相同的登入名稱使用電子服務平台服務。如系統偵測到有人以相同的登入名稱同時使用電子服務平台服務，較早登入的用戶在屏幕上進行的操作會即時停止或取消，或者以其他方式受到阻斷而不作事先警告。</p>
            <br/>
            <h2 className='font-bold'>終止使用登記帳戶</h2>
            <br/>
            <p>10.	登記用戶可向本署書面申請取消其登記帳戶。一經取消，該登記帳戶將永久失效。本署將保存被終止／刪除帳戶的個人資料最多兩年。 </p>
            <br/>
            <p>11.	如出現下列任何一種情況，本署可終止登記帳戶，而無須事先通知： </p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>不活躍帳戶：登記帳戶已連續五年未有使用或登入；或登記公司／機構已停止營運。
                </li>
                <li>違規：登記用戶違反本條款及條件的任何規定。
                </li>
            </ol>
            <br/>
            <h2 className='font-bold'>責任</h2>
            <br/>
            <p>12.	登記用戶只能以業主、使用人或業主／使用人的認可代理人身份在電子服務平台就一個／多個物業處理申請或服務。 </p>
            <br/>
            <p>13.	倘帳戶資料中的通訊地址、聯絡電話號碼、電郵地址或任何其他資料有所更改，登記用戶須更新向電子服務平台提供的帳戶資料。 </p>
            <br/>
            <p>14.	就登記公司／機構而言，持有管理員帳戶的登記用戶應： </p>
            <br/>
            <ol className="ms-lg list-[lower-alpha]">
                <li>確保在任何時間至少有一個有效的管理員帳戶作為與本署的聯絡點；
                </li>
                <li>妥善管理有關公司／機構的管理員帳戶和人員帳戶，倘登記用戶不再是有關公司／機構的員工，刪除有關的用戶帳戶；以及
                </li>
                <li>在登記公司／機構停止營運時，立即以書面通知本署。
                </li>
            </ol>
            <br/>
            <p>15.	登記用戶不得使用或啟動任何自動軟件或裝置，包括但不限於「機械人」、「蜘蛛」、「離線閱讀器」 等，存取電子服務平台。 </p>
            <br/>
            <h2 className='font-bold'>通訊</h2>
            <br/>
            <p>16.	登記用戶承諾，通過電子服務平台向本署提交的任何資料、通訊內容或材料均屬真確無誤，以及在所有要項上不具誤導、不完整或虛假成分。 </p>
            <br/>
            <p>17.	任何經電子服務平台記錄的聯絡方式或通知匣發送給登記用戶的信息，包括認收通知書、通知書和文件，一經發出，將視作已發送至登記用戶，並隨後獲登記用戶收悉，除非相反證明成立。所有信息將會自發出日期起最長保留三年。 </p>
            <br/>
            <p>18.	電子服務平台發送至登記用戶的認收通知書、通知書和文件，包括但不限於「電子決定通知書」，有關電子檔案只限作記錄用途。用戶或須保留相關通知書或文件的印刷文本正本，以用於任何法律程序。 </p>
            <br/>
            <h2 className='font-bold'>限制使用所提供的產品和服務</h2>
            <br/>
            <br/>
            <p>19.	除非得到本署書面明文批准或特許，否則本署一概保留對電子服務平台提供的任何產品或服務的編程作品或文件的所有權權益和版權。登記用戶獲得的所有產品及服務，均不得儲存或用作任何目的，但供登記用戶自用則除外。 </p>
            <br/>
            <p>20.	登記用戶不得把電子服務平台服務，或據此取得的任何產品或服務，用於任何違法或非法的活動上，亦不得用於任何本署認為不妥當的活動上，特別是不得用於違反《個人資料（私隱）條例》（第486章）或《版權條例》（第 528 章）或任何香港法例規定的任何活動或目的。何謂「不妥當的活動」全由本署最終決定。 </p>
            <br/>
            <h2 className='font-bold'>豁免條文</h2>
            <br/>
            <p>21.	電子服務平台是按「現況」和在「現有」的形式上提供。本署無須就使用電子服務平台，或與此有關的事宜所引致的任何損失或損害賠償（不論損失或損害賠償為何及如何引致），向登記用戶或任何第三方負責。在不限制前文的一般性原則下，本署不會對於因下述情況，或與該等情況有關而引致的任何損失或損害賠償負責：</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>
                    電子服務平台及與其連接的系統、網絡、設備或裝置的任何故障、中止運作、失靈、機能失常或任何其他失誤或操作中斷；
                </li>
                <li>中止或延遲執行電子服務平台的任何指令，或中止或延遲提供電子服務平台的任何服務；
                </li>
                <li>因登記用戶使用電子服務平台而導致登記用戶的網絡、電腦、調解器、電話系統、設備或其他裝置的硬件或軟件的任何損毀；以及
                </li>
                <li>引用所提供的資料作任何用途（評估差餉及地租除外）而導致的損害賠償或損失。
                </li>
            </ol>
            <br/>
            <p>22.	電子服務平台屬電子資訊系統，並且連接至多個由第三方提供的網絡。本署無責任協助任何登記用戶追查或尋找與電子服務平台或其所連接的系統、網絡、設備及裝置的硬件或軟件的設計或操作有關的任何投訴或據稱存在的問題。登記用戶也無權就該等原因或其他目的（包括與其相關的任何訴訟目的）要求查核或檢查上述任何器件。 </p>
            <br/>
            <h2 className='font-bold'>收集個人資料聲明</h2>
            <br/>
            <p>23.	在帳戶註冊過程中，本署將向你收集下列個人資料： </p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>英英文及／或中文姓名；以及 </li>
                <li>電郵地址及／或電話號碼或傳真號碼。</li>
            </ol>
            <br/>
            <p>24.	提供個人資料純屬自願性質，但如果你未能提供所需資料，本署可能無法處理你的申請。 </p>
            <br/>
            <p>25.	你所提供的資料將用於以下用途： </p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>執行《差餉條例》（第 117 章）、《業主與租客(綜合)條例》（第 7章）和《地租(評估及徵收)條例》（第 515 章）的有關事宜，亦可能轉交稅務局局長作評估和徵收《稅務條例》（第 112 章）所訂明的稅項之用；
                </li>
                <li>根據本文所載的條款及條件，處理就你申請的服務以及本署提供的服務有關事務；
                </li>
                <li>執行與本署其他電子系統或電子表格的帳戶連接及／或提供單一登入功能；
                </li>
                <li>便利本署與你之間的溝通；以及 </li>
                <li>製備與本署服務有關的統計資料。</li>
            </ol>
            <br/>
            <p>26.	除上述用途外，本署不會將個人資料給予其他人士，除非該等人士獲法例授權而要求取得這些資料。 </p>
            <br/>
            <p>27. 根據《個人資料（私隱）條例》（第486章），你有權要求查閱和修正你的個人資料。你可以書面向本署顧客服務主任提出這類要求。地址：九龍長沙灣道303號長沙灣政府合署15樓，<a
                className='text-surface-primary hover:underline'
                href="mailto: enquiries@rvd.gov.hk">或電郵至enquiries@rvd.gov.hk</a> 。</p>
            <br/>
            <p>28.	本署可不時自行更改、修改、刪除及／或增訂本文所載條款及條件中的任何條文。凡用戶接達和使用電子服務平台，即視作同意在法律上受這些條款及條件約束，而本署可在未有事先通知的情況下，不時修改及／或補充這些條款及條件。 </p>
            <br/>
            <p>29.	本文所載的條款及條件為中文譯本，如中、英文兩個版本有任何抵觸或不相符之處，應以英文版本為準。</p>
        </div>
    )

    const sc = () => (
        <div>
            <h2 className='font-bold'>一般条款及条件</h2>
            <br/>
            <p>1.
                以下是差饷物业估价署电子服务平台（下称「电子服务平台」）的使用条款及条件。差饷物业估价署（下称「本署」）同意提供电子服务平台服务，而电子服务平台的登记用户同意根据本文所载的条款及条件使用服务。</p>
            <br/>
            <p>2. 就本文所载条款及条件而言：</p>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>「登记账户」指在电子服务平台登记和建立的用户账户，包括公司／机构（管理员）账户和公司／机构（人员）账户。</li>
                <li>「登记用户」指持有登记账户的用户。</li>
                <li>「登记公司／机构」指持有登记账户的法人团体。</li>
            </ol>
            <br/>
            <h2 className='font-bold'>服务与功能</h2>
            <br/>
            <p>3.
                电子服务平台将按照本文所载的条款及条件，容许登记用户使用电子服务平台的服务与功能以处理差饷地租估价事宜。</p>
            <br/>
            <p>4.
                本署保留权力制定或更改任何电子服务平台服务的使用程序，并在有需要时增加、修改、暂停或终止任何服务与功能，而无须事先取得任何用户同意或通知任何用户。</p>
            <br/>
            <h2 className='font-bold'>登记和建立账户</h2>
            <br/>
            <p>5.
                任何商业实体／机构须通过电子服务平台申请一个登记账户，方可接达和使用电子服务平台服务。用户须承诺，在登记时提供的所需资料均属真实无误。</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li className='underline'>公司／机构账户</li>
                <ol className='ms-md list-[lower-roman]'>
                    <li>任何法人团体，例如持有根据《商业登记条例》（第 310 章）第 6
                        条发出的有效商业登记证的商业实体，或机构（例如大学、学校、法定机构）、或《税务条例》（第112章）第88条所指属公共性质的慈善机构，如能提供最少某一物业的有效账单资料，均可在电子服务平台建立登记账户。登记时须一并提交该法人团体的法团或公司注册证书副本，以及附有该公司／机构印鉴和授权签署的确认书。
                    </li>
                    <br/>
                    <li className='underline'>公司／机构（管理员）账户</li>
                    <br/>
                    <ul>
                        <li>(1)
                            为公司／机构建立的首个账户将为公司／机构（管理员）账户（「管理员账户」）。一个公司／机构账户内的所有管理员账户具有相同功能和对该账户上的信息有相同权限。
                        </li>
                    </ul>
                    <br/>
                    <li className='underline'>公司／机构（人员）账户</li>
                    <br/>
                    <ul>
                        <li>(1)
                            管理员账户可建立一个／多个公司／机构（人员）账户（「人员账户」）。管理员账户可为人员账户设置该公司／机构上的信息权限控制。
                        </li>
                    </ul>
                    <br/>
                    <li>公司／机构账户（首个管理员账户）的登记申请须经本署批准。公司／机构可视乎业务需要，管理员账户可建立和删除额外一个／多个管理员及人员账户。</li>
                    <br/>
                    <li>同一公司／机构如欲以不同账户名称开设一个／多个额外公司／机构账户，可另行登记。</li>
                </ol>
            </ol>
            <br/>
            <p>6. 一个账单账目编号只可供一个公司／机构账户作登记用途。</p>
            <br/>
            <p>7. 如账户的名称或资料被视为不恰当，可能会令合情理的人反感，则本署保留权利拒绝接纳该账户的登记申请。</p>
            <br/>
            <h2 className='font-bold'>登录</h2>
            <br/>
            <p>8.
                登记用户须采取适当措施把登录名称和密码保密。任何用户如一并输入登记账户独有的登录名称和密码，则会被视为登记账户的获授权使用者，有权使用电子服务平台服务。就本条款及条件的所有目的而言，登记用户须为自己及上述使用者的一切作为或不作为负上责任。</p>
            <br/>
            <p>9.
                登记用户不得容许两名或以上人士同时以相同的登录名称使用电子服务平台服务。如系统侦测到有人以相同的登录名称同时使用电子服务平台服务，较早登录的用户在屏幕上进行的操作会即时停止或取消，或者以其他方式受到阻断而不作事先警告。</p>
            <br/>
            <h2 className='font-bold'>终止使用登记账户</h2>
            <br/>
            <p>10.
                登记用户可向本署书面申请取消其登记账户。一经取消，该登记账户将永久失效。本署将保存被终止／删除账户的个人资料最多两年。</p>
            <br/>
            <p>11. 如出现下列任何一种情况，本署可终止登记账户，而无须事先通知：</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>不活跃账户：登记账户已连续五年未有使用或登录；或登记公司／机构已停止营运。</li>
                <li>违规：登记用户违反本条款及条件的任何规定。</li>
            </ol>
            <br/>
            <h2 className='font-bold'>责任</h2>
            <br/>
            <p>12.
                登记用户只能以业主、使用人或业主／使用人的认可代理人身份在电子服务平台就一个／多个物业处理申请或服务。</p>
            <br/>
            <p>13.
                倘账户资料中的通讯地址、联系电话号码、电邮地址或任何其他资料有所更改，登记用户须更新向电子服务平台提供的账户资料。</p>
            <br/>
            <p>14. 就登记公司／机构而言，持有管理员账户的登记用户应：</p>
            <br/>
            <ol className="ms-lg list-[lower-alpha]">
                <li>确保在任何时间至少有一个有效的管理员账户作为与本署的联络点；</li>
                <li>妥善管理有关公司／机构的管理员账户和人员账户，倘登记用户不再是有关公司／机构的员工，删除有关的用户账户；以及</li>
                <li>在登记公司／机构停止营运时，立即以书面通知本署。</li>
            </ol>
            <br/>
            <p>15.
                登记用户不得使用或启动任何自动软件或装置，包括但不限于「机器人」、「蜘蛛」、「离线阅读器」等，存取电子服务平台。</p>
            <br/>
            <h2 className='font-bold'>通讯</h2>
            <br/>
            <p>16.
                登记用户承诺，通过电子服务平台向本署提交的任何资料、通讯内容或材料均属真实无误，以及在所有要项上不具误导、不完整或虚假成分。</p>
            <br/>
            <p>17.
                任何经电子服务平台记录的联络方式或通知匣发送给登记用户的信息，包括认收通知书、通知书和文件，一经发出，将视作已发送至登记用户，并随即获登记用户收悉，除非相反证明成立。所有信息将会自发出日期起最长保留三年。</p>
            <br/>
            <p>18.
                电子服务平台发送至登记用户的认收通知书、通知书和文件，包括但不限于「电子决定通知书」，有关电子档案只限作记录用途。用户或须保留相关通知书或文件的印刷文本正本，以用于任何法律程序。</p>
            <br/>
            <h2 className='font-bold'>限制使用所提供的产品和服务</h2>
            <br/>
            <br/>
            <p>19.
                除非得到本署书面明文批准或特许，否则本署一概保留对电子服务平台提供的任何产品或服务的编程作品或文件的所有权权益和版权。登记用户获得的所有产品及服务，均不得储存或用作任何目的，但供登记用户自用则除外。</p>
            <br/>
            <p>20. 登记用户不得把电子服务平台服务，或据此取得的任何产品或服务，用于任何违法或非法的活动上，亦不得用于任何本署认为不妥当的活动上，特别是不得用于违反《个人资料（私隐）条例》（第486章）或《版权条例》（第
                528章）或任何香港法例规定的任何活动或目的。何谓「不妥当的活动」全由本署最终决定。</p>
            <br/>
            <h2 className='font-bold'>豁免条文</h2>
            <br/>
            <p>21.
                电子服务平台是按「现况」和在「现有」的形式上提供。本署无须就使用电子服务平台，或与此有关的事宜所引致的任何损失或损害赔偿（不论损失或损害赔偿为何及如何引致），向登记用户或任何第三方负责。在不限制前文的一般性原则下，本署不会对于因下述情况，或与该等情况有关而引致的任何损失或损害赔偿负责：</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>电子服务平台及与其连接的系统、网络、设备或装置的任何故障、中止运作、失灵、机能失常或任何其他失误或操作中断；</li>
                <li>中止或延迟执行电子服务平台的任何指令，或中止或延迟提供电子服务平台的任何服务；</li>
                <li>因登记用户使用电子服务平台而导致登记用户的网络、电脑、调解器、电话系统、设备或其他装置的硬件或软件的任何损毁；以及</li>
                <li>引用所提供的资料作任何用途（评估差饷及地租除外）而导致的损害赔偿或损失。</li>
            </ol>
            <br/>
            <p>22.
                电子服务平台属电子信息系统，并且连接至多个由第三方提供的网络。本署无责任协助任何登记用户追查或寻找与电子服务平台或其所连接的系统、网络、设备及装置的硬件或软件的设计或操作有关的任何投诉或据称存在的问题。登记用户也无权就该等原因或其他目的（包括与其相关的任何诉讼目的）要求查核或检查上述任何器件。</p>
            <br/>
            <h2 className='font-bold'>收集个人资料声明</h2>
            <br/>
            <p>23. 在账户注册过程中，本署将向你收集下列个人资料：</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>英文及／或中文姓名；以及</li>
                <li>电邮地址及／或电话号码或传真号码。</li>
            </ol>
            <br/>
            <p>24. 提供个人资料纯属自愿性质，但如果你未能提供所需资料，本署可能无法处理你的申请。</p>
            <br/>
            <p>25. 你所提供的资料将用于以下用途：</p>
            <br/>
            <ol className='ms-lg list-[lower-alpha]'>
                <li>执行《差饷条例》（第 117 章）、《业主与租客(综合)条例》（第 7章）和《地租(评估及征收)条例》（第 515
                    章）的有关事宜，亦可能转交税务局局长作评估和征收《税务条例》（第 112 章）所订明的税项之用；
                </li>
                <li>根据本文所载的条款及条件，处理就你申请的服务以及本署提供的服务有关事务；</li>
                <li>执行与本署其他电子系统或电子表格的账户连接及／或提供单一登录功能；</li>
                <li>便利本署与你之间的沟通；以及</li>
                <li>准备与本署服务有关的统计资料。</li>
            </ol>
            <br/>
            <p>26. 除上述用途外，本署不会将个人资料给予其他人士，除非该等人士获法例授权而要求取得这些资料。</p>
            <br/>
            <p>27. 根据《个人资料（私隐）条例》（第486章），你有权要求查阅和修正你的个人资料。你可以书面向本署客服服务主任提出这类要求。地址：九龙长沙湾道303号长沙湾政府合署15楼，<a
                className='text-surface-primary hover:underline'
                href="mailto: enquiries@rvd.gov.hk">或电邮至enquiries@rvd.gov.hk</a>。</p>
            <br/>
            <p>28.
                本署可不时自行更改、修改、删除及／或增订本文所载条款及条件中的任何条文。凡用户接达和使用电子服务平台，即视作同意在法律上受这些条款及条件约束，而本署可在未有事先通知的情况下，不时修改及／或补充这些条款及条件。</p>
            <br/>
            <p>29. 本文所载的条款及条件为中文译本，如中、英文两个版本有任何抵触或不相符之处，应以英文版本为准。</p>
        </div>
    )

    return (
        <>
            <FormHeaderTemplate title="Terms and Conditions"
                                subtitle="Please read the terms and conditions before registering a new account."
                                t={t}/>
            <NoteTemplate title="RVD Web Portal - Terms and Conditions" t={t}>
                {language === 'tc' ? tc() : language === 'sc' ? sc() : en()}

                <div className="mt-x-big pt-x-big">
                    <Checkbox itemStart={true} labelClass='label1'
                              inputLabel="I confirm I have read and understand all the above terms and conditions and agree to be bound by them."
                              name="privacy_policy" setDisableBtn={setDisableBtn} disabledBtn={disabledBtn}
                              updateFields={updateFields} data={data['privacy_policy']}
                              check={data['privacy_policy'] === '1'} t={t}/>
                </div>
                <MtCaptcha loadingCapthcha={loadingCapthcha} error={error['captcha_verify_code']}/>
            </NoteTemplate>
        </>
    )
}

export default StepTwo;
