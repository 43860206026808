import { useEffect, useState } from "react";
import { ReactComponent as SearchSVG } from "../../../assets/images/others/search.svg";
import { SearchButton } from "../../button/SingleButton";
import ErrorMessage from "../../error/ErrorMessage";
import {useTranslation} from "react-i18next";

const WhiteInput = ({
  name,
  type = "text",
  placeholder,
  haveicon = null,
  searchbar = false,
  searchButton,
  min,
  max,
  action,
  data,
  onKeyDown,
  flexInput,
  resetAction,
  customSearchAction,
  updateFields,
  error,
  setError,
  help_text,
}) => {
  const [newValue, setNewValue] = useState(data || "");
  const {t} = useTranslation();
  const [flexNewValue, setFlexNewValue] = useState(
    Array(flexInput?.length).fill("")
  );

  useEffect(() => {
    setNewValue(data || "");
  }, [data]);

  const handleReset = () => {
    setNewValue("");
    if (resetAction) resetAction();
    if (flexInput) setFlexNewValue(Array(flexInput?.length).fill(""));
    if (setError) setError("");
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (setError) setError("");

    if (flexInput) {
      const updatedValues = [...flexNewValue];
      updatedValues[index] = value;
      setFlexNewValue(updatedValues);
    } else {
      setNewValue(value);
    }
    if (action) action(e);
    if (updateFields) {
      if (flexInput) {
        updateFields({ [flexInput[index].name]: value });
      } else {
        updateFields({ [name]: value });
      }
    }
  };

  if (haveicon !== null) {
    return (
      <>
        <div className="relative">
          <input
            name={name}
            type={type}
            placeholder={t(placeholder)}
            value={newValue}
            onChange={(e) => handleChange(e)}
            max={max}
            min={min}
            className={`default_input w-full pe-lg ${
              error ? "border-warning" : ""
            }`}
          />
          <span
            className="bg-neutral-100 px-sm absolute h-full rounded-r-sm caption1 text-caption items-center flex"
            style={{ height: "46px", top: "1px", right: "1px" }}
          >
            {haveicon}
          </span>
        </div>
        {searchButton && (
          <SearchButton
            t={t}
            action={customSearchAction}
            reset={handleReset}
            search_data={newValue}
          />
        )}
      </>
    );
  }

  if (searchbar) {
    return (
      <div className="flex-col md:flex-row flex gap-sm w-full">
        <div className="relative w-full flex">
          <SearchSVG
            style={{
              fill: "var(--Neutral-600)",
              top: "15px",
              width: "18px",
              left: "15px",
            }}
            className="absolute"
          />
          <input
            name={name}
            type={type}
            placeholder={t(placeholder)}
            value={newValue}
            onChange={(e) => handleChange(e)}
            onKeyDown={onKeyDown}
            className={`flex-1 default_input body1 w-full ps-x-lg ${
              error ? "border-warning" : ""
            }`}
            style={{ paddingLeft: "var(--spacing-x-lg)" }}
          />
        </div>
        <SearchButton
          t={t}
          action={customSearchAction}
          reset={handleReset}
          search_data={newValue}
        />
      </div>
    );
  }

  if (flexInput) {
    return (
      <div className="flex-col md:flex-row flex gap-sm w-full">
        {flexInput.map((item, index) => {
          return (
            <input
              key={item.name}
              name={item.name}
              type={type}
              placeholder={t(item.placeholder)}
              className={`default_input md:flex-1 ${
                error ? "border-warning" : ""
              }`}
              value={flexNewValue[index]}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={onKeyDown}
            />
          );
        })}
        <SearchButton t={t} action={customSearchAction} reset={handleReset} />
      </div>
    );
  }

  return (
    <>
      <input
        name={name}
        type={type}
        placeholder={t(placeholder)}
        className={`default_input w-full ${error ? "border-warning" : ""}`}
        value={newValue}
        onChange={(e) => handleChange(e)}
        onKeyDown={onKeyDown}
      />
      {searchButton && (
        <SearchButton t={t} action={customSearchAction} reset={handleReset} />
      )}
      <ErrorMessage error={error} t={t} help_text={help_text} />
    </>
  );
};

export default WhiteInput;
