import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import {useTranslation} from "react-i18next";

export default function ProcessingLoading(loading) {
    const { t } = useTranslation();
    return (
        loading ? <div
                className='fixed min-h-[100vh] w-[100vw] left-[0] top-[0] flex flex-col gap-sm justify-center items-center circular-progress-container'>
                <CircularProgress style={{marginTop: '10px', color: 'var(--red-600)'}}/>
                <span className='text-white'>{t('Processing')}</span>
            </div> : null
    );
}