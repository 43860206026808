import React, { useState } from "react";
import { Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import MySwitch from "../Switch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PrimaryButton } from "../../button/SingleButton";
import { useTranslation } from "react-i18next";

const CheckboxCateSelect = ({ name, options, props }) => {
  const [selectAll, setSelectAll] = useState(true);
  const [preSelectAll, setPreSelectAll] = useState(true);
  const { t } = useTranslation();
  const [readyCategory, setReadyCategory] = useState(
    props?.currentCategories || []
  );
  const [menuOpen, setMenuOpen] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    if (value !== undefined && options !== undefined) {
      setSelectAll(value.length === options.length);
      setReadyCategory(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleSelectAll = (event) => {
    setPreSelectAll(event.target.checked);
    setReadyCategory(
      event.target.checked ? options && options.map((item) => item.id) : []
    );
  };

  const handleSave = () => {
    setMenuOpen(false);
    setSelectAll(preSelectAll)
    props.setCurrentCategories(readyCategory);
    props.setIsFetching(true);
  };

  const renderValue = (selected) => {
    if (selected !== undefined) {
      if (selected.length === 0) return t("Selected Category") + ': 0';
      if (selected.length === options.length) return t("Selected Category: All");
      return t('Selected Category')`: ${selected.length}`;
    }
  };

  return (
    <Select
      name={name}
      IconComponent={KeyboardArrowDownIcon}
      multiple
      className="body1 text-body w-full border border-default"
      value={readyCategory || []}
      onChange={handleChange}
      displayEmpty
      open={menuOpen}
      onClose={() => {
        setMenuOpen(false);
        setReadyCategory(props.currentCategories);
      }}
      onOpen={() => setMenuOpen(true)}
      renderValue={renderValue}
      MenuProps={{
        PaperProps: {
          className: "custom-scrollbar",
          style: {
            padding:
              "var(--spacing-x-sm) var(--spacing-x-sm) 0 var(--spacing-x-sm)",
            maxHeight: "322px",
            marginTop: "var(--spacing-sm)",
            overflowY: "auto",
            background: "var(--surface-card)",
            borderRadius: "var(--radius-sm)",
            boxShadow: "var(--box-shadow-lg)",
          },
        },
        MenuListProps: { style: { padding: "0" } },
      }}
      sx={{
        "&.MuiInputBase-root": { border: "0" },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid var(--surface-primary)",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--border-default)",
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--border-default)",
        },
        background: "var(--surface-card)",
        borderRadius: "var(--radius-sm)",
        height: "48px",
        width: "100%",
      }}
    >
      {options &&
        options.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            sx={{
              "&:hover": {
                "&.Mui-selected": {
                  background: "var(--surface-secondary)",
                },
                background: "var(--surface-secondary)",
              },
              "&.MuiList-root": { padding: "0" },
              "&.Mui-selected": {
                color: "var(--text-body) !important",
                background: "none",
              },
              maxWidth: "430px",
              whiteSpace: "normal",
              display: "flex",
              gap: "var(--spacing-md)",
              padding: "0",
              borderRadius: "var(--radius-x-sm)",
            }}
          >
            <Checkbox
              checked={readyCategory.indexOf(item.id) > -1}
              sx={{
                "&.Mui-checked": { color: "var(--surface-primary) !important" },
                color: "var(--Neutral-300)",
              }}
            />
            <ListItemText
              primary={item.name}
              sx={{ padding: "var(--spacing-sm) var(--spacing-md)" }}
            />
          </MenuItem>
        ))}

      <div
        className="bg-surface-card border-default border-t flex justify-between p-md sticky"
        style={{ bottom: "0px" }}
      >
        <div className="flex items-center gap-md">
          <span className="label1 text-body">{t('Select All')}</span>
          <MySwitch action={handleSelectAll} data={selectAll} />
        </div>
        <div className="w-fit">
          <PrimaryButton t={t} label={"Save"} action={handleSave} />
        </div>
      </div>
    </Select>
  );
};

export default CheckboxCateSelect;
