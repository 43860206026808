import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddDataToHTML } from "../assets/js/global";
import SmallFunction from "../../src/components/function/SmallFunction";
import { useTranslation } from "react-i18next";
import USER_TYPE from "../data/variable/user_type.json";
import useResetDataThemeOnPageChange from "../hooks/useResetDataThemeOnPageChange";

const UserLoginContext = createContext({
  profileData: {},
  userLoading: true,
  token: "",
  icon: "",
  account_type: null,
  notification: [],
  haveNoRead: false,
  admin: false,
  setProfileData: () => {},
});
const UserLoginProvider = ({ lng, children }) => {
  const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(userData);
  const [notification, setNotification] = useState([]);
  const [haveNoRead, setHaveNoRead] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [icon, setIcon] = useState("");
  const [account_type, setAccountType] = useState(null);
  const [token, setToken] = useState("");
  const { getIconValue } = SmallFunction();
  let getLang = false;

  useEffect(() => {
    if (notification) {
      const hasUnread = notification.some((option) => !option.isRead);
      setHaveNoRead(hasUnread);
    }
  }, [notification]);

  const setHeaders = (lng) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", lng);
    myHeaders.append("Content-Type", "application/json");
    return myHeaders;
  };

  const fetchNotifications = async (
    myHeaders,
    setNotification,
    navigate,
    lng
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}/v1/notification/badge`,
        {
          method: "POST",
          headers: myHeaders,
        }
      );
      const resData = await res.json();

      if (res.ok && resData.code === 200) {
        setNotification(resData.data.list);
      } else {
        if (resData.code === 501) {
          navigate(`/${lng}/error`);
        }
      }
    } catch (error) {
      // sweetFailAlert(error, t)
    }
  };

  const fetchProfileData = async (
    myHeaders,
    setProfileData,
    setUserLoading,
    navigate,
    lng,
    saveUserDate
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}/v1/profile`,
        {
          method: "POST",
          headers: myHeaders,
        }
      );
      const resData = await res.json();

      if (res.ok && resData.code === 200) {
        setProfileData(resData.data.profile);
        setUserLoading(false);
      } else {
        if (resData.code === 501) {
          navigate(`/${lng}/error`);
        }
      }
      saveUserDate(resData.data.profile);
    } catch (error) {
      // sweetFailAlert(error, t)
    }
  };

  useEffect(() => {
    const excludePages = ['login', 'privacy-policy', 'important-notice', 'site-map' ]

    setUserLoading(true);
    // setUserLoading(false);
    if (!excludePages.some(page => window.location.pathname.includes(`${lng}/${page}`))) {
      const myHeaders = setHeaders(lng);
      fetchNotifications(myHeaders, setNotification, navigate, lng);
      if (!userData) {
        fetchProfileData(
          myHeaders,
          setProfileData,
          setUserLoading,
          navigate,
          lng,
          saveUserDate
        );
        getLang = true;
      } else {
        saveUserDate(userData);
        setUserLoading(false);
      }
    }
  }, [userLoading]);

  const saveUserDate = (data) => {
    if (data) {
      setAdmin(Number(data?.accountType?.value) === USER_TYPE.ADMIN);
      AddDataToHTML("data-theme", data?.themeColour?.description);
      setProfileData(data);
      setUserLoading(false);
      setAccountType(Number(data?.accountType?.value));
      if (data.avatarColour?.value) {
        getIconValue(data.avatarColour?.value, setIcon);
      }
    }
  };

  const color = useResetDataThemeOnPageChange(profileData ? profileData : null)

  return (
    <UserLoginContext.Provider
      value={{
        profileData,
        userLoading,
        token,
        setToken,
        icon,
        account_type,
        setProfileData: saveUserDate,
        notification,
        haveNoRead,
        admin,
      }}
    >
      {children}
    </UserLoginContext.Provider>
  );
};

export const useUserLoginContext = () => useContext(UserLoginContext);
export default UserLoginProvider;
