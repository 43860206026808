import React from "react";
import { ReactComponent as FileDownloadSVG } from "../../assets/images/upload/file_download.svg";
import { ReactComponent as FileUploadSVG } from "../../assets/images/upload/file_upload.svg";
import { ReactComponent as ArrowForwardSVG } from "../../assets/images/arrow/arrow_forward.svg";
import { ReactComponent as ArrowBackSVG } from "../../assets/images/arrow/arrow_back.svg";
import { ReactComponent as DeleteSVG } from "../../assets/images/upload/delete.svg";
import { ReactComponent as DownloadSVG } from "../../assets/images/upload/file_download.svg";
import { ReactComponent as SearchSVG } from "../../assets/images/others/search_white.svg";
import { ReactComponent as EditSVG } from "../../assets/images/others/CkEdit.svg";
import { ReactComponent as ArrowOutwardSVG } from "../../assets/images/arrow/arrow_outward.svg";
import { ReactComponent as DownArrowSVG } from "../../assets/images/arrow/down_arrow.svg";
import iAmSmartIcon from "../../assets/images/others/iAM_Smart_icon.png";
import AddIcon from "@mui/icons-material/Add";
import TuneIcon from "@mui/icons-material/Tune";
import { Link } from "react-router-dom";

const handleClick = (event, action) => {
  event.preventDefault();
  action();
};

const buttonClassNames =
  "whitespace-nowrap rounded-sm flex items-center justify-center gap-x-sm";

export const DiscardButton = ({ t, action, label = "Discard", className }) => (
  <button
    className={`${className || ""} outline-btn line-height ${buttonClassNames}`}
    type="button"
    onClick={(event) => (action ? handleClick(event, action) : null)}
  >
    <span className="label1 leading-none">{t(label)}</span>
  </button>
);

export const OutlineBackButton = ({
  t,
  href,
  label = "Back",
  action,
  stateObj,
}) => (
  <Link
    className="outline-btn line-height ${buttonClassNames}"
    to={href || ""}
    state={stateObj || {}}
    onClick={(event) => (action ? handleClick(event, action) : null)}
  >
    <span className="label1 leading-none">{t(label)}</span>
  </Link>
);


export const OutlineButton = ({ t, label, action, red }) => (
  <button
    className={`flex px-x-big justify-center items-center rounded-sm border ${
      red
        ? `border-label-icon-warning text-warning hover:bg-label-icon-warning`
        : "border-surface-primary text-surface-primary hover:bg-surface-primary-hover"
    } bg-white h-x-lg gap-x-sm hover:text-white line-height`}
    type="button"
    onClick={(event) => (action ? handleClick(event, action) : null)}
  >
    <span className="label1 leading-none">{t(label)}</span>
  </button>
);

export const ArrowBackButton = ({
  t,
  href,
  label = "Back",
  action,
  stateObj,
  disabled,
}) => (
  <Link
    disabled={disabled}
    className={`${
      disabled && "disabled"
    } label1 outline-back-btn ${buttonClassNames} pl-md pr-x-big border border-surface-primary text-surface-primary bg-white h-x-lg hover:bg-surface-primary-hover hover:text-white`}
    to={href || ""}
    state={stateObj || {}}
    onClick={(event) => (action ? handleClick(event, action) : null)}
  >
    <ArrowBackSVG width={18} style={{ transition: ".2s ease-out all" }} />
    <span className="label1 leading-none">{t(label)}</span>
  </Link>
);

export const AddButton = ({ t, href, label = "Add", stateObj }) => (
  <Link
    className={`primary-btn ${buttonClassNames}`}
    to={href || ""}
    state={stateObj || {}}
    style={{ gap: "var(--spacing-x-sm) !important" }}
  >
    <AddIcon style={{ width: "19px" }} />
    <span className="label1 leading-none">{t(label)}</span>
  </Link>
);

export const RedButton = ({
  t,
  label,
  type,
  loading,
  action,
  disabled,
  sx,
}) => (
  <button
    className={`red-btn`}
    onClick={(event) => (action ? handleClick(event, action) : null)}
    type={type || ""}
    disabled={disabled || loading}
    style={sx}
  >
    <span className="label1 leading-none">
      {loading ? t("Loading") : t(label)}
    </span>
  </button>
);

export const RedForwardButton = ({
  t,
  href,
  action,
  label,
  blank,
  type,
  disabled,
  stateObj,
}) => (
  <Link
    className={`red-forward-btn ${buttonClassNames}`}
    to={href || ""}
    state={stateObj || {}}
    onClick={(event) => (action ? handleClick(event, action) : null)}
    target={blank ? "_blank" : ""}
    type={type || ""}
    disabled={disabled}
  >
    <span className="label1 leading-none">{t(label)}</span>
    <ArrowForwardSVG width={18} style={{ transition: ".2s ease-out all" }} />
  </Link>
);

export const BlueForwardButton = ({
  t,
  label,
  type,
  loading,
  action,
  disabled,
}) => (
  <button
    className={`w-full blue-forward-btn h-x-lg bg-surface-primary px-x-big rounded-sm ${buttonClassNames} text-white no-underline hover:bg-surface-primary-hover`}
    onClick={(event) => (action ? handleClick(event, action) : null)}
    type={type || ""}
    disabled={disabled || loading}
  >
    <span className="label1 leading-none">
      {loading ? t("Loading") : t(label)}
    </span>
    <ArrowForwardSVG width={18} style={{ transition: ".2s ease-out all" }} />
  </button>
);

export const BlueForwardLink = ({ t, label, href, disabled, stateObj }) => (
  <Link
    to={href || ""}
    state={stateObj || {}}
    className={`${
      disabled && "disabled"
    } blue-forward-btn h-x-lg bg-surface-primary px-x-big rounded-sm ${buttonClassNames} text-white no-underline hover:bg-surface-primary-hover`}
  >
    <span className="label1 leading-none">{t(label)}</span>
    <ArrowForwardSVG width={18} style={{ transition: ".2s ease-out all" }} />
  </Link>
);

export const PrimaryButton = ({
  t,
  label,
  type,
  loading,
  action,
  className,
  sx,
  disabled,
}) => (
  <button
    className={`${
      className || ""
    } w-full h-x-lg bg-surface-primary px-x-big rounded-sm ${buttonClassNames} text-white no-underline hover:bg-surface-primary-hover`}
    onClick={(event) => (action ? handleClick(event, action) : null)}
    type={type || ""}
    disabled={loading || disabled}
    style={sx}
  >
    <span className="label1 leading-none">
      {loading ? t("Loading") : typeof label === 'function' ? label() : t(label)}
    </span>
  </button>
);

export const PrimaryLink = ({
  t,
  label,
  href,
  target = "_blank",
  className,
  style,
  stateObj,
}) => (
  <Link
    to={href || ""}
    state={stateObj || {}}
    target={target}
    className={`${
      className || ""
    } h-x-lg bg-surface-primary px-x-big rounded-sm ${buttonClassNames} text-white no-underline hover:bg-surface-primary-hover`}
    style={style}
  >
    <span className="label1 leading-none">{t(label)}</span>
  </Link>
);

export const IAmSmartButton = ({ t, href, action, label, blank, stateObj }) => (
  <Link
    className={`label1 bg-green-dark ${buttonClassNames} px-x-big md:w-fit min-h-x-lg text-white no-underline relative hover:bg-green-dark-hover`}
    to={href || ""}
    state={stateObj || {}}
    onClick={(event) => (action ? handleClick(event, action) : null)}
    target={blank ? "_blank" : ""}
  >
    <img src={iAmSmartIcon} alt="i am smart icon" width={18} />
    <span className="label1 leading-none">{t(label)}</span>
  </Link>
);

export const DeleteWithTextButton = ({ t, label = "Delete", action }) => (
  <button
    className={`label1 text-label-icon-on-light hover:text-label-icon-warning svg_link ${buttonClassNames}`}
    onClick={(event) => (action ? handleClick(event, action) : null)}
  >
    <DeleteSVG width={20} />
    <span className="label1 leading-none ">
      {t(label)}
    </span>
  </button>
);

export const ProceedButton = ({ disabled, label="Proceed", t }) => (
    <div
        className={`outline-btn flex gap-x-sm ps-x-big pe-md items-center ${
            disabled ? "disabled" : ""
        }`}
    >
      <span className="label1">{t(label)}</span>
      <DownArrowSVG width={18} />
    </div>
);

export const ImportButton = ({ t, action, setState, label, selectIcon }) => (
  <button
    className={`outline-btn ${buttonClassNames} ps-md pe-x-big label1`}
    type="button"
    onClick={() => (action ? action() : setState ? setState(true) : null)}
    style={
      selectIcon
        ? {
            padding: "var(--spacing-sm) var(--spacing-md) !important",
            gap: "var(--spacing-x-sm) !important",
          }
        : {}
    }
  >
    <FileDownloadSVG width={19} />
    <span className="label1 leading-none">{t(label || "Import")}</span>
    {selectIcon && <DownArrowSVG width={18} />}
  </button>
);

export const ImportBlueButton = ({ t, action, setState, label = "Import" }) => (
  <button
    className={`primary-btn ${buttonClassNames} ps-md pe-x-big label1`}
    type="button"
    onClick={() => (action ? action() : setState ? setState(true) : null)}
  >
    <FileDownloadSVG width={19} />
    <span className="label1 leading-none">{t(label)}</span>
  </button>
);

export const UploadButton = ({
  t,
  action,
  setState,
  label = "Upload",
  outline = false,
  href,
  selectIcon,
  stateObj,
}) => {
  if (href) {
    return (
      <Link
        className={`${
          outline ? "outline-btn" : "primary-btn"
        } ${buttonClassNames} ps-md pe-x-big label1`}
        to={href || ""}
        state={stateObj || {}}
      >
        <FileUploadSVG width={19} />
        <span className="label1 leading-none">{t(label)}</span>
      </Link>
    );
  } else {
    return (
      <button
        className={`${
          outline ? "outline-btn" : "primary-btn"
        } ${buttonClassNames} ps-md pe-x-big label1`}
        type="button"
        onClick={() => (action ? action() : setState ? setState(true) : null)}
        style={
          selectIcon
            ? {
                padding: "var(--spacing-sm) var(--spacing-md) !important",
                gap: "var(--spacing-x-sm) !important",
              }
            : {}
        }
      >
        <FileUploadSVG width={19} />
        <span className="label1 leading-none">{t(label)}</span>
        {selectIcon && <DownArrowSVG width={18} />}
      </button>
    );
  }
};

export const DownloadButton = ({
  t,
  href,
  label,
  selectIcon,
  action,
  stateObj,
}) => {
  if (href) {
    return (
      <Link
        className={`md:ms-auto bg-surface-primary p-sm rounded-sm ${buttonClassNames}`}
        to={href || ""}
        state={stateObj || {}}
        target={"_blank"}
        title={t("download file")}
        download
      >
        <DownloadSVG width="20px" />
      </Link>
    );
  } else {
    return (
      <button
        className={`primary-btn ${buttonClassNames} ps-md pe-x-big label1`}
        type="button"
        onClick={() => (action ? action() : null)}
        style={
          selectIcon
            ? {
                padding: "var(--spacing-sm) var(--spacing-md) !important",
                gap: "var(--spacing-x-sm) !important",
              }
            : {}
        }
      >
        <span className="label1 leading-none">{t(label || "Download")}</span>
        {selectIcon && <DownArrowSVG width={18} />}
      </button>
    );
  }
};

export const RemoveButton = ({ t, handleRemove }) => (
  <button
    className={`md:ms-auto primary-btn ${buttonClassNames}`}
    title={t("remove file")}
    style={{
      padding: "var(--spacing-x-sm)",
      maxWidth: "32px",
      maxHeight: "32px",
    }}
    onClick={(event) => handleRemove(event)}
  >
    <DeleteSVG width="20px" />
  </button>
);

export const TableDownloadButton = ({ t, action }) => (
    <button
        type={"button"}
        className={`md:ms-auto bg-surface-primary p-xx-sm rounded-x-sm ${buttonClassNames} w-[32px] h-[32px]`}
        title={t("download file")}
        onClick={() => (action ? action() : null)}
    >
      <DownloadSVG width="16px" />
    </button>
);


export const EditButton = ({ t, href, searchIcon, stateObj }) => (
  <Link
    className={`bg-surface-primary p-xx-sm min-w-xxx-big h-xxx-big hover:bg-surface-primary-hover ${buttonClassNames} rounded-x-sm`}
    to={href || ""}
    title={t("Edit")}
    state={stateObj || {}}
  >
    {searchIcon ? <SearchSVG width={16} /> : <EditSVG width={16} />}
  </Link>
);

export const ArrowOutwardButton = ({ t, href, stateObj }) => (
  <Link
    className={`bg-surface-primary p-xx-sm min-w-xxx-big h-xxx-big hover:bg-surface-primary-hover ${buttonClassNames} rounded-x-sm`}
    to={href || ""}
    state={stateObj || {}}
  >
    <ArrowOutwardSVG width={16} />
  </Link>
);

export const SearchButton = ({ t, action, search_data, reset, disabled }) => {
  const handleReset = () => {
    if (reset) reset();
  };

  return (
    <div className="flex gap-sm me-auto md:me-0">
      {action ? (
        <button
          className="primary-btn"
          onClick={() => action(search_data)}
          type="button"
          disabled={disabled}
        >
          {t("Search")}
        </button>
      ) : (
        <button className="primary-btn" type="submit" disabled={disabled}>
          {t("Search")}
        </button>
      )}
      <DiscardButton t={t} action={handleReset} label="Cancel" />
    </div>
  );
};

export const AdvanceButton = ({ t, setShowAdvance }) => (
  <button
    className="primary-btn"
    type="button"
    onClick={() => setShowAdvance((prevState) => !prevState)}
  >
    <TuneIcon fontSize="inherit" />
    {t("Advanced Search")}
  </button>
);
