import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as NavigateNextSVG } from "../../assets/images/header/navigate_next.svg";
import { useTranslation } from "react-i18next";

const Breadcrumb = ({
                      activeClasses,
                      capitalizeLinks,
                      lng,
                      currentElement,
                      pervElement,
                      noCurrentElement,
                      homeElement,
                      customElement,
                    }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((path) => path);
  const startIndex = pathNames.findIndex((name) => name === lng);
  const filteredPaths =
      startIndex !== -1 ? pathNames.slice(startIndex + 1) : [];
  if (noCurrentElement) filteredPaths.pop();
  const listClasses = "hover:underline hover:text-surface-primary-hover";
    const toTitleCase = (str) => {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

  return (
      <div className="text-600 bg-white caption2-600 md:label2">
        <ul className="flex items-center flex-wrap breadcrumb-container layout-container gap-xx-sm">
          <li className={listClasses}>
            <Link to={`/${lng}`}>{homeElement || t("Home")}</Link>
          </li>
            {filteredPaths.length > 0 && <NavigateNextSVG />}
          {customElement && customElement.length > 0 ? (
              <>
                {customElement.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <li className={listClasses}>
                                <Link to={`/${lng}/${item?.link}`}>{ typeof item?.name === 'string' && item?.name ? t(item?.name) : ''}</Link>
                            </li>
                            <NavigateNextSVG />
                        </React.Fragment>
                    )
                })}
                <li className="pointer-events-none">
                    {t(toTitleCase(currentElement))}
                </li>
              </>
          ) : (
              filteredPaths.map((link, index) => {
                const href = `/${filteredPaths.slice(0, index + 1).join("/")}`;
                const itemClasses =
                    location.pathname === href
                        ? `${listClasses} ${activeClasses}`
                        : listClasses;
                const itemLink = link
                    .replace(/-/g, " ")
                    .replace(/^\w/, (c) => c.toUpperCase());
                const isLast = index === filteredPaths.length - 1;
                const showPervElement =
                    pervElement &&
                    index === filteredPaths.length - pervElement.position;
                return (
                    <React.Fragment key={index}>
                      <li
                          className={
                            isLast && !noCurrentElement
                                ? "pointer-events-none"
                                : itemClasses
                          }
                      >
                        {showPervElement &&
                        pervElement.skip ? null : showPervElement ? (
                            <Link
                                to={
                                  pervElement.link
                                      ? `/${lng}/${pervElement.link}`
                                      : `/${lng}${href}`
                                }
                            >
                              {t(toTitleCase(pervElement.name))}
                            </Link>
                        ) : isLast && !noCurrentElement ? (
                             currentElement || t(toTitleCase(itemLink))
                        ) : (
                            <Link to={`/${lng}${href}`}>
                              {t(toTitleCase(itemLink))}
                            </Link>
                        )}
                      </li>
                      {!(showPervElement && pervElement.skip) && !isLast && (
                          <NavigateNextSVG />
                      )}
                    </React.Fragment>
                );
              })
          )}
        </ul>
      </div>
  );
};

export default Breadcrumb;