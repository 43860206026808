import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import SearchBar from "../../components/input/SearchBar";
import AccountManagementTemplate from "../../components/account_management/Template";
import LoadingUI from "../../components/loading/LoadingUI";
import StatusTag from "../../components/tags/StatusTag";
import RoleTag from "../../components/tags/RoleTag";
import DataTable from "../../components/table/DataTable";
import { AddButton, EditButton } from "../../components/button/SingleButton";
import Breadcrumb from "../../components/header/Breadcrumb";
import moment from "moment";
import useDocument from "../../hooks/useDocument";

const CorporateAccounts = ({ lng }) => {
  const { userLoading, account_type } = useUserLoginContext();
  const { t } = useTranslation();
  const [data, setDate] = useState({});
  const [searchData, setSearchData] = useState("");
  const api = "corporate/accounts";
  const lang = lng[0].toUpperCase() + lng.slice(1);
  const [loading, setLoading] = useState(true);
  const [resetPagination, setResetPagination] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  let minutes = ''
  let hours = ''
  let a_few_seconds = ''
  let a_minute = ''
  let an_hour = ''
  switch (lng) {
    case 'tc':
        minutes = '分鐘'
        hours = '小時'
        an_hour = '1小時'
        a_few_seconds = '幾秒'
        a_minute = '1分鐘'
        break;
    case 'sc':
        minutes = '分钟'
        hours = '小时'
        an_hour = '1小时'
        a_few_seconds = '几秒'
        a_minute = '1分钟'
        break;
    default:
        minutes = 'minutes'
        hours = 'hours'
        an_hour = 'an hour'
        a_few_seconds = 'a few seconds'
        a_minute = 'a minute'
  }

  useDocument("User Management")

  const tableDataProps = {
    setState: setDate,
    searchData,
    data,
    api,
    isFetching,
    setIsFetching,
    DefaultSorting: [{ id: "accountType", desc: true }],
    appandData: {
      keyword: searchData,
    },
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "username",
        header: t("User"),
        minSize: 260,
        grow: false,
        objKey: "username",
        Cell: ({ cell, row }) => (
          <div className="flex flex-col justify-center">
            <div>{cell.getValue()}</div>
            <div>{row.original.email}</div>
          </div>
        ),
      },
      {
        accessorKey: "accountType",
        header: t("User Role"),
        minSize: 150,
        grow: false,
        objKey: "account_type",
        Cell: ({ renderedCellValue }) => (
          <RoleTag
            role={renderedCellValue.value}
            label={renderedCellValue?.[`label${lang}`]}
            color={renderedCellValue.color}
          />
        ),
      },
      {
        accessorKey: "status",
        header: t("Status"),
        minSize: 170,
        grow: false,
        objKey: "status",
        Cell: ({ renderedCellValue }) => (
          <StatusTag
            status={renderedCellValue.value}
            label={renderedCellValue?.[`label${lang}`]}
            color={renderedCellValue.color}
          />
        ),
      },
      {
        accessorKey: "lastLoginTime",
        header: t("Last Login Date/Time"),
        grow: true,
        objKey: "login_time",
        Cell: ({ cell }) => {
          const loginTime = cell.getValue();
          const isOlderThanOneDay = moment().diff(moment(loginTime), 'days') >= 1;
          return (
              <span className="body2 flex items-center">
            {loginTime
                ? isOlderThanOneDay
                    ? moment(loginTime).format("YYYY/MM/DD HH:mm")
                    : moment(loginTime).fromNow(true)
                    .replace('minutes', minutes)
                    .replace('hours', hours)
                    .replace('a few seconds', a_few_seconds)
                    .replace('a minute', a_minute)
                    .replace('an hour', an_hour)
                    + t(' ago')
                : "--"}
          </span>
          );
        },
      },
      {
        accessorKey: "accountNo",
        header: "",
        size: 80,
        enableSorting: false,
        grow: false,
        Cell: ({ cell, row }) => (
          <EditButton
            t={t}
            href={`/${lng}/user-management/account-details/${row.original.accountNo}`}
          />
        ),
      },
    ],
    [lng, data, minutes, hours]
  );

  const CalladdBtn = () => {
    return (
      <div className="w-fit">
        <AddButton
          t={t}
          href={`/${lng}/user-management/account-creation`}
        />
      </div>
    );
  };

  if (userLoading) return <LoadingUI />;

  return (
    <>
      <Breadcrumb lng={lng} />
      <AccountManagementTemplate
        lng={lng}
        accountType={account_type}
        title="User Management"
        button_bar={CalladdBtn()}
      >
        <div className="flex flex-col gap-xx-big">
          <SearchBar
            setLoading={setLoading}
            data={data}
            setSearchData={setSearchData}
            setIsFetching={setIsFetching}
            setResetPagination={setResetPagination}
          />
          <DataTable
            columns={columns}
            border={false}
            apiProps={tableDataProps}
            setCustomLoading={setLoading}
            customLoading={loading}
            setResetPagination={setResetPagination}
            resetPagination={resetPagination}
          />
        </div>
      </AccountManagementTemplate>
    </>
  );
};

export default CorporateAccounts;
