import { ReactComponent as NoPermissionSVG } from "../../assets/images/error/403.svg";
import { ArrowBackButton } from "../../components/button/SingleButton";
import ErrorPageTemplate from "./ErrorPageTemplate";
import { useTranslation } from "react-i18next";
const NotFoundPage = ({ lng }) => {
  const { t } = useTranslation();
  return (
    <ErrorPageTemplate
      svg={<NoPermissionSVG />}
      breadcrumbText="Error 403"
      title="No Permission"
      text="You do not have permission to view this page."
      button={
        <ArrowBackButton
          action={() => {
            if (window.history.length <= 2) {
              window.location.href = "/";
            } else {
              window.history.go(-2);
            }
          }}
          t={t}
        />
      }
    />
  );
};

export default NotFoundPage;
